import axios from '../../axios/axios';
import ssc from './game/ssc.vue';
import pk10 from './game/pk10.vue';
import lhc from './game/lhc.vue';
import xy28 from './game/xy28.vue';
import k3 from './game/k3.vue';
import { pcddNum } from "../../plugin/pcddNum";
import { lhcNum } from '../../plugin/lhcNum';
import { get_lhc_sx } from '../../plugin/sx';
import { duijiangma } from '../../plugin/duijiangma';
export default {
  name: 'lishikaijiang',
  components: {
    ssc,
    pk10,
    lhc,
    xy28,
    k3
  },

  data() {
    return {
      componentId: '',
      game_list: [],
      checked_gname: '',
      gid: '',
      page: 1,
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      history_data: []
    };
  },

  computed: {
    listenint_router_view() {
      return this.$store.state.int_router_view;
    }

  },
  watch: {
    listenint_router_view(newVal, oldVal) {
      console.log(newVal, oldVal); // 此处处理逻辑

      this.get_history_data(this.gid);
    }

  },

  beforeRouteEnter(to, from, next) {
    let checked_gname = to.query.gname;
    let gid = to.query.gid;
    let componentId = '';

    if (gid == 1 || gid == 4 || gid == 7 || gid == 10 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
      componentId = 'ssc';
    } else if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
      componentId = 'pk10';
    } else if (gid == 20 || gid == 34 || gid == 48 || gid == 49) {
      componentId = 'lhc';
    } else if (gid == 22 || gid == 23) {
      componentId = 'xy28';
    } else if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
      componentId = 'k3';
    } // 通过 `vm` 访问组件实例


    axios.post("betresult/index", {
      lid: gid,
      page: 1,
      limit: 30
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.pagination = result.data.repsoneContent.pagination;
          vm.game_list = result.data.repsoneContent.game_list;
          let list = result.data.repsoneContent.list; //处理是pc蛋蛋的结果

          if (gid == 22 || gid == 23) {
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < list[i].res.length; j++) {
                list[i].res[j] = pcddNum(list[i].res[j]);
              }

              list[i].sum = pcddNum(list[i].sum);
            }
          } //处理六合彩


          if (gid == 20 || gid == 34 || gid == 48 || gid == 49) {
            for (let i = 0; i < list.length; i++) {
              let arr = [];

              for (let j = 0; j < list[i].res.length; j++) {
                arr.push(lhcNum(lhcNum(list[i].res[j], vm.$filters.time(list[i].closetime).split(' ')[0])));
              }

              list[i].res_s = get_lhc_sx('开奖', arr);
            }
          }

          duijiangma(list, gid);
          vm.history_data = list;
          vm.gid = gid;
          vm.checked_gname = checked_gname;
          vm.componentId = componentId;
        });
      }
    });
  },

  methods: {
    change_page(p) {
      this.page = p;
      this.get_history_data(this.gid);
    },

    get_history_data(gid) {
      // this.history_data = [];
      this.axios.post("betresult/index", {
        lid: gid,
        page: this.page,
        // start_time: this.date.start,
        // end_time: this.date.end,
        limit: 30
      }).then(result => {
        if (result.data.status == 200) {
          this.pagination = result.data.repsoneContent.pagination;
          this.history_data = result.data.repsoneContent.list;
          let list = result.data.repsoneContent.list; //处理是pc蛋蛋的结果

          if (gid == 22 || gid == 23) {
            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < list[i].res.length; j++) {
                list[i].res[j] = pcddNum(list[i].res[j]);
              }

              list[i].sum = pcddNum(list[i].sum);
            }
          } //处理六合彩


          if (gid == 20 || gid == 34 || gid == 48 || gid == 49) {
            for (let i = 0; i < list.length; i++) {
              let arr = [];

              for (let j = 0; j < list[i].res.length; j++) {
                arr.push(lhcNum(lhcNum(list[i].res[j], this.$filters.time(list[i].closetime).split(' ')[0])));
              }

              list[i].res_s = get_lhc_sx('开奖', arr);
            }
          }

          this.history_data = list;

          if (gid == 1 || gid == 4 || gid == 7 || gid == 10 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
            this.componentId = 'ssc';
          } else if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
            this.componentId = 'pk10';
          } else if (gid == 20 || gid == 34 || gid == 48 || gid == 49) {
            this.componentId = 'lhc';
          } else if (gid == 22 || gid == 23) {
            this.componentId = 'xy28';
          } else if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
            this.componentId = 'k3';
          } //处理兑奖码


          duijiangma(list, gid);
          this.gid = gid;
        }
      });
    },

    check_game(gname) {
      // this.componentId = '';
      let gid = this.game_list.find(item => item.gname == gname).id;
      this.get_history_data(gid);
    },

    get_game_list() {
      this.axios.post("main/game_list").then(result => {
        if (result.data.status === 200) {
          this.game_list = result.data.repsoneContent.game_list;
          this.checked_gname = this.game_list[0].gname;
          this.check_game(this.game_list[0].gname);
        }
      });
    }

  }
};