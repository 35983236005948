import axios from '../../../axios/axios';
import loginLog from "../plublic/loginLog.vue";
import changeLog from "../plublic/changeLog.vue";
import addZizhanghao from "../adduser/addZizhanghao.vue";
import editZizhanghao from "../edituser/editZizhanghao.vue";
import fenpeizhanghao from "../plublic/fenpeizhanghao.vue";
export default {
  components: {
    loginLog,
    changeLog,
    addZizhanghao,
    editZizhanghao,
    fenpeizhanghao
  },

  data() {
    return {
      users: [],
      title: '子账号管理',
      panelwidth: '100%',
      post_huiyuan: {
        type: 100,
        page: 1,
        //页码
        userState: 1,
        //状态
        sortName: "0",
        //排序类型：时间0 账号1
        userFlag: "0",
        //搜索类型：账号0 昵称1
        sortType: "0",
        //排序顺序：降0 升1
        zstype: "",
        //所属会员，zgs直属会员，fgs普通会员
        searchkey: "",
        //搜索时输入的内容
        limit: 30,
        uid: "",
        start_time: "",
        end_time: "",
        zc_time: "",
        filter: [{
          value: 1,
          label: "启用"
        }, {
          value: 3,
          label: "全部"
        }, {
          value: 2,
          label: "冻结"
        }, {
          value: 0,
          label: "停用"
        }]
      },
      pagination: {
        total_records: 0,
        limit: 30,
        page: 1
      },
      // 修改状态
      change_user_status: {
        visible: false,
        status: ''
      },
      //选中的用户信息
      row_user: {
        id: '',
        username: '',
        status: ''
      },
      other_view: {
        activated_name: '',
        visible: false,
        row: ''
      },
      add_user_data: {},
      addinfo: {
        sup_group_name: '',
        sub_group_name: '会员',
        sub_list: []
      }
    };
  },

  computed: {
    relod_sub_menu_data() {
      return this.$store.state.relod_sub_menu_data;
    }

  },
  watch: {
    relod_sub_menu_data(newVal, oldVal) {
      // console.log(newVal, oldVal);
      if (newVal) {
        this.get_users();
        this.$store.state.relod_sub_menu_data = false;
      }
    }

  },

  beforeRouteEnter(to, from, next) {
    axios.post('user_version2/user_list', {
      type: 100,
      page: 1,
      //页码
      userState: 1,
      //状态
      sortName: "0",
      //排序类型：时间0 账号1
      userFlag: "0",
      //搜索类型：账号0 昵称1
      sortType: "0",
      //排序顺序：降0 升1
      zstype: "",
      //所属会员，zgs直属会员，fgs普通会员
      searchkey: "",
      //搜索时输入的内容
      limit: 30,
      uid: "",
      start_time: "",
      end_time: "",
      zc_time: ""
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.users = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination; // vm.amounts = result.data.repsoneContent.amounts;
        });
      }
    });
  },

  methods: {
    //分配账号
    fenpei(row) {
      this.other_view.row = row;
      this.other_view.row.sup_group_name = '上级';
      this.other_view.row.sub_group_name = '子账号';
      this.axios.post("user_version2/sub_account", {
        uid: row.id,
        act: 'list'
      }).then(result => {
        if (result.data.status == 200) {
          this.other_view.activated_name = 'fenpeizhanghao';
          this.title = `为 ${row.username} 分配账号`;
          this.panelwidth = '70%';
          this.other_view.visible = true;
          this.$nextTick(() => {
            this.$refs['fenpeizhanghao'].getdata(result.data.repsoneContent, row.id);
          });
        }
      });
    },

    //修改
    edit_user(row) {
      this.other_view.row = row;
      this.other_view.row.sup_group_name = '上级';
      this.other_view.row.sub_group_name = '子账号';
      this.axios.post("user_version2/user_edit", {
        uid: row.id
      }).then(result => {
        if (result.data.status == 200) {
          this.other_view.activated_name = 'editZizhanghao';
          this.title = '修改子账号';
          this.panelwidth = '70%';
          this.other_view.visible = true;
          this.$nextTick(() => {
            this.$refs['editZizhanghao'].get_user_edit(result.data.repsoneContent);
          });
        }
      });
    },

    //新增
    add_user() {
      this.other_view.row = {
        sup_group_name: '上级',
        sub_group_name: '子账号'
      };
      this.axios.post("user_version2/user_add", {
        group_id: this.$store.state.userinfo.group_id
      }).then(result => {
        if (result.data.status == "200") {
          this.other_view.activated_name = 'addZizhanghao';
          this.title = '新增子账号';
          this.panelwidth = '70%';
          this.other_view.visible = true;
          this.$nextTick(() => {
            this.$refs['addZizhanghao'].get_user_edit(result.data.repsoneContent);
          });
        }
      });
    },

    //打开用户修改记录
    open_changeLog(row) {
      this.other_view.row = row;
      this.other_view.activated_name = 'changeLog';
      this.title = '修改记录';
      this.panelwidth = '100%';
      this.axios.post("log/admin_log", {
        uid: row.id,
        page: 1,
        limit: 20,
        username: row.username
      }).then(result => {
        if (result.data.status == 200) {
          this.other_view.visible = true;
          this.$nextTick(() => {
            this.$refs['changeLog'].get_data(result.data.repsoneContent);
          });
        }
      });
    },

    //打开用户登陆日志
    open_loginLog(row) {
      // console.log(row);
      this.other_view.row = row;
      this.other_view.activated_name = 'loginLog';
      this.title = '登陆日志';
      this.panelwidth = '100%';
      this.axios.post("log/admin_login_log", {
        uid: row.id,
        page: 1,
        limit: 10
      }).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.other_view.visible = true;
          this.$nextTick(() => {
            this.$refs['loginLog'].get_data(result.data.repsoneContent);
          });
        }
      });
    },

    //取消修改用户状态
    cancel_change_status() {
      this.change_user_status.visible = false;
      this.get_users();
    },

    //修改用户状态
    change_status(row, submit = false) {
      //修改会员状态
      if (!submit) {
        this.row_user = row;
        this.change_user_status.status = row.status;
        this.change_user_status.visible = true;
      } else {
        this.axios.post("user_version2/member_status", {
          status: this.change_user_status.status,
          uid: this.row_user.id
        }).then(result => {
          if (result.data.status == 200) {
            this.$message({
              message: result.data.shortMessage,
              type: "success"
            });
            this.change_user_status.visible = false; //刷新列表

            this.get_users(this.link);
          }
        });
      }
    },

    //股东用户list分页查询
    change_page(page) {
      this.post_huiyuan.page = page;
      this.get_users();
    },

    //获取股东用户list
    get_users() {
      this.axios.post('user_version2/user_list', this.post_huiyuan).then(result => {
        if (result.data.status == 200) {
          this.users = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;
          this.other_view.visible = false;
          this.panelwidth = '100%';
        }
      });
    },

    //其他页面返回
    other_view_back() {
      this.title = '子账号管理';
      this.get_users();
    }

  }
};