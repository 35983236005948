import axios from '../../../axios/axios';
import caopanrizhi from "./caopanrizhi";
import { pcddNum } from "../../../plugin/pcddNum";
import datepicker from "../../homePublic/datepicker.vue";
export default {
  components: {
    caopanrizhi,
    datepicker
  },

  data() {
    return {
      date: "",
      award_data: [],
      page: 1,
      active_gid: '',
      game_list: [],
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      dialog: {
        visible: '奖期管理'
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("play/period_num", {
      page: 1,
      gid: '',
      start_time: '',
      end_time: ''
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          vm.game_list = vm.$store.state.game_list;
          vm.active_gid = vm.game_list[0].id;
          vm.award_data = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination; //处理是pc蛋蛋的结果

          if (vm.active_gid == 22 || vm.active_gid == 23) {
            let list = result.data.repsoneContent.list;

            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < list[i].res.length; j++) {
                list[i].res[j] = pcddNum(list[i].res[j]);
              }

              list[i].sum = pcddNum(list[i].sum);
            }

            vm.award_data = list;
          }
        });
      }
    });
  },

  methods: {
    back_money(fid, qnum) {
      this.$confirm(`注意：返还金额后将用户注单、报表删除，并不能恢复！总公司进行此操作时，只对是此游戏是现金额度的直属公司有效！确认对期数【${qnum}】返还金额吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.post('play/num', {
          fid
        }).then(result => {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            this.$message.success(result.data.shortMessage);
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },

    //功能：操盘日志
    get_caopan(qunm) {
      this.dialog.visible = '操盘日志';
      this.$nextTick(() => {
        this.$refs.caopanrizhi.get_traderRecord_log(1, qunm);
      });
    },

    //切换游戏
    change_game(gid) {
      // console.log(gid)
      this.active_gid = gid;
      this.get_award_data();
    },

    //分页
    change_page(page) {
      this.page = page;
      this.get_award_data();
    },

    get_award_data() {
      this.axios.post("play/period_num", {
        gid: this.active_gid,
        page: this.page,
        start_time: this.date.start,
        end_time: this.date.end
      }).then(result => {
        // console.log(result.data.repsoneContent)
        if (result.data.status == 200) {
          this.award_data = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination; //处理是pc蛋蛋的结果

          if (this.active_gid == 22 || this.active_gid == 23) {
            let list = result.data.repsoneContent.list;

            for (let i = 0; i < list.length; i++) {
              for (let j = 0; j < list[i].res.length; j++) {
                list[i].res[j] = pcddNum(list[i].res[j]);
              }

              list[i].sum = pcddNum(list[i].sum);
            }

            this.award_data = list; // console.log(list)
          }
        }
      });
    },

    get_game_list() {
      this.axios.post("main/game_list").then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.game_list = result.data.repsoneContent.game_list;
          this.active_gid = this.game_list[0].id;
          this.get_award_data();
        }
      });
    },

    getDate(date) {
      this.date = date;
      this.get_award_data();
    }

  }
};