import axios from '../../../axios/axios';
export default {
  data() {
    return {
      game_list: [],
      user: '',
      show_panel: true
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("play/getGameInfo").then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          let list = result.data.repsoneContent.list;
          list.forEach(element => {
            element.order = element.order == 0 ? '' : element.order;
          });
          vm.game_list = list;
        });
      }
    });
  },

  methods: {
    chageGameStatus(item) {
      // console.log(item.id, item.gname);
      this.game_list.forEach(game => {
        if (game.id == item.id) {
          game.status = item.status == '1' ? '0' : '1';
        }
      });
    },

    save_list() {
      // console.log(this.game_list);
      let new_list = [];
      this.game_list.forEach(game => {
        if (game.status == 1) {
          new_list.push({
            gid: game.id,
            order: game.order * 1
          });
        }
      }); //排序

      new_list = new_list.sort((a, b) => a.order - b.order);
      new_list.forEach((game, index) => {
        // console.log(game, index);
        //自动排序不符合条件的order
        if ((game.order == '' || game.order <= 0 || game.order > new_list.length) && new_list.length > 1 && index != 0) {
          // console.log(index, game.order, new_list[index - 1]);
          game.order = new_list[index - 1].order + 1;
        }
      });

      if (new_list.length < 1) {
        return this.$alert('请至少选择1个彩种', "错误提示", {
          confirmButtonText: "确定",
          type: "error",
          callback: action => {}
        });
      }

      let obj = {
        play_name: new_list
      };

      if (this.user != '') {
        obj.agent_type = 1;
        obj.user_id = this.user.id; //判断是总公司设置彩种，需要传没有选中的彩种

        if (this.user.group_id == 2) {
          let cancel_playids = [];
          this.game_list.forEach(game => {
            if (game.status == 0) {
              cancel_playids.push(game.id);
            }
          });

          if (cancel_playids.length > 0) {
            obj.cancel_playids = cancel_playids;
          }
        }
      } //判断是一级总监设置彩种，需要传没有选中的彩种


      if (this.$store.state.userinfo.group_id == 2) {
        let cancel_playids_own = [];
        this.game_list.forEach(game => {
          if (game.status == 0) {
            cancel_playids_own.push(game.id);
          }
        });

        if (cancel_playids_own.length > 0) {
          obj.cancel_playids_own = cancel_playids_own;
        }
      }

      this.axios.post("play/game_checked", obj).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.$message.success(result.data.shortMessage);

          if (this.user != '') {
            this.get_all_game(this.user);
          } else {
            this.get_all_game();
            this.$emit('change_game_list');
          }
        }
      });
    },

    get_all_game(user = '', data = '', show_panel = true) {
      // console.log(user);
      if (data != '') {
        this.user = user;
        this.show_panel = show_panel;
        let list = data.list;
        list.forEach(element => {
          element.order = element.order == 0 ? '' : element.order;
        });
        this.game_list = list;
      } else {
        let obj = {};

        if (user != '') {
          this.user = user;
          obj.agent_type = 1;
          obj.user_id = user.id;
        }

        this.axios.post("play/getGameInfo", obj).then(result => {
          // console.log(result.data.repsoneContent)
          if (result.data.status == 200) {
            let list = result.data.repsoneContent.list;
            list.forEach(element => {
              element.order = element.order == 0 ? '' : element.order;
            });
            this.game_list = list;
          }
        });
      }
    }

  }
};