import axios from '../../../axios/axios';
export default {
  data() {
    return {
      log_data: [],
      page: 1,
      active_gid: 0,
      game_list: [],
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      qnum: ""
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("trader/trader_record", {
      page: 1,
      gid: '0',
      qnum: ''
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          vm.game_list = JSON.parse(JSON.stringify(vm.$store.state.game_list));
          vm.game_list.unshift({
            id: 0,
            gname: "全部"
          });
          vm.list = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination;
        });
      }
    });
  },

  methods: {
    change_game(gid) {
      this.active_gid = gid;
      this.get_traderRecord_log();
    },

    get_traderRecord_log(page = 1, qnum) {
      if (qnum == undefined && this.qnum == "") {
        qnum = "";
      }

      this.qnum = qnum;
      this.axios.post("trader/trader_record", {
        gid: this.active_gid,
        page,
        qnum: this.qnum
      }).then(result => {
        // console.log(result.data.repsoneContent)
        if (result.data.status == 200) {
          this.log_data = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;
        }
      });
    }

  }
};