import axios from '../../../axios/axios';
export default {
  components: {},

  data() {
    return {
      data: ''
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("play/rules").then(result => {
      if (result.data.status == 1) {
        next(vm => {
          vm.data = result.data.res;
        });
      }
    });
  },

  created() {},

  methods: {}
};