import axios from '../../../axios/axios';
export default {
  components: {},

  data() {
    return {
      log_data: [],
      pagination: {
        page_size: 20,
        total_pages: 8,
        total_records: ""
      },
      page: 1
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("log/admin_login_log", {
      page: 1,
      limit: 20
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.log_data = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination; // 通过 `vm` 访问组件实例
        });
      }
    });
  },

  methods: {
    get_data(page = 1) {
      this.axios.post("log/admin_login_log", {
        page,
        limit: 20
      }).then(result => {
        if (result.data.status == 200) {
          this.log_data = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;
        }
      });
    }

  }
};