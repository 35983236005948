export default {
  components: {},

  data() {
    return {
      user_id: '',
      userlist: [],
      checkList: [],
      all_checked: false
    };
  },

  created() {},

  methods: {
    sub() {
      if (this.checkList.length == 0) {
        return this.$message.error('您还未选择用户');
      }

      this.axios.post("user_version2/sub_account", {
        user_id: this.user_id,
        act: 'add',
        members: this.checkList.toString()
      }).then(result => {
        if (result.data.status == 200) {
          this.$message.success(result.data.shortMessage);
          this.$emit("other_view_back", "");
        }
      });
    },

    refslist() {
      this.checkList = [];
      this.axios.post("user_version2/sub_account", {
        uid: this.user_id,
        act: 'list'
      }).then(result => {
        if (result.data.status == 200) {
          this.getdata(result.data.repsoneContent, this.user_id);
        }
      });
    },

    chang_allchecked(s) {
      this.checkList = [];

      if (s) {
        this.userlist.forEach(item => {
          this.checkList.push(item.id);
        });
      }
    },

    getdata(data, user_id = '') {
      this.userlist = data.list;
      this.user_id = user_id;
      this.userlist.forEach(item => {
        if (item.is_select == 1) {
          this.checkList.push(item.id);
        }
      });
    }

  }
};