import { isArray } from 'util';
export default {
  props: ["userinfo", "game_list"],

  data() {
    return {
      type: "",
      users: "",
      uid: "",

      /**彩种分组 */
      game_group: [],
      loss_data: [],
      active_gid: '',
      active_pid: '',
      play_ids: '',
      fast: {
        lower: "",
        rate: ""
      },
      is_first: false
    };
  },

  created() {
    this.active_gid = this.game_list[0].id;
  },

  methods: {
    /**取消修改 */
    cancel() {
      this.$emit("cancel", "");
    },

    /**提交修改 */
    sub_change_loss() {
      let post_list = [];
      this.loss_data.forEach(e => {
        if (isNaN(e.lower) || isNaN(e.rate)) {
          this.$message({
            message: '请输入正确的数值',
            type: 'error',
            showClose: true,
            offset: 400
          });
        } else {
          if (this.is_first) {
            post_list.push(e);
          } else {
            if (e.rate != e.old_rate || e.lower != e.old_lower) {
              post_list.push(e);
            }
          }
        }
      });

      if (post_list.length === 0) {
        this.$message({
          message: '您未作出任何修改',
          type: 'warning',
          showClose: true,
          offset: 400
        });
        return;
      }

      let user_name = this.users;
      let uid = this.uid;
      uid ? user_name = "" : uid = "";
      this.axios.post("play/bonus_set_member_exe4", {
        uid: uid,
        gid: this.active_gid,
        user_name: user_name,
        play_ids: this.play_ids,
        play_arr_odds: post_list
      }).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent.list)
          this.$message({
            message: result.data.shortMessage,
            type: 'success',
            showClose: true,
            offset: 300
          });
        }
      });
    },

    //快速设置某一列
    fast_set_col() {
      for (const key in this.fast) {
        if (this.fast[key]) {
          for (let i = 0; i < this.loss_data.length; i++) {
            this.loss_data[i][key] = this.fast[key];
          }
        }
      }
    },

    //切换分类
    change_group(pid) {
      // console.log(pid)
      this.active_gid = '';
      this.active_pid = pid;
      this.get_data_list(this.type, this.users, pid);
    },

    //切换彩种
    change_game(gid) {
      this.active_pid = '';
      this.active_gid = gid;
      this.get_data_list(this.type, this.users, '');
    },

    //获取当前赔率数据
    get_data_list(type, user, play_ids) {
      // this.get_game_list();
      this.type = type;
      this.users = user;
      this.play_ids = play_ids;

      if (type == "single") {
        this.uid = user.user_id;
      } else {
        this.uid = "";
      }

      this.axios.post("play/bonus_member", {
        act: "add",
        uid: this.uid,
        gid: this.active_gid,
        play_ids: play_ids //游戏类型，ssc,lhc,pk10,kl8,11x5,xy28,k3,k10

      }).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent.list)
          let game_group = result.data.repsoneContent.play_arr;
          this.is_first = result.data.repsoneContent.is_first == 1 ? true : false;
          this.game_group = [];

          for (const key in game_group) {
            game_group[key].pid = key;
            this.game_group.push(game_group[key]);
          }

          let loss_data = result.data.repsoneContent.list;

          if (this.uid) {
            loss_data.forEach(element => {
              element.old_rate = element.rate;
              element.old_lower = element.lower;
            });
          }

          this.loss_data = loss_data;
        }
      });
    },

    get_game_list() {
      this.axios.post("main/game_list").then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.game_list = result.data.repsoneContent.game_list;
          this.active_gid = this.game_list[0].id;
        }
      });
    }

  }
};