export default {
  props: ["userinfo"],

  data() {
    return {
      change_log_data: [],
      page: 1,
      //请求页码
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      }
    };
  },

  // created() {
  //     this.get_change_log_data();
  // },
  methods: {
    get_change_log_data() {
      this.axios.post("log/admin_log", {
        uid: this.userinfo.id,
        page: this.page,
        limit: 20,
        username: this.userinfo.username
      }).then(result => {
        if (result.data.status == 200) {
          this.change_log_data = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;
        }
      });
    },

    change_page(p) {
      this.page = p;
      this.get_change_log_data();
    },

    get_data(data) {
      data.list.forEach(item => {
        if (Array.isArray(item.new_value)) {
          item.new_value.sort((a, b) => a.id - b.id);
        }
      });
      this.change_log_data = data.list;
      this.pagination = data.pagination;
    }

  }
};