/*
 * @Author: your name
 * @Date: 2022-05-02 14:23:10
 * @LastEditTime: 2022-11-13 14:10:24
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\plugin\jszd_menu.js
 */
export function jszd_menu(gid) {
  let menu = []; //ssc

  if (gid == 1 || gid == 7 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
    menu = [{
      checked: false,
      index: 0,
      link: "trader/index?ball=lm",
      title: "两面",
      gid,
      comp: 'ssc',
      bg: '#fff',
      color: '#333'
    }, {
      checked: false,
      index: 1,
      link: "trader/index?ball=zx",
      title: "1-5球",
      gid,
      comp: 'ssc',
      bg: '#fff',
      color: '#333'
    }];
  } //PK10


  if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
    menu = [{
      gid,
      checked: false,
      index: 0,
      title: "冠、亚军 组合",
      link: "trader/index?ball=1",
      comp: 'pk10',
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 1,
      title: "三、四、伍、六名",
      link: "trader/index?ball=2",
      comp: 'pk10',
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 2,
      title: "七、八、九、十名",
      link: "trader/index?ball=3",
      comp: 'pk10',
      bg: '#fff',
      color: '#333'
    }];
  } //k3


  if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
    menu = [{
      gid,
      checked: false,
      index: 0,
      link: "trader/index?ball=lmzx",
      title: "总项",
      comp: 'k3',
      bg: '#fff',
      color: '#333'
    } // {
    //     gid,
    //     checked: false,
    //     index: 1,
    //     title: "三同号通选",
    //     link: "trader/index?ball=sthtx",
    //     comp:'k3'
    // },
    // {
    //     gid,
    //     checked: false,
    //     index: 2,
    //     title: "三同号单选",
    //     link: "trader/index?ball=sthdx",
    //     comp:'k3'
    // },
    // {
    //     gid,
    //     checked: false,
    //     index: 3,
    //     title: "三不同号",
    //     link: "trader/index?ball=sbth",
    //     comp:'k3'
    // },
    // {
    //     gid,
    //     checked: false,
    //     index: 4,
    //     title: "三连号通选",
    //     link: "trader/index?ball=slhtx",
    //     comp:'k3'
    // },
    // {
    //     gid,
    //     checked: false,
    //     index: 5,
    //     title: "二同号复选",
    //     link: "trader/index?ball=ethfx",
    //     comp:'k3'
    // },
    // {
    //     gid,
    //     checked: false,
    //     index: 6,
    //     title: "二同号单选",
    //     link: "trader/index?ball=ethdx",
    //     comp:'k3'
    // },
    // {
    //     gid,
    //     checked: false,
    //     index: 7,
    //     title: "二不同号",
    //     link: "trader/index?ball=ebth",
    //     comp:'k3'
    // },
    // {
    //     gid,
    //     checked: false,
    //     index: 8,
    //     title: "红黑",
    //     link: "trader/index?ball=hh",
    //     comp:'k3'
    // },
    // {
    //     gid,
    //     checked: false,
    //     index: 9,
    //     title: "红黑码",
    //     link: "trader/index?ball=hhm",
    //     comp:'k3'
    // },
    ];
  } //lhc


  if (gid == 20 || gid == 34 || gid == 48 || gid == 49) {
    menu = [{
      gid,
      checked: false,
      index: 0,
      comp: 'lhc',
      title: "特码A+B",
      link: "trader/index?ball=tm",
      submenu: [{
        title: "特码A+B",
        link: "trader/index?ball=tm"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 1,
      comp: 'lhc',
      title: "特码A",
      link: "trader/index?ball=tma",
      submenu: [{
        title: "特码A",
        link: "trader/index?ball=tma"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 2,
      comp: 'lhc',
      title: "特码B",
      link: "trader/index?ball=tmb",
      submenu: [{
        title: "特码B",
        link: "trader/index?ball=tmb"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 3,
      comp: 'lhc',
      title: "色波",
      link: "trader/index?ball=sb",
      submenu: [{
        title: "色波",
        link: "trader/index?ball=sb"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 4,
      comp: 'lhc',
      title: "特肖",
      link: "trader/index?ball=tx",
      submenu: [{
        title: "特肖",
        link: "trader/index?ball=tx"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 5,
      comp: 'lhc',
      title: "正码/正特",
      link: "trader/index?ball=zm",
      submenu: [{
        title: "正码",
        link: "trader/index?ball=zm"
      }, {
        title: "正特一",
        link: "trader/index?ball=ztm1"
      }, {
        title: "正特二",
        link: "trader/index?ball=ztm2"
      }, {
        title: "正特三",
        link: "trader/index?ball=ztm3"
      }, {
        title: "正特四",
        link: "trader/index?ball=ztm4"
      }, {
        title: "正特五",
        link: "trader/index?ball=ztm5"
      }, {
        title: "正特六",
        link: "trader/index?ball=ztm6"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 6,
      comp: 'lhc',
      title: "正码1-6",
      link: "trader/index?ball=zm1_6",
      submenu: [{
        title: "正码1-6",
        link: "trader/index?ball=zm1_6"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 7,
      comp: 'lhc',
      title: "连码",
      link: "trader/index?ball=lma1",
      submenu: [{
        title: "三全中",
        link: "trader/index?ball=lma1"
      }, {
        title: "三中二",
        link: "trader/index?ball=lma"
      }, {
        title: "二全中",
        link: "trader/index?ball=lma2"
      }, {
        title: "二中特",
        link: "trader/index?ball=lma3"
      }, {
        title: "特串",
        link: "trader/index?ball=lma4"
      }, {
        title: "四中一",
        link: "trader/index?ball=lma5"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 8,
      comp: 'lhc',
      title: "一肖",
      link: "trader/index?ball=yx",
      submenu: [{
        title: "一肖",
        link: "trader/index?ball=yx"
      }, {
        title: "一肖不中",
        link: "trader/index?ball=yxbz"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 9,
      comp: 'lhc',
      title: "自选不中",
      link: "trader/index?ball=bz5",
      submenu: [{
        title: "五不中",
        link: "trader/index?ball=bz5"
      }, {
        title: "六不中",
        link: "trader/index?ball=bz6"
      }, {
        title: "七不中",
        link: "trader/index?ball=bz7"
      }, {
        title: "八不中",
        link: "trader/index?ball=bz8"
      }, {
        title: "九不中",
        link: "trader/index?ball=bz9"
      }, {
        title: "十不中",
        link: "trader/index?ball=bz10"
      }, {
        title: "十一不中",
        link: "trader/index?ball=bz11"
      }, {
        title: "十二不中",
        link: "trader/index?ball=bz12"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 10,
      comp: 'lhc',
      title: "连肖",
      link: "trader/index?ball=lx2",
      submenu: [{
        title: "连肖二肖",
        link: "trader/index?ball=lx2"
      }, {
        title: "连肖三肖",
        link: "trader/index?ball=lx3"
      }, {
        title: "连肖四肖",
        link: "trader/index?ball=lx4"
      }, {
        title: "连肖五肖",
        link: "trader/index?ball=lx5"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 11,
      comp: 'lhc',
      title: "六肖一中",
      link: "trader/index?ball=lxyz",
      submenu: [{
        title: "六肖一中",
        link: "trader/index?ball=lxyz"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 12,
      comp: 'lhc',
      title: "两面",
      link: "trader/index?ball=lm",
      submenu: [{
        title: "两面",
        link: "trader/index?ball=lm"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 13,
      comp: 'lhc',
      title: "尾数",
      link: "trader/index?ball=ws",
      submenu: [{
        title: "尾数",
        link: "trader/index?ball=ws"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 14,
      comp: 'lhc',
      title: "尾连",
      link: "trader/index?ball=wl",
      submenu: [{
        title: "二尾连",
        link: "trader/index?ball=wl"
      }, {
        title: "三尾连",
        link: "trader/index?ball=wl1"
      }, {
        title: "四尾连",
        link: "trader/index?ball=wl2"
      }, {
        title: "五尾连",
        link: "trader/index?ball=wl3"
      }],
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 15,
      comp: 'lhc',
      title: "生肖/尾数量",
      link: "trader/index?ball=sxws",
      submenu: [{
        title: "生肖/尾数量",
        link: "trader/index?ball=sxws"
      }],
      bg: '#fff',
      color: '#333'
    }];
  } //PCDD


  if (gid == 22 || gid == 23) {
    menu = [{
      gid,
      checked: false,
      index: 0,
      title: "总项盘口",
      link: "trader/index?ball=zx",
      comp: 'pcdd',
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 1,
      title: "特码包三",
      link: "trader/index?ball=tmb3",
      comp: 'pcdd',
      bg: '#fff',
      color: '#333'
    }];
  } //FC3D


  if (gid == 4) {
    menu = [{
      gid,
      checked: false,
      index: 0,
      link: "trader/index?ball=lm",
      title: "两面",
      comp: 'fc3d',
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 1,
      link: "trader/index?ball=zx",
      title: "1-3球",
      comp: 'fc3d',
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 2,
      link: "trader/index?ball=hz",
      title: "和值",
      comp: 'fc3d',
      bg: '#fff',
      color: '#333'
    }];
  } //pl35


  if (gid == 10) {
    menu = [{
      gid,
      checked: false,
      index: 0,
      link: "trader/index?ball=lm",
      title: "两面",
      comp: 'pl35',
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 1,
      link: "trader/index?ball=zx",
      title: "1-5球",
      comp: 'pl35',
      bg: '#fff',
      color: '#333'
    }, {
      gid,
      checked: false,
      index: 2,
      link: "trader/index?ball=hz",
      title: "和值",
      comp: 'pl35',
      bg: '#fff',
      color: '#333'
    }];
  }

  return menu;
}