export default {
  props: ["history_data"],

  data() {
    return {
      expands: []
    };
  },

  methods: {
    clickRowHandle(row, column, event) {
      this.expands = [];

      if (this.expands.includes(row.id)) {
        this.expands = this.expands.filter(val => val !== row.id);
      } else {
        this.expands.push(row.id);
      }
    }

  }
};