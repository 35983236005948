import jiaoshou from "./jiaoshou.vue";
import fenlei from "./fenlei.vue";
import datepicker from "../homePublic/datepicker.vue";
export default {
  components: {
    jiaoshou,
    fenlei,
    datepicker
  },

  data() {
    return {
      xunitabledata: [{
        username: "",
        real_name: "",
        bet_count: "",
        sum_bet: "",
        balance: "",
        hy_shuyin: "",
        receive_subline: "",
        sup_occupy_lock: "",
        occu_balance: "",
        bonus: "",
        occu_winlose: "",
        occu_rebate: "",
        real_occupy_result: "",
        earn_rebate: "",
        eran: "",
        real_result: "",
        cont_sup: "",
        receive_supline: ""
      }, {
        username: "",
        real_name: "",
        bet_count: "",
        sum_bet: "",
        balance: "",
        hy_shuyin: "",
        receive_subline: "",
        sup_occupy_lock: "",
        occu_balance: "",
        bonus: "",
        occu_winlose: "",
        occu_rebate: "",
        real_occupy_result: "",
        earn_rebate: "",
        eran: "",
        real_result: "",
        cont_sup: "",
        receive_supline: ""
      }, {
        username: "",
        real_name: "",
        bet_count: "",
        sum_bet: "",
        balance: "",
        hy_shuyin: "",
        receive_subline: "",
        sup_occupy_lock: "",
        occu_balance: "",
        bonus: "",
        occu_winlose: "",
        occu_rebate: "",
        real_occupy_result: "",
        earn_rebate: "",
        eran: "",
        real_result: "",
        cont_sup: "",
        receive_supline: ""
      }, {
        username: "",
        real_name: "",
        bet_count: "",
        sum_bet: "",
        balance: "",
        hy_shuyin: "",
        receive_subline: "",
        sup_occupy_lock: "",
        occu_balance: "",
        bonus: "",
        occu_winlose: "",
        occu_rebate: "",
        real_occupy_result: "",
        earn_rebate: "",
        eran: "",
        real_result: "",
        cont_sup: "",
        receive_supline: ""
      }, {
        username: "",
        real_name: "",
        bet_count: "",
        sum_bet: "",
        balance: "",
        hy_shuyin: "",
        receive_subline: "",
        sup_occupy_lock: "",
        occu_balance: "",
        bonus: "",
        occu_winlose: "",
        occu_rebate: "",
        real_occupy_result: "",
        earn_rebate: "",
        eran: "",
        real_result: "",
        cont_sup: "",
        receive_supline: ""
      }, {
        username: "",
        real_name: "",
        bet_count: "",
        sum_bet: "",
        balance: "",
        hy_shuyin: "",
        receive_subline: "",
        sup_occupy_lock: "",
        occu_balance: "",
        bonus: "",
        occu_winlose: "",
        occu_rebate: "",
        real_occupy_result: "",
        earn_rebate: "",
        eran: "",
        real_result: "",
        cont_sup: "",
        receive_supline: ""
      }],
      width: "630px",
      loading: true,
      dialog: {
        visible: false,
        title: "交收报表"
      },
      date_or_qnum: "按日期：",
      check_game_list: [],
      //选中的彩种
      checked_all: true,
      //是否选择全部彩种
      form: {
        report_type: "交收报表",
        settle_state: 3,
        gid: 0,
        //0 默认全部
        gids: [],
        game_play: "",
        qid: "",
        date: {
          start: "",
          end: ""
        },
        uid: "",
        username: ""
      },
      game_class_checked: "all",
      //选中的类别
      game_class: [//全部类别
      {
        name: "all",
        label: "--- 所有 ---"
      }, {
        name: "kc",
        label: "快彩"
      }, {
        name: "lhc",
        label: "六合彩"
      }],
      kc_game_list: [],
      //快彩列表
      lhc_game_list: [],
      //六合彩列表
      game_play_list_checked: "",
      game_play_list: [],
      //玩法
      game_list: [],
      //用户的彩种
      qnum_list: [],
      //期号
      js_page: 1,
      fl_page: 1,
      onlyUpdate: true,
      game_detail_total: {
        //明细账
        post: {} //明细post参数

      },
      group_id: "",
      show_waibu: false,
      btntext: "查询报表",
      search_title_info: "",
      bbdata: {},
      bbdta_ready: false,
      source: null //存放取消的请求方法

    };
  },

  computed: {
    listenint_router_view() {
      return this.$store.state.int_router_view;
    }

  },
  watch: {
    listenint_router_view(newVal, oldVal) {
      console.log(newVal, oldVal); // 此处处理逻辑

      this.dialog_close();
      this.$store.state.int_router_view = false; // if (newVal) {
      // }

      this.int();
    }

  },

  created() {
    this.get_game_list();
  },

  methods: {
    cancelQuest() {
      if (typeof this.source === "function") {
        this.source("终止请求", "终止请求"); //取消请求
      }
    },

    int() {
      this.dialog.visible = false;
      this.bbdta_ready = false;
      this.$refs.jiaoshou.dialog.visible = "报表";
      this.$refs.jiaoshou.game_detail_data.open = false;
      this.$refs.jiaoshou.back_list = [];
    },

    send_report() {
      this.axios.post("operate/send_report", this.qs.stringify(this.$refs.datePicker.getShowTimeValue())).then(result => {
        if (result.data.status == 200) {
          this.$message.success(result.data.shortMessage);
        }
      });
    },

    //选择类别
    select_class(name) {
      if (name == "all") {
        this.checked_all = true;
        this.check_game_list = this.game_list.map(item => item.id);
      }

      if (name == "kc") {
        this.checked_all = false;
        this.check_game_list = [];
        this.kc_game_list.map(item => this.check_game_list.push(item.id));
      }

      if (name == "lhc") {
        this.checked_all = false;
        this.check_game_list = [];
        this.lhc_game_list.map(item => this.check_game_list.push(item.id));
      }

      this.form.gids = this.check_game_list;
      this.form.game_play = "";
    },

    //选择单个彩种
    select_one_game(gid) {
      console.log(gid);
      this.checked_all = false;
      this.form.gid = gid;
      this.check_game_list = [gid];
      this.form.gids = [gid];
      this.get_game_play_list(gid);
    },

    //选择玩法
    select_game_play() {},

    dialog_close() {
      this.js_page = 1;
      this.fl_page = 1;
      this.form.uid = "";
      this.form.report_type = "交收报表";
      this.form.settle_state = 3;
      this.form.username = "";
      this.dialog.visible = false;
      this.width = "630px"; // this.change_game_all(true);

      this.$nextTick(() => {
        this.$refs["datePicker"].setDate("今天");
      });
    },

    //分类代理点击
    search_sub(id) {
      this.form.uid = id;
      this.form.username = "";
      this.js_page = 1;
      this.get_report().then(() => {
        this.get_report_btn_click();
      });
    },

    //分类分页
    change_page_fl(page) {
      this.fl_page = page;
      this.get_report().then(() => {
        this.get_report_btn_click();
      });
    },

    //交收分页
    change_page_js(page) {
      this.js_page = page;
      this.get_report().then(() => {
        this.get_report_btn_click();
      });
    },

    //分类根据玩法查询
    search_paly(name) {
      this.form.game_play = name;
      this.get_report();
    },

    get_report() {
      const _this = this;

      this.cancelQuest(); //在请求发出前取消上一次未完成的请求；

      return new Promise((resolve, reject) => {
        if (this.form.date.start === "" && this.form.date.end === "") {
          this.form.date = this.$refs["datePicker"].getShowTimeValue();
        }

        let arr = [];

        if (this.checked_all) {
          this.game_list.forEach(item => arr.push(item.id));
          this.form.gids = arr;
        } else {
          arr = this.form.gids;
        }

        if (this.search_title_info = "按日期：") {
          this.search_title_info = `按日期查询：${this.form.date.start.split(" ")[0]} 至 ${this.form.date.end.split(" ")[0]}`;
        } else {
          this.search_title_info = `按期数查询：${this.game_list.find(item => {
            item.gid == form.gid;
          }).gname}--${this.form.qid}`;
        }

        if (this.form.report_type == "交收报表") {
          // console.log(this.form.qid);
          this.dialog.title = "交收报表";
          this.loading = true;
          let post = {
            game_list: this.game_list,
            gid: arr,
            BeginDate: this.form.date.start,
            EndDate: this.form.date.end,
            method_name: this.form.game_play,
            t_status: this.form.settle_state,
            u_id: this.form.uid,
            page: this.js_page,
            limit: 40,
            period: this.form.qid,
            username: this.form.username,
            is_select: this.checked_all ? 1 : 0
          };
          this.game_detail_total.post = JSON.parse(JSON.stringify(post));
          this.axios.post("operate/ReportList_B_kc", post, {
            cancelToken: new this.axios.CancelToken(function executor(c) {
              _this.source = c;
            })
          }).then(result => {
            if (result.data.status == 200) {
              // console.log(result.data.repsoneContent)
              this.loading = false;

              if (this.form.username != "") {
                this.show_waibu = false;
              } else if (this.form.username == "" && result.data.repsoneContent.bet.length > 0 && result.data.repsoneContent.bet[0].group_id == 3) {
                this.show_waibu = true;
              } else {
                this.show_waibu = false;
              } // console.log(this.form.date);


              this.bbdata = result.data.repsoneContent;
              this.bbdta_ready = true; // this.dialog.visible = true;
              // this.btntext = '查询报表';
              // this.$nextTick(() => {
              //     this.$refs.jiaoshou.proc_data(result.data.repsoneContent);
              // });
              // this.width = '100%';

              if (this.bbdta_ready && this.dialog.visible) {
                this.$nextTick(() => {
                  this.$refs.jiaoshou.proc_data(result.data.repsoneContent);
                });
              }
            }

            if (result.data.shortMessage == "系统结算中，请稍后") {
              window.setTimeout(() => {
                this.btntext = "查询报表";
              }, 2000);
            }

            resolve(true);
          }).catch(error => {
            console.log(error.message);
          });
        }

        if (this.form.report_type == "分类报表") {
          this.dialog.title = "分类报表";
          this.loading = true;
          this.axios.post("operate/ReportList_T_kc", {
            gid: arr,
            BeginDate: this.form.date.start,
            EndDate: this.form.date.end,
            method_name: this.form.game_play,
            t_status: this.form.settle_state,
            period: this.form.qid,
            page: this.fl_page,
            limit: 40,
            is_select: this.checked_all ? 1 : 0,
            username: this.form.username
          }, {
            cancelToken: new this.axios.CancelToken(function executor(c) {
              _this.source = c;
            })
          }).then(result => {
            if (result.data.status == 200) {
              // this.dialog.visible = true;
              this.bbdata = result.data.repsoneContent;
              this.bbdta_ready = true; // this.loading = false;
              // this.btntext = '查询报表';
              // // console.log(result.data.repsoneContent)
              // this.width = '100%';

              if (this.bbdta_ready && this.dialog.visible) {
                this.$nextTick(() => {
                  this.$refs.fenlei.proc_data(result.data.repsoneContent);
                });
              }
            }

            if (result.data.shortMessage == "系统结算中，请稍后") {
              window.setTimeout(() => {
                this.btntext = "查询报表";
              }, 2000);
            }

            resolve(true);
          }).catch(error => {
            console.log(error.message);
          });
        }
      }); // console.log(this.form.date)
    },

    //交收明细账
    get_game_detail() {
      this.axios.post("operate/ReportList_Class", this.game_detail_total.post).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          let list = result.data.repsoneContent.class;
          list.forEach(game => {
            game.bet.bet.forEach(item => {
              item.small = game.bet.small;
            });
          });
          this.$nextTick(() => {
            this.$refs.jiaoshou.proc_game_detail_data(list);
          });
        }
      });
    },

    //获取游戏玩法
    get_game_play_list(gid) {
      if (gid != "0") {
        this.axios.post("ajax/get_period_type", {
          gid: gid
        }).then(result => {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            this.game_play_list = result.data.repsoneContent.playoption;
            this.game_play_list.unshift({
              id: 0,
              name: "全部"
            });
            this.form.game_play = result.data.repsoneContent.playoption[0].name; // this.qnum_list = result.data.repsoneContent.phaseoption;
            // this.qnum_list.unshift({
            //     id: 0,
            //     qnum: "全部"
            // })
            // if (this.date_or_qnum === '按期数：') {
            //     this.form.qid = this.qnum_list[1].qnum;
            // } else {
            //     this.form.qid = '';
            // }
          }
        });
      } else {
        // this.qnum_list = [];
        this.form.qid = "";
        this.game_play_list = [];
        this.form.game_play = "";
      }
    },

    get_game_list() {
      // this.game_list = result.data.repsoneContent.game_list;
      this.game_list = this.$store.state.game_list; //判断是否只有六合彩

      let only_lhc = false;
      let lhc = [20, 34, 49];

      if (this.game_list.length <= lhc.length) {
        for (var i = 0; i < this.game_list.length; i++) {
          if (lhc.includes(this.game_list[i].id)) {
            only_lhc = true;
          } else {
            only_lhc = false;
          }
        }
      }

      if (only_lhc) {
        this.game_class = [//全部类别
        {
          name: "all",
          label: "--- 所有 ---"
        }, {
          name: "lhc",
          label: "六合彩"
        }];
      } else {
        var _this$game_list$find, _this$game_list$find2;

        if (((_this$game_list$find = this.game_list.find(item => item.id == 20)) === null || _this$game_list$find === void 0 ? void 0 : _this$game_list$find.status) == 1 || ((_this$game_list$find2 = this.game_list.find(item => item.id == 34)) === null || _this$game_list$find2 === void 0 ? void 0 : _this$game_list$find2.status) == 1 || this.game_list.find(item => {
          var _;

          return item.id == ((_ = 49) === null || _ === void 0 ? void 0 : _.status) == 1;
        })) {
          this.game_class = [//全部类别
          {
            name: "all",
            label: "--- 所有 ---"
          }, {
            name: "kc",
            label: "快彩"
          }, {
            name: "lhc",
            label: "六合彩"
          }];
        } else {
          this.game_class = [//全部类别
          {
            name: "all",
            label: "--- 所有 ---"
          }, {
            name: "kc",
            label: "快彩"
          }];
        }
      }

      this.group_id = this.$store.state.userinfo.group_id;
      this.checked_all = true;
      this.kc_game_list = [{
        id: 0,
        gname: "--- 所有快彩 ---"
      }];
      this.lhc_game_list = [{
        id: 0,
        gname: "--- 所有六合彩 ---"
      }];
      let all_gid = [];
      this.game_list.map(item => {
        if (item.id == 20 || item.id == 34 || item.id == 49) {
          this.lhc_game_list.push(item);
        } else {
          this.kc_game_list.push(item);
        }

        all_gid.push(item.id);
      }); //获取所有期数

      this.axios.post("ajax/get_all_period", {
        gid: all_gid
      }).then(result => {
        if (result.data.status == 200) {
          this.qnum_list = result.data.repsoneContent;
        }
      }); // this.axios.post("main/game_list").then(result => {
      //     if (result.data.status == 200) {
      //     }
      // })
    },

    select_qnum(e) {
      this.date_or_qnum = e;

      if (e === "按日期：") {
        this.form.qid = "";
      } else {// this.form.qid = this.qnum_list[1].qnum;
      }
    },

    get_report_btn_click() {
      // console.log(this.bbdta_ready);
      this.btntext = "查询中...";

      if (this.bbdta_ready) {
        this.dialog.visible = true;

        if (this.dialog.title == "交收报表") {
          this.$nextTick(() => {
            this.$refs.jiaoshou.proc_data(this.bbdata);
          });
        } else {
          this.$nextTick(() => {
            this.$refs.fenlei.proc_data(this.bbdata);
          });
        }

        this.width = "100%";
        this.btntext = "查询报表";
      } else {
        this.get_report().then(() => {
          this.dialog.visible = true;

          if (this.dialog.title == "交收报表") {
            this.$nextTick(() => {
              this.$refs.jiaoshou.proc_data(this.bbdata);
            });
          } else {
            this.$nextTick(() => {
              this.$refs.fenlei.proc_data(this.bbdata);
            });
          }

          this.width = "100%";
          this.btntext = "查询报表";
        });
      }
    },

    get_report_wait() {
      this.dialog.visible = false;
      this.bbdta_ready = false;
      this.get_report();
    },

    //获取时间
    getDate(date, updata) {
      // console.log(date, updata);
      this.bbdta_ready = false;
      this.form.date = date;
      this.get_report(); // if (updata === 'updata') {
      // }
    }

  }
};