import { el } from "element-plus/es/locale";
export default {
  props: ["addinfo"],

  data() {
    return {
      sltuid: "",
      add_user_data: {
        group_id: "",
        level_id: ""
      },
      name: "",
      nickname: "",
      //昵称
      level_id: [],
      pwd: "",
      userCredit_kc: "",
      //信用额度
      kuaicai_userCredit_kc: "",
      //信用额度
      transfer_status: "0",
      //现金模式
      moneyinout_status: "1",
      //是否出入款
      is_inmoney: 0,
      //是否显示入款统计
      autolet_status: 0,
      trader_status: "0",
      //操盘
      trader_diff_user: 0,
      //是否显示赚取赔率差
      trader_diff: false,
      //赚取赔率差
      allowmaxrate_kc: "0",
      //限占1，任占0
      lowmaxrate_kc: "",
      //限占值
      play_list: [{
        old_occupy: ""
      }],
      //游戏列表占成设置
      trader_diff: {
        //赚取赔率差
        status: "0",
        diff: [0.0001, 1]
      },
      posttime: "",
      //生效时间
      // fast_game_set_value: '',//游戏占成快速设置值
      sup_occupy_fly: "1",
      //剩余占成回到公司
      add_oc_url: "0",
      agent_url: "",
      note: "",
      //公司名称
      // sup_occupy_value: '',//自身占成
      sup_occupy_lock: "",
      //锁定自身占成
      is_extract: "1",
      //是否可提现
      sup_occupy_catch: "1",
      //拦截下级补货开关
      is_manlet: "1",
      //开2级及以下时手动补货
      show_is_manlet: false,
      //根据所有六合彩状态判断是否显示手补
      bill_reset: "0",
      //额度还原
      allGamesSet: {
        occupy: "",
        game_list_status: "0"
      },
      copy_user_id: "",
      //赔率复制
      rebate: {
        min_A: "5.0000",
        min_B: "4.0000",
        min_C: "3.0000",
        min_D: "2.0000"
      },
      //所有最高可设退水额度
      rebate_max: 0,
      //当前对应盘口的最高退水额度
      auto_draw_back: {
        show: false,
        value: "0"
      },
      //快彩----------------------
      kuaicai_rebate_max: 0,
      kuaicai_rebate: {
        kuaicai_min_A: "5.0000",
        kuaicai_min_B: "4.0000",
        kuaicai_min_C: "3.0000"
      },
      kuaicai_auto_draw_back: {
        show: false,
        value: "0"
      },
      kuaicai_level_id: [],
      kuaicai_sup_occupy_lock: "",
      //锁定自身占成
      kuaicai_allowmaxrate_kc: "0",
      //限占1，任占0
      kuaicai_lowmaxrate_kc: "",
      //限占值
      auto_manlet_status: 0,
      intercept_proportion_status: "0",
      //拦截占余
      remainder: "0",
      //占余成数归:0占成归自身,1限制下级固定成数
      kuaicai_remainder: "0",
      status: "1",
      check_time: "",
      //提前封盘时间秒
      err: {
        name: {
          visible: false,
          content: ""
        },
        nickname: {
          visible: false,
          content: ""
        },
        pwd: {
          visible: false,
          content: ""
        },
        sup_occupy_lock: {
          visible: false,
          content: ""
        },
        lowmaxrate_kc: {
          visible: false,
          content: ""
        },
        kuaicai_sup_occupy_lock: {
          visible: false,
          content: ""
        },
        kuaicai_lowmaxrate_kc: {
          visible: false,
          content: ""
        },
        userCredit_kc: {
          visible: false,
          content: ""
        },
        kuaicai_userCredit_kc: {
          visible: false,
          content: ""
        },
        check_time: {
          visible: false,
          content: ""
        }
      },
      user_play_status: "kc",
      user_play_status_list: []
    };
  },

  created() {
    this.sltuid = this.addinfo.sub_list[0].id;
  },

  methods: {
    change_user_play_status(user_play_status) {
      this.user_play_status_list = [];

      if (user_play_status == "lh") {
        if (this.add_user_data.status_liuhe == 1) {
          this.user_play_status_list.push(2);
        }

        if (this.add_user_data.status_aomen == 1) {
          this.user_play_status_list.push(3);
        }

        if (this.add_user_data.status_xinaomen == 1) {
          this.user_play_status_list.push(4);
        }
      }
    },

    change_user_play_status_list(list) {
      if (list.length < 1) {
        return this.$message.error("至少选择一个彩种");
      } // if (list.length > 1) {
      //     let value = list[list.length - 1];
      //     if (value == "1") {
      //         this.user_play_status_list = ["1"];
      //     } else {
      //         this.user_play_status_list = this.user_play_status_list.filter((item) => item != "1");
      //     }
      // }
      // console.log(this.user_play_status_list);

    },

    /**切换盘口 */
    change_kuaicai_pk(list) {
      if (list.includes("0") && list[list.length - 1] != "0") {
        this.kuaicai_level_id.shift();
      } else if (list[list.length - 1] == "0") {
        this.kuaicai_level_id = ["0"];
      }
    },

    change_pk(list) {
      if (list.includes("0") && list[list.length - 1] != "0") {
        this.level_id.shift();
      } else if (list[list.length - 1] == "0") {
        this.level_id = ["0"];
      }
    },

    /**退水选择 */
    draw_back_change(type, value) {
      // console.log(value)
      if (type == "lh") {
        if (value == "auto") {
          this.auto_draw_back.show = true;
          this.auto_draw_back.value = "";
        } else {
          this.auto_draw_back.show = false;
        }
      } else {
        if (value == "auto") {
          this.kuaicai_auto_draw_back.show = true;
          this.kuaicai_auto_draw_back.value = "";
        } else {
          this.kuaicai_auto_draw_back.show = false;
        }
      }
    },

    change_rzxz_lhc(s) {
      this.lowmaxrate_kc = this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1;
    },

    change_rzxz_kc(s) {
      this.kuaicai_lowmaxrate_kc = this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1;
    },

    //游戏占成快速设置
    fast_game_set(value) {
      for (const item of this.play_list) {
        item.power_status = this.allGamesSet.power_status;
        item.occupy = value;

        if (item.old_occupy < value) {
          item.occupy = item.old_occupy;
        }
      }
    },

    formatTooltip(value) {
      return (value / 200).toFixed(4);
    },

    proc_data(data) {
      this.add_user_data = data;
      this.kuaicai_allowmaxrate_kc = this.add_user_data.kuaicai_proportion_status;
      this.allowmaxrate_kc = this.add_user_data.proportion_status;
      this.trader_diff_user = this.add_user_data.trader_diff_user;
      this.is_extract = this.add_user_data.extract_bill.toString();
      this.add_user_data.D_odds *= 1;
      this.add_user_data.com_odds[0] *= 1;
      this.add_user_data.com_odds[1] *= 1;

      if (this.add_user_data.D_odds) {
        if (this.add_user_data.D_odds < this.add_user_data.com_odds[0]) {
          this.add_user_data.D_odds = this.add_user_data.com_odds[0];
        }

        this.add_user_data.D_odds *= 1;
        this.add_user_data.com_odds[0] *= 1;
        this.add_user_data.com_odds[1] *= 1;
      }

      this.play_list = this.add_user_data.play_list.filter(item => item.occupy_status == 1); // 所有六合彩打开才显示手补-------->

      let lhc = this.play_list.find(item => item.gname == "六合彩");
      let amlhc = this.play_list.find(item => item.gname == "澳门六合彩");

      if (lhc && lhc.occupy_status != undefined && lhc.occupy_status == 1 || amlhc && amlhc.occupy_status != undefined && amlhc.occupy_status == 1) {
        this.show_is_manlet = true;
      } else {
        this.show_is_manlet = false;
      } //盘口------------------------------<


      this.level_id = this.add_user_data.level_id.split(","); //------------------------------<

      if (this.add_user_data.add_oc_url == 1) {
        this.add_oc_url = this.add_user_data.add_oc_url;
      } //二级开始，一直往下，新增代理的时候，剩余占成默认开启


      if (this.add_user_data.group_id > 2) {
        this.sup_occupy_fly = "1";
      }

      this.sup_occupy_lock = 0;

      if (this.sup_occupy_lock > this.add_user_data.max_sup_lock * 1) {
        this.sup_occupy_lock = this.add_user_data.max_sup_lock * 1;
      } //快彩------------------------------>


      this.kuaicai_level_id = this.add_user_data.kuaicai_level_id.split(","); // this.kuaicai_rebate = this.add_user_data.kuaicai_rebate;

      this.kuaicai_sup_occupy_lock = 0;

      if (this.kuaicai_sup_occupy_lock * 1 > this.add_user_data.kuaicai_max_sup_lock * 1) {
        this.kuaicai_sup_occupy_lock = this.add_user_data.kuaicai_max_sup_lock * 1;
      }

      this.user_play_status_list = [];

      if (this.add_user_data.status_kuaicai == 1) {
        this.user_play_status_list.push("1");
      }

      if (this.add_user_data.status_liuhe == 1) {
        this.user_play_status_list.push("2");
      }

      if (this.add_user_data.status_aomen == 1) {
        this.user_play_status_list.push("3");
      }

      if (this.add_user_data.status_xinaomen == 1) {
        this.user_play_status_list.push("4");
      }

      if (this.add_user_data.note != undefined && this.add_user_data.note != "") {
        this.note = this.add_user_data.note;
      }
    },

    /**获取用户新增菜单选项，权限 */
    get_user_edit(data) {
      if (typeof data == "object") {
        this.proc_data(data);
      } else {
        this.axios.post("user_version2/member_add", {
          sltuid: data,
          groupid_zs: ""
        }).then(result => {
          if (result.data.status === 200) {
            this.proc_data(result.data.repsoneContent);
          }
        });
      }
    },

    /**提交修改 */
    post_change() {
      if (this.name.length < 2) {
        return this.err.name = {
          visible: true,
          content: "账号最少2个字符"
        };
      }

      if (this.nickname.length < 1) {
        return this.err.nickname = {
          visible: true,
          content: "名称最少1个字符"
        };
      }

      if (!/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,20}$/.test(this.pwd)) {
        return this.err.pwd = {
          visible: true,
          content: "密码为8-20位，大小写字母和数字组合"
        };
      }

      if (this.add_user_data.is_liuhe == 1) {
        if (this.sup_occupy_lock < 0 || this.sup_occupy_lock === "") {
          return this.err.sup_occupy_lock = {
            visible: true,
            content: "六合彩保留占成不能小于0"
          };
        }

        if (this.add_user_data.max_sup_lock != 0) {
          if (this.allowmaxrate_kc == 1 && this.lowmaxrate_kc < 0) {
            return this.err.lowmaxrate_kc = {
              visible: true,
              content: "六合彩限制下线可占成数不能小于0"
            };
          }
        } else {
          this.lowmaxrate_kc = 0;
        }

        if (this.sup_occupy_lock * 1 > this.add_user_data.max_sup_lock * 1) {
          return this.err.sup_occupy_lock = {
            visible: true,
            content: `六合彩保留占成不能大于 ${this.add_user_data.max_sup_lock}%`
          };
        }

        if (this.add_user_data.group_id > 2) {
          if (this.lowmaxrate_kc == "" && this.allowmaxrate_kc == 1) {
            this.lowmaxrate_kc = this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1;
          }

          if (this.lowmaxrate_kc * 1 > this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1) {
            return this.err.lowmaxrate_kc = {
              visible: true,
              content: `六合彩限制下线可占成数不能大于 ${this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1}%`
            };
          }
        } else {
          if (this.lowmaxrate_kc * 1 > this.add_user_data.max_sup_lock * 1) {
            return this.err.lowmaxrate_kc = {
              visible: true,
              content: `六合彩限制下线可占成数不能大于 ${this.add_user_data.max_sup_lock}%`
            };
          }
        }

        if (this.lowmaxrate_kc == "") {
          this.lowmaxrate_kc = this.add_user_data.group_id > 2 ? this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1 : this.add_user_data.max_sup_lock * 1;
        }
      } //快彩------------------------------>


      if (this.add_user_data.is_kuaicai == 1) {
        // if (this.kuaicai_auto_draw_back.value != 'all' && this.kuaicai_auto_draw_back.value > this.kuaicai_rebate_max) {
        //     return this.$message.error("赚取退水不得高于" + this.kuaicai_rebate_max);
        // }
        if (this.kuaicai_sup_occupy_lock < 0 || this.kuaicai_sup_occupy_lock === "") {
          return this.err.kuaicai_sup_occupy_lock = {
            visible: true,
            content: "快彩保留占成不能小于0"
          };
        }

        if (this.add_user_data.kuaicai_max_sup_lock * 1 != 0) {
          if (this.kuaicai_allowmaxrate_kc == 1 && this.kuaicai_lowmaxrate_kc < 0) {
            return this.err.kuaicai_lowmaxrate_kc = {
              visible: true,
              content: "快彩限制下线可占成数不能小于0"
            };
          }
        } else {
          this.kuaicai_lowmaxrate_kc = 0;
        }

        if (this.kuaicai_sup_occupy_lock * 1 > this.add_user_data.kuaicai_max_sup_lock * 1) {
          return this.err.kuaicai_sup_occupy_lock = {
            visible: true,
            content: `快彩保留占成不能大于 ${this.add_user_data.kuaicai_max_sup_lock}%`
          };
        }

        if (this.add_user_data.group_id > 1) {
          if (this.kuaicai_lowmaxrate_kc == "" && this.kuaicai_allowmaxrate_kc == 1) {
            this.kuaicai_lowmaxrate_kc = this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1;
          }

          if (this.kuaicai_lowmaxrate_kc * 1 > this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1) {
            return this.err.kuaicai_lowmaxrate_kc = {
              visible: true,
              content: `快彩限制下线可占成数不能大于 ${this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1}%`
            };
          }
        } else {
          if (this.kuaicai_lowmaxrate_kc * 1 > this.add_user_data.kuaicai_max_sup_lock * 1) {
            return this.err.kuaicai_lowmaxrate_kc = {
              visible: true,
              content: `快彩限制下线可占成数不能大于 ${this.add_user_data.kuaicai_max_sup_lock}%`
            };
          }
        }

        if (this.kuaicai_lowmaxrate_kc == "") {
          this.kuaicai_lowmaxrate_kc = this.add_user_data.group_id > 3 ? this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1 : this.add_user_data.kuaicai_max_sup_lock * 1;
        }
      }

      if (this.auto_draw_back.value > this.rebate_max) {
        return this.$message.error("退水不得高于" + this.rebate_max); // return this.$message.error("六合彩赚取退水不得高于" + this.rebate_max);
      }

      if (this.kuaicai_userCredit_kc < 0 || this.kuaicai_userCredit_kc > this.add_user_data.kuaicai_balance * 1) {
        return this.err.kuaicai_userCredit_kc = {
          visible: true,
          content: "清输入正确的快彩额度"
        };
      }

      if (this.userCredit_kc < 0 || this.userCredit_kc > this.add_user_data.balance * 1) {
        return this.err.userCredit_kc = {
          visible: true,
          content: "清输入正确的六合额度"
        };
      }

      if (this.check_time * 1 < 0) {
        return this.err.check_time = {
          visible: true,
          content: "提前封盘时间不能小于0"
        };
      }

      if (this.add_oc_url == 1 && this.add_user_data.group_id < 4) {
        if (this.note == "") {
          return this.$message.error("请填写公司名称！");
        }

        if (this.note.length > 20) {
          return this.$message.error("公司名称最多20个字符");
        }
      }

      let game_change_list = []; //生成每个游戏占成修改的数组数据

      for (let i = 0; i < this.play_list.length; i++) {
        game_change_list.push({
          id: this.play_list[i].id,
          power_status: this.play_list[i].power_status,
          // occupy_status: this.play_list[i].occupy_status,
          occupy_status: 1,
          // occupy: this.play_list[i].occupy == '' ? 'kong' : this.play_list[i].occupy,
          occupy: "kong",
          oid: this.play_list[i].oid,
          old_occupy: this.play_list[i].old_occupy
        });
      } // console.log(this.addinfo);


      let obj = {
        sltupuser: this.add_user_data.par_id,
        group_id: this.add_user_data.group_id,
        agent_mark: this.add_user_data.agent_mark,
        userName: this.name,
        userNicker: this.nickname,
        userPassword: this.pwd,
        userKind_kc: this.level_id,
        autolet_status: this.autolet_status,
        trader_status: Number(this.trader_status),
        transfer_status: this.transfer_status,
        moneyinout_status: this.moneyinout_status,
        is_inmoney: this.is_inmoney,
        userAllowSale_kc: Number(this.autolet_status),
        allowmaxrate_kc: this.allowmaxrate_kc,
        lowmaxrate_kc: this.lowmaxrate_kc,
        userzhan_kc: "1",
        game_change_list: game_change_list,
        kuaicai_userCredit_kc: this.kuaicai_userCredit_kc,
        userCredit_kc: this.userCredit_kc,
        sup_occupy_fly: this.sup_occupy_fly,
        add_oc_url: this.add_oc_url,
        agent_url: this.agent_url,
        note: this.note,
        check_time: this.check_time,
        // sup_occupy_value: this.sup_occupy_value,
        sup_occupy_catch: this.sup_occupy_catch,
        bill_reset: this.bill_reset,
        sup_occupy_lock: this.sup_occupy_lock,
        game_list_status: this.allGamesSet.game_list_status,
        auto_manlet_status: this.is_manlet,
        intercept_proportion_status: this.intercept_proportion_status,
        sltDrawback_kc: this.auto_draw_back.value,
        max_sup_occupy_lock: this.add_user_data.max_sup_lock,
        //快彩-------------
        kuaicai_sltDrawback_kc: this.kuaicai_auto_draw_back.value,
        kuaicai_userKind_kc: this.kuaicai_level_id,
        kuaicai_allowmaxrate_kc: this.kuaicai_allowmaxrate_kc,
        kuaicai_lowmaxrate_kc: this.kuaicai_lowmaxrate_kc,
        kuaicai_sup_occupy_lock: this.kuaicai_sup_occupy_lock,
        max_kuaicai_sup_occupy_lock: this.add_user_data.kuaicai_max_sup_lock,
        //占余成数归
        remainder: this.remainder,
        kuaicai_remainder: this.kuaicai_remainder,
        //彩种
        // user_play_status: this.user_play_status == "kc" ? 1 : this.user_play_status_list.toString(),
        user_play_status: this.user_play_status_list.toString()
      };

      if (this.add_user_data.group_id == 2) {
        obj.copy_user = this.add_user_data.copy_list.find(item => item.id == this.copy_user_id) || "";
      }

      if (this.trader_diff.status === "1") {
        obj.trader_diff = {
          status: "1",
          diff: this.trader_diff.diff.toString()
        };
      } else {
        obj.trader_diff = {
          status: "0",
          diff: ""
        };
      }

      if (this.posttime) {
        obj.posttime = this.posttime;
        obj.value_status = 1;
      }

      if (this.level_id.includes("4") || this.level_id.includes("0")) {
        obj.D_odds = this.add_user_data.D_odds;
        obj.sltDrawback_D = (this.add_user_data.max_rebate / (this.add_user_data.com_odds[1] - this.add_user_data.com_odds[0]) * (this.add_user_data.D_odds - this.add_user_data.com_odds[0])).toFixed(3);
      }

      if (this.add_user_data.extract_bill == 1) {
        obj.extract_bill = this.is_extract;
      }

      if (this.add_user_data.group_id > 1) {
        obj.is_manlet = this.is_manlet;
      }

      this.axios.post("user_version2/member_save", obj).then(result => {
        if (result.data.status == 200) {
          this.$message.success(result.data.shortMessage); //刷新列表

          this.finish_cancel();
        }
      });
    },

    finish_cancel() {
      this.$emit("other_view_back", "");
    }

  }
};