import { fa } from 'element-plus/lib/locale';
import axios from '../../axios/axios';
export default {
  components: {},

  data() {
    return {
      type: '1',
      //页面类型，daili,huiyuan,touzhurenshu
      typename: '管理',
      //页面类型，daili,huiyuan,touzhurenshu
      group_count: [{
        dzj: 0,
        zj: 0,
        fgs: 0,
        dgd: 0,
        gd: 0,
        zdl: 0,
        dl: 0,
        hy: 0
      }],
      list: [],
      page: 1,
      pagination: {
        page_size: "0",
        //每页条数
        total_pages: 0,
        //总页数
        total_records: "0" //总条数

      },
      pc_num: 0,
      phone_num: 0,
      search_group: '',
      weijie: {
        show: false,
        username: '',
        list: [],
        row: '',
        page: 1,
        limit: 20,
        pagination: {
          page_size: "0",
          //每页条数
          total_pages: 0,
          //总页数
          total_records: "0" //总条数

        },
        total: ''
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    let type = to.query.type; // console.log(to, type);

    if (type == 2) {
      axios.post("count/log_bet", {
        page: 1,
        limit: 20
      }).then(result => {
        if (result.data.status == 200) {
          next(vm => {
            vm.type = 2;
            vm.typename = '投注人数';
            vm.proc_data(result.data.repsoneContent);
          });
        }
      });
    } else {
      axios.post("count/log_now", {
        page: 1,
        limit: 20,
        group_status: type,
        group_id: ''
      }).then(result => {
        if (result.data.status == 200) {
          next(vm => {
            vm.type = type;
            vm.typename = type == 1 ? '在线管理' : '在线会员';
            vm.search_group = '';
            vm.proc_data(result.data.repsoneContent); // 通过 `vm` 访问组件实例
          });
        }
      });
    }
  },

  watch: {
    $route(to, from) {
      var _to$query;

      this.type = ((_to$query = to.query) === null || _to$query === void 0 ? void 0 : _to$query.type) || 1;
      this.search_group = '';
      this.ref_list();
    },

    page(n, o) {
      if (this.type == 2) {
        this.get_bet_users(n);
      } else {
        if (this.typename == '在线管理') {
          this.get_users(n, '');
        } else {
          this.get_users(n, this.type);
        }
      }
    }

  },
  methods: {
    //未结注单
    get_weijie(row, page) {
      this.weijie.row = row;
      this.weijie.username = row.username;
      this.axios.post("betbackup/no_order_list", {
        user_id: row.id,
        group_id: row.group_id,
        start_time: '',
        end_time: '',
        page: page,
        limit: 20
      }).then(result => {
        if (result.data.status === 200) {
          if (row.count > 0) {
            if (result.data.repsoneContent == '' || result.data.repsoneContent.longMessage == '暂无数据') {
              row.count = 0;
              this.$message('暂无数据，注单已结算');
              this.get_bet_users(this.page);
            } else {
              this.weijie.list = result.data.repsoneContent.list;
              this.weijie.pagination = result.data.repsoneContent.pagination;
              this.weijie.total = result.data.repsoneContent.total;
              this.weijie.show = true;
            }
          }
        }
      });
    },

    ref_list() {
      if (this.type == 2) {
        this.get_bet_users();
      } else {
        this.get_users(1, this.search_group);
      }
    },

    //踢出系统
    tichu(row) {
      this.$messageBox.confirm('确认要将此用户踢出系统吗？', '操作确认').then(() => {
        this.axios.post("user_version2/user_online_status", {
          id: row.id
        }).then(result => {
          if (result.data.status === 200) {
            this.$message.success(result.data.shortMessage);
            this.get_users(this.page, this.search_group);
          }
        });
      }).catch(() => {});
    },

    //下级分类点击
    get_sub_users(group_name = '', group_id = 0) {
      this.typename = group_name;
      this.type = group_name == '在线会员' ? 0 : 1;
      this.search_group = group_id;
      this.get_users(1, group_id);
    },

    // change_page(page = 1) {
    //     // console.log(page);
    //     this.page = page;
    //     if (this.type == 2) {
    //         this.get_bet_users(page);
    //     } else {
    //         this.get_users(page, this.type);
    //     }
    // },
    get_bet_users(page = 1) {
      let obj = {
        page,
        limit: 20
      };
      this.axios.post('count/log_bet', obj).then(result => {
        if (result.data.status == 200) {
          this.proc_data(result.data.repsoneContent);
        }
      });
    },

    get_users(page = 1, search_group = '') {
      let obj = {
        page,
        limit: 20,
        group_status: this.type,
        group_id: search_group
      };
      this.axios.post('count/log_now', obj).then(result => {
        if (result.data.status == 200) {
          this.proc_data(result.data.repsoneContent);
          this.weijie.show = false;
        }
      });
    },

    proc_data(data = {}) {
      let group_count = data.group_count; // console.log(data);

      group_count.forEach(item => {
        if (item.group_id == 2) {
          this.group_count[0].dzj = item.num;
        }

        if (item.group_id == 3) {
          this.group_count[0].zj = item.num;
        }

        if (item.group_id == 4) {
          this.group_count[0].fgs = item.num;
        }

        if (item.group_id == 5) {
          this.group_count[0].dgd = item.num;
        }

        if (item.group_id == 6) {
          this.group_count[0].gd = item.num;
        }

        if (item.group_id == 7) {
          this.group_count[0].zdl = item.num;
        }

        if (item.group_id == 8) {
          this.group_count[0].dl = item.num;
        }

        if (item.group_id == 0) {
          this.group_count[0].hy = item.num;
        }
      });
      this.list = data.list;
      this.pagination = data.pagination;
      this.pc_num = data.pc_num;
      this.phone_num = data.phone_num;
    }

  }
};