import axios from "../../../axios/axios";
export default {
  components: {},

  data() {
    return {
      list: [] // scale_status: "0",
      // scale_num: "",
      // kusaicai_liuhe_status: { is_kuaicai: "0", is_liuhe: "0", status_aomen: "0", status_liuhe: "0", status_xinaomen: "0" },
      // cur_scale_num: { status_aomen: "", status_kuaicai: "", status_liuhe: "", status_xinaomen: "" },

    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("user_version2/user_scale", {}).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.list = result.data.repsoneContent.list || [];
          vm.list.forEach(item => {
            item.scale_num *= 1;
            item.scale_status *= 1;
          }); // vm.scale_status = result.data.repsoneContent.list.scale_status || "0";
          // vm.scale_num = result.data.repsoneContent.list.scale_num || "";
          // vm.cur_scale_num = result.data.repsoneContent.cur_scale_num;
          // vm.kusaicai_liuhe_status = result.data.repsoneContent.kusaicai_liuhe_status;
        });
      }
    });
  },

  methods: {
    sub() {
      // if (this.scale_num == "" || this.scale_num == "0" || this.scale_num == undefined || Number.isNaN(this.scale_num * 1) || this.scale_num < 1) {
      //     return this.$message.error(`请填写正确的倍数,大于等于1`);
      // }
      // this.scale_num = Number(this.scale_num);
      this.axios.post("user_version2/user_scale", {
        type: "edit",
        // status: this.scale_status,
        // num: this.scale_num,
        list: this.list
      }).then(result => {
        if (result.data.status == 200) {
          this.$message.success(result.data.shortMessage);
          this.get_data();
        }
      });
    },

    get_data() {
      this.axios.post("user_version2/user_scale", {}).then(result => {
        if (result.data.status == 200) {
          this.list = result.data.repsoneContent.list || [];
          this.list.forEach(item => {
            item.scale_num *= 1;
            item.scale_status *= 1;
          }); // this.scale_status = result.data.repsoneContent.list.scale_status || "0";
          // this.scale_num = result.data.repsoneContent.list.scale_num || "";
          // this.cur_scale_num = result.data.repsoneContent.cur_scale_num;
          // this.kusaicai_liuhe_status = result.data.repsoneContent.kusaicai_liuhe_status;
        }
      });
    }

  }
};