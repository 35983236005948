<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-10-12 11:11:24
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-10-12 17:10:17
 * @FilePath: \pc_admin\src\components\self\views\tiqianfengpan.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="tiqianfengpan">
        <div class="panel">
            <div class="panel-title">
                <h2>彩种提前封盘时间设置</h2>
            </div>
            <div class="panel-body">
                <div style="margin: 10px 0">
                    <span>快速设置：</span>
                    <el-button-group class="ml-4">
                        <el-button type="primary" @click="fast_set(0)">0秒</el-button>
                        <el-button type="primary" @click="fast_set(5)">5秒</el-button>
                        <el-button type="primary" @click="fast_set(10)">10秒</el-button>
                        <el-button type="primary" @click="fast_set(15)">15秒</el-button>
                        <el-button type="primary" @click="fast_set(20)">20秒</el-button>
                        <el-button type="primary" @click="fast_set(30)">30秒</el-button>
                    </el-button-group>
                </div>
                <el-row class="content" :gutter="20">
                    <el-col v-for="item of game_list" :key="item.id" :span="8">
                        <div class="item">
                            <el-input v-model="item.check_time" placeholder="请输入秒数" clearable min="0">
                                <template #prepend>
                                    <div style="color: #000; width: 70px">{{ item.gname }}</div>
                                </template>
                                <template #append>
                                    <span>秒</span>
                                </template>
                            </el-input>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <el-button style="margin-top: 10px" @click="get_list">重置</el-button>
            <el-button style="margin-top: 10px" type="warning" @click="save_list">保存修改</el-button>
        </div>
    </div>
</template>

<script>
import axios from "../../../axios/axios";
export default {
    data() {
        return {
            game_list: [],
        };
    },
    beforeRouteEnter(to, from, next) {
        axios.post("user_version2/user_check_time").then((result) => {
            if (result.data.status == 200) {
                next((vm) => {
                    // 通过 `vm` 访问组件实例
                    let list = result.data.repsoneContent.list;
                    vm.game_list = list;
                });
            }
        });
    },
    methods: {
        get_list() {
            this.axios.post("user_version2/user_check_time").then((result) => {
                if (result.data.status == 200) {
                    let list = result.data.repsoneContent.list;
                    this.game_list = list;
                }
            });
        },
        fast_set(time) {
            this.game_list.forEach((item) => (item.check_time = time));
        },
        save_list() {
            for (let index = 0; index < this.game_list.length; index++) {
                if (this.game_list[index].check_time == "") {
                    this.game_list[index].check_time = 0;
                }
                if (this.game_list[index].check_time != 0) {
                    if (Number.isNaN(this.game_list[index].check_time * 1) || this.game_list[index].check_time * 1 < 0) {
                        return this.$message.error(`${this.game_list[index].gname} 的封盘时间不正确，请重新输入`);
                    }
                    if (!Number.isInteger(this.game_list[index].check_time * 1)) {
                        return this.$message.error(`${this.game_list[index].gname} 的封盘时间不正确，请输入整数秒`);
                    }
                }
            }
            this.axios.post("user_version2/user_check_time", { type: "edit", list: this.game_list }).then((result) => {
                if (result.data.status == 200) {
                    this.$message.success(result.data.shortMessage);
                }
            });
        },
    },
};
</script>

<style scoped>
.title {
    font-size: 20px;
    color: #e6a23c;
    font-weight: bold;
}

.content {
    width: 50%;
    position: relative;
    left: 25%;
}

.item {
    margin: 5px;
    width: 100%;
    text-align: left;
}
</style>