import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "change-log"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  const _component_el_pagination = _resolveComponent("el-pagination");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $data.change_log_data,
    border: "",
    size: "mini",
    "highlight-current-row": "",
    "header-cell-class-name": "table-header-cell",
    "header-row-class-name": "table-header-row",
    "table-layout": "auto"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      property: "id",
      label: "编号",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "uptime",
      label: "时间",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "act",
      label: "操作",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "old_value",
      label: "原值",
      align: "left"
    }, {
      default: _withCtx(scope => [typeof scope.row.old_value == 'string' ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(_toDisplayString(scope.row.old_value), 1)], 64)) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(scope.row.old_value, (item, key, i) => {
        return _openBlock(), _createElementBlock("div", {
          style: {
            "display": "inlie-block"
          },
          key: i
        }, _toDisplayString(key) + "：" + _toDisplayString(item), 1);
      }), 128))]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      property: "new_value",
      label: "新值",
      align: "left"
    }, {
      default: _withCtx(scope => [typeof scope.row.new_value == 'string' ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(_toDisplayString(scope.row.new_value), 1)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [Array.isArray(scope.row.new_value) ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(scope.row.new_value, item => {
        return _openBlock(), _createElementBlock("div", {
          style: {
            "display": "inlie-block"
          },
          key: item.id
        }, _toDisplayString(item.label) + "：" + _toDisplayString(item.value), 1);
      }), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
        key: 1
      }, _renderList(scope.row.new_value, (item, key) => {
        return _openBlock(), _createElementBlock("div", {
          style: {
            "display": "inlie-block"
          },
          key: item.id
        }, _toDisplayString(key) + "：" + _toDisplayString(item), 1);
      }), 128))], 64))]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      property: "admin_name",
      label: "操作人",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "ip",
      label: "操作ip",
      align: "center"
    })]),
    _: 1
  }, 8, ["data"]), _createVNode(_component_el_pagination, {
    class: "paging",
    small: "",
    onCurrentChange: $options.change_page,
    modelValue: $data.page,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.page = $event),
    "page-size": 20,
    "pager-count": 5,
    total: $data.pagination.total_records * 1,
    layout: "total,prev,pager,next,jumper",
    "prev-text": "上一页",
    "next-text": "下一页",
    "hide-on-single-page": ""
  }, null, 8, ["onCurrentChange", "modelValue", "total"])]);
}