import usersBatchSetLossGames from "./usersBatchSetLossGames";
export default {
  components: {
    usersBatchSetLossGames
  },
  props: ["game_list"],

  data() {
    return {
      modified_user_list: [],
      //已修改过的用户
      inputVisible: false,
      inputValue: "",
      //当前输入的用户
      // checkAll: false,
      // checkedUsers: [],
      // isIndeterminate: false,
      newUsers: [],
      dialog: {
        visible: false
      }
    };
  },

  mounted() {},

  methods: {
    close() {
      this.dialog.visible = false;
      this.get_users();
    },

    cancel() {
      this.dialog.visible = false;
    },

    refresh() {
      this.get_users();
      this.newUsers = [];
      this.inputValue = "";
    },

    //提交批量设置
    sub_users() {
      if (!this.newUsers.length) {
        this.$message({
          message: '请先添加用户再进行批量设置',
          type: 'error',
          showClose: true,
          offset: 300
        });
        return;
      }

      this.dialog.visible = true;
      this.$nextTick(() => {
        this.$refs.usersBatchSetLossGames.get_data_list("many", this.newUsers, '');
      });
    },

    // 已设置过用户单个修改
    sub_user(user) {
      this.dialog.visible = true;
      this.$nextTick(() => {
        this.$refs.usersBatchSetLossGames.get_data_list("single", user, '');
      });
    },

    //获取当前设置用户列表
    get_users() {
      this.axios.post("play/bonus_member", {
        act: "list"
      }).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.modified_user_list = [];
          let list = result.data.repsoneContent;

          for (let i = 0; i < list.length; i++) {
            this.modified_user_list.push(list[i]);
          }
        }
      });
    },

    //删除已添加用户
    handleClose(type, tag) {
      if (type === 'old') {
        // this.modified_user_list.splice(this.modified_user_list.indexOf(tag), 1);
        this.axios.post("play/bonus_member", {
          act: "delete_user",
          uid: tag
        }).then(result => {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            this.$message({
              message: result.data.shortMessage,
              type: 'success',
              showClose: true,
              offset: 300
            });
            this.get_users();
          }
        });
      } else {
        this.newUsers.splice(this.newUsers.indexOf(tag), 1);
      }
    },

    //添加用户时切换成input
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    //添加用户
    handleInputConfirm() {
      let inputValue = this.inputValue;

      if (inputValue) {
        // console.log(this.modified_user_list.length)
        if (this.modified_user_list.length + this.newUsers.length > 14) {
          this.$message({
            message: '最多添加15个用户',
            type: 'error',
            showClose: true,
            offset: 300
          });
          return;
        }

        for (let i = 0; i < this.modified_user_list.length; i++) {
          if (inputValue === this.modified_user_list[i].username) {
            this.$message({
              message: '您已经添加了此用户，不能重复添加',
              type: 'error',
              showClose: true,
              offset: 300
            });
            return;
          }
        }

        for (let i = 0; i < this.newUsers.length; i++) {
          if (inputValue === this.newUsers[i]) {
            this.$message({
              message: '您已经添加了此用户，不能重复添加',
              type: 'error',
              showClose: true,
              offset: 300
            });
            return;
          }
        }

        this.axios.post("play/bonus_set_member_exe4", {
          type: 1,
          user_name: inputValue
        }).then(result => {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent.list)
            this.newUsers.push(inputValue);
          }
        });
      }

      this.inputVisible = false;
      this.inputValue = '';
    }

  }
};