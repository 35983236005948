import axios from '../../../axios/axios';
import datepicker from '../../homePublic/datepicker.vue';
export default {
  components: {
    datepicker
  },

  data() {
    return {
      list: [],
      page: 1,
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      username: '',
      date: {
        start: '',
        end: ''
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("log/admin_opera_log", {
      page: 1,
      start_time: '',
      end_time: '',
      username: ''
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          vm.list = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination;
        });
      }
    });
  },

  methods: {
    get_admin_log(page = 1) {
      this.page = page;
      let data = {
        page,
        start_time: this.date.start,
        end_time: this.date.end,
        username: this.username
      };
      this.axios.post("log/admin_opera_log", data).then(result => {
        if (result.data.status == 200) {
          this.list = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;
        }
      });
    },

    getDate(date) {
      this.date = date;
      this.get_admin_log(1);
    }

  }
};