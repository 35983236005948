import axios from '../../../axios/axios';
export default {
  components: {},

  data() {
    return {
      list: [],
      page: 1,
      pagination: {
        page_size: 0,
        total_pages: 0,
        total_records: 0
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("user_version2/viewAutoSaleLog", {
      page: 1,
      limit: 20
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.list = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination; // 通过 `vm` 访问组件实例
        });
      }
    });
  },

  methods: {
    get_data(page = 1) {
      this.axios.post("user_version2/viewAutoSaleLog", {
        page,
        limit: 20
      }).then(result => {
        if (result.data.status == 200) {
          this.list = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;
        }
      });
    }

  }
};