import axios from '../axios/axios';
import top from '../components/homePublic/top.vue';
import usermenu from '../components/homePublic/usermenu.vue';
export default {
  name: 'Home',
  components: {
    top,
    usermenu
  },

  provide() {
    return {
      reload: this.reload
    };
  },

  data() {
    return {
      activated: '',
      game_list: [],
      isRouterAlive: true
    };
  },

  created() {},

  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },

    change_play(name) {
      this.$refs['usermenu'].submenu_change(name);
    },

    change_submenu(name, params) {
      // console.log(this.$refs['usermenu']);
      let menu = this.$refs['usermenu'].submenu.find(item => item.title == name);
      this.$refs['usermenu'].click_sub_menu(menu, params);
    },

    change_color(color) {
      let strs = color.split(','); // let h = (strs[0].split('(')[1] * 1).toFixed(1);

      let h = strs[0].match(/\d+/g)[0];
      let s = strs[1].split('%')[0];
      let l = strs[2].split('%')[0];

      if (l < 95) {
        l = 95;
      }

      if (s > 35) {
        s = 35;
      } // console.log(h,s,l);


      let themeColorLight = `hsl(${h},${s}%,${l}%)`;
      let themeColorhalf = `hsl(${h},30%,75%)`;
      let themeColorhalf_l = `hsl(${h},50%,80%)`;
      this.$emit('get_color', color, themeColorLight, themeColorhalf, themeColorhalf_l);
      this.$nextTick(() => {
        this.$refs['usermenu'].change_color(color);
      });
    },

    get_activated(activated_text) {
      this.activated = activated_text;
    }

  }
};