import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "inner"
};
export function render(_ctx, _cache) {
  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, {
    onChange_game_list: _cache[0] || (_cache[0] = $event => _ctx.$emit('change_game_list'))
  })]);
}