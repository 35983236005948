import axios from '../../../axios/axios';
export default {
  name: 'wanfazhuangtai',
  components: {},

  data() {
    return {
      gid: '',
      pid: '',
      game_list: [],
      game_group: [],

      /**彩种分组 */
      list: [],
      type: '' //当前模式，单个/分类

    };
  },

  beforeRouteEnter(to, from, next) {
    let gid = to.query.gid;
    axios.post("play/method_set", {
      gid: gid,
      act: 'list'
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          vm.gid = gid;
          vm.game_list = vm.$store.state.game_list;
          let data = result.data.repsoneContent.list;
          vm.list = [];

          for (let item in data) {
            vm.list.push(data[item]);
          }

          let game_group = result.data.repsoneContent.play_arr;
          let group = [];

          for (const key in game_group) {
            game_group[key].pid = key;
            group.push(game_group[key]);
          }

          vm.game_group = group;
          vm.type = 'one';
        });
      }
    });
  },

  created() {},

  methods: {
    change_status(row, status) {
      let obj = {
        act: 'edit',
        play_str: row.play_str,
        id: row.id,
        status: status
      };

      if (this.type == 'one') {
        obj.gid = this.gid;
      } else {
        obj.pid = this.pid;
      }

      this.axios.post("play/method_set", obj).then(result => {
        if (result.data.status == 200) {}
      });
    },

    change_game(gid) {
      this.axios.post("play/method_set", {
        gid: gid,
        act: 'list'
      }).then(result => {
        if (result.data.status == 200) {
          let data = result.data.repsoneContent.list;
          let list = [];

          for (let item in data) {
            list.push(data[item]);
          }

          this.list = list;
        }
      });
      this.gid = gid;
      this.pid = '';
      this.type = 'one';
    },

    change_group(pid) {
      this.axios.post("play/method_set", {
        pid: pid,
        act: 'list'
      }).then(result => {
        if (result.data.status == 200) {
          let data = result.data.repsoneContent.list;
          let list = [];

          for (let item in data) {
            list.push(data[item]);
          }

          this.list = list;
        }
      });
      this.pid = pid;
      this.gid = '';
      this.type = 'group';
    }

  }
};