import ssc from "../history/game/ssc.vue";
import pk10 from "../history/game/pk10.vue";
import lhc from "../history/game/lhc.vue";
import xy28 from "../history/game/xy28.vue";
import k3 from "../history/game/k3.vue";
import { pcddNum } from "../../plugin/pcddNum";
import { lhcNum } from "../../plugin/lhcNum";
import { get_lhc_sx } from "../../plugin/sx";
export default {
  props: ["from", "rowinfo", "form", "gameClassID"],
  components: {
    ssc,
    pk10,
    lhc,
    xy28,
    k3
  },

  data() {
    return {
      dialog: {
        visible: false,
        detail_data: [],
        page: 1,
        pagination: {
          //分页信息
          total_pages: "",
          total_records: "",
          page_size: ""
        },
        row: ""
      },
      isGetBuHuoDetail: false,
      history_data: [],
      componentId: "",
      gid: "",
      is_eran: "0"
    };
  },

  created() {// this.search_detail(this.rowinfo, this.form);
    // console.log(this.rowinfo);
  },

  methods: {
    //获取显示开奖号码
    show_kaijiang(row) {
      this.history_data = [];
      this.gid = row.game_id;
      let gid = this.gid;

      if (gid == 1 || gid == 4 || gid == 7 || gid == 10 || gid == 18 || gid == 38 || gid == 39 || gid == 44 || gid == 45 || gid == 46) {
        this.componentId = "ssc";
      } else if (gid == 3 || gid == 19 || gid == 24 || gid == 28 || gid == 37 || gid == 40 || gid == 43 || gid == 47) {
        this.componentId = "pk10";
      } else if (gid == 20 || gid == 34 || gid == 48 || gid == 49) {
        this.componentId = "lhc";
      } else if (gid == 22 || gid == 23) {
        this.componentId = "xy28";
      } else if (gid == 6 || gid == 29 || gid == 30 || gid == 31 || gid == 32 || gid == 36) {
        this.componentId = "k3";
      }

      this.axios.post("betresult/get_periods_result", {
        gid: row.game_id,
        qnum: row.qnum
      }).then(result => {
        if (result.data.status == 200) {
          if (result.data.repsoneContent.res != undefined) {
            this.pagination = result.data.repsoneContent.pagination;
            this.history_data = [result.data.repsoneContent]; //处理是pc蛋蛋的结果

            if (gid == 22 || gid == 23) {
              let list = [result.data.repsoneContent];

              for (let i = 0; i < list.length; i++) {
                for (let j = 0; j < list[i].res.length; j++) {
                  list[i].res[j] = pcddNum(list[i].res[j]);
                }

                list[i].sum = pcddNum(list[i].sum);
              }

              this.history_data = list;
            } //处理六合彩


            if (gid == 20 || gid == 34 || gid == 48 || gid == 49) {
              let list = [result.data.repsoneContent];

              for (let i = 0; i < list.length; i++) {
                let arr = [];

                for (let j = 0; j < list[i].res.length; j++) {
                  arr.push(lhcNum(lhcNum(list[i].res[j], this.$filters.time(list[i].closetime).split(" ")[0])));
                }

                list[i].res_s = get_lhc_sx("开奖", arr);
              }

              this.history_data = list;
            }
          }
        }
      });
    },

    tableRowClassName_del_red_line({
      row,
      rowIndex
    }) {
      if (row.remark === "后台撤单") {
        return "tableRowClassName_del_red_line";
      }

      return "";
    },

    dialog_change_page(page) {
      this.dialog.page = page;
      this.search_detail(this.rowinfo);
    },

    search_detail(row) {
      this.dialog.row = row;
      let obj = {};

      if (this.from == "分类报表") {
        obj = this.rowinfo;
        obj.page = this.dialog.page; // obj = {
        //     gid: row.game_id,
        //     userid: "",
        //     phaseid: this.form.qid,
        //     page: this.dialog.page,
        //     limit: 20,
        //     t_status: this.form.settle_state,
        //     m_type: "zd",
        //     up_id: this.$store.state.userinfo.user_id,
        //     level_two: row.level_two,
        //     level_three: row.level_three,
        //     level_four: row.level_four,
        //     BeginDate: this.form.date.start,
        //     EndDate: this.form.date.end,
        // }
      }

      if (this.from == "交收报表") {
        obj = {
          gid: this.gameClassID === "" ? this.form.gid : this.gameClassID,
          //判断是总账还是明细账
          userid: row.id,
          page: this.dialog.page,
          limit: 20,
          t_status: this.form.settle_state,
          BeginDate: this.form.date.start,
          EndDate: this.form.date.end,
          phaseid: this.form.qid,
          bet_count: row.bet_count
        };

        if (this.isGetBuHuoDetail) {
          obj.is_manlet = 1;
          obj.is_fly = 1;
        }

        if (row.fly != undefined && row.fly != "") {
          obj.fly = row.fly;
        }

        if (row.is_fly != undefined && row.is_fly != "") {
          obj.is_fly = row.is_fly;
        }

        if (obj.fly == "1" || obj.is_fly == "1") {
          this.isGetBuHuoDetail = true;
          obj.userid = this.form.uid != "" && this.form.uid != undefined ? this.form.uid : "";
        } else {
          this.isGetBuHuoDetail = false;
        }
      }

      this.axios.post("operate/ReportDetail_B_kc", obj).then(result => {
        if (result.data.status == 200) {
          this.dialog.visible = true;
          this.is_eran = result.data.repsoneContent.is_eran;
          this.dialog.pagination = result.data.repsoneContent.pagination;
          this.dialog.detail_data = result.data.repsoneContent.list;
          let group_id = this.$store.state.userinfo.group_id;

          for (let i = 0; i < this.dialog.detail_data.length; i++) {
            if (this.dialog.detail_data[i].agent_list.length === 2) {
              let agent_list = [];
              agent_list.push(this.dialog.detail_data[i].agent_list[1]);
              this.dialog.detail_data[i].agent_list = agent_list;
            }

            for (let j = 0; j < this.dialog.detail_data[i].agent_list.length; j++) {
              if (this.dialog.detail_data[i].agent_list[j].name == "自己" || group_id == this.dialog.detail_data[i].agent_list[j].group_id) {
                this.dialog.detail_data[i].myResult = (this.dialog.detail_data[i].agent_list[j].occupy_bill * 1).toFixed(0);
              }
            }
          }
        }
      });
    },

    proc_data(data, isGetBuHuoDetail = false, row) {
      this.isGetBuHuoDetail = isGetBuHuoDetail;
      this.dialog.visible = true;
      this.is_eran = data.is_eran;
      this.dialog.pagination = data.pagination;
      this.dialog.detail_data = data.list;
      let group_id = this.$store.state.userinfo.group_id;

      for (let i = 0; i < this.dialog.detail_data.length; i++) {
        if (this.dialog.detail_data[i].agent_list.length === 2) {
          let agent_list = [];
          agent_list.push(this.dialog.detail_data[i].agent_list[1]);
          this.dialog.detail_data[i].agent_list = agent_list;
        }

        for (let j = 0; j < this.dialog.detail_data[i].agent_list.length; j++) {
          if (this.dialog.detail_data[i].agent_list[j].name == "自己" || group_id == this.dialog.detail_data[i].agent_list[j].group_id) {
            this.dialog.detail_data[i].myResult = (this.dialog.detail_data[i].agent_list[j].occupy_bill * 1).toFixed(0);
          }
        }
      }
    }

  }
};