export default {
  props: {
    form: {},
    list: {
      type: Array,
      default: []
    }
  },
  methods: {
    baobiao_sum(param) {
      let {
        columns,
        data
      } = param;
      let sums = [];
      columns.forEach((column, index) => {
        if (column.label == '代理') {
          return sums[index] = '';
        }

        if (column.label == '名称') {
          return sums[index] = '合计';
        } // 笔数


        if (column.label == '笔数') {
          return sums[index] = data[0].small.bet_count;
        } //总投注额


        if (column.label == '总投注额') {
          return sums[index] = data[0].small.sum_bet;
        } //有效金额


        if (column.label == '有效金额') {
          return sums[index] = data[0].small.balance;
        } //会员输赢


        if (column.label == '会员输赢') {
          return sums[index] = (data[0].small.bonus * 1 - data[0].small.balance * 1).toFixed(0);
        } //应收下线


        if (column.label == '应收下线') {
          return sums[index] = data[0].small.receive_subline;
        } // //实占成数


        if (column.label == '占成') {
          return sums[index] = '';
        } //实占注额


        if (column.label == '实占注额') {
          return sums[index] = data[0].small.occu_balance;
        } //占货比


        if (column.label == '占货比') {
          return sums[index] = '';
        } //实占输赢


        if (column.label == '实占输赢') {
          return sums[index] = data[0].small.occu_winlose;
        } //实占退水


        if (column.label == '实占退水') {
          return sums[index] = data[0].small.occu_rebate;
        } //实占结果


        if (column.label == '实占结果') {
          return sums[index] = data[0].small.real_occupy_result;
        } //赚取退水


        if (column.label == '赚取退水') {
          return sums[index] = data[0].small.earn_rebate;
        } //赚取赔差


        if (column.label == '赚取赔差') {
          return sums[index] = data[0].small.eran;
        } //退水后结果


        if (column.label == '退水后结果') {
          return sums[index] = data[0].small.real_result;
        } //贡献上级


        if (column.label == '贡献上级') {
          return sums[index] = data[0].small.cont_sup;
        } //应付上级


        if (column.label == '应付上级') {
          return sums[index] = data[0].small.receive_supline;
        }
      });
      return sums;
    },

    //报表列的颜色
    cellClassName({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex != 0 && columnIndex != 1) {
        //合计列
        this.$nextTick(() => {
          let dom = this.$refs.tableRef.$refs.footerWrapper.querySelector('.el-table__footer').querySelectorAll('td')[columnIndex].querySelectorAll('.cell')[0];
          let val = this.$refs.tableRef.$refs.footerWrapper.querySelector('.el-table__footer').querySelectorAll('td')[columnIndex].querySelectorAll('.cell')[0].innerText;
          dom.style.color = !Number.isNaN(val) && val < 0 ? 'red' : '#000';
        }); //数据列

        let columnProperty = this.data_list[rowIndex][column.property];

        if (columnProperty < 0) {
          return 'redcolor'; // class名称
        } else {
          return 'blackcolor'; // class名称
        }
      }
    }

  }
};