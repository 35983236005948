import axios from '../../../axios/axios';
import usersBatchSetLoss from "./usersBatchSetLoss.vue";
import ballLossSet from "./ballLossSet.vue";
export default {
  name: 'peilvsheding',
  components: {
    usersBatchSetLoss,
    ballLossSet
  },

  data() {
    return {
      set_data: [],
      active_gid: "",
      game_list: [],
      dialog: {
        visible: '赔率设定'
      },
      game_group: [],

      /**彩种分组 */
      play_param: "",
      type: "",
      //当前模式，单个/分类
      active_pid: "",
      //当前激活的分类pid
      fast: {
        play_rate: "",
        low_rate: "",
        b_rate: "",
        c_rate: "",
        lower: "",
        rate: ""
      },
      parentMode: '',
      user_id: '',
      checked_name: '',
      show_type: false
    };
  },

  beforeRouteEnter(to, from, next) {
    // console.log(to.query.user_id);
    let group = [];
    axios.post("play/bonus_set", {
      pid: 1,
      login_user: to.query.user_id
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.game_list = result.data.repsoneContent.game_list;
          vm.active_gid = '';
          vm.checked_name = vm.game_list[0].gname;
          let data = result.data.repsoneContent.list;
          vm.set_data = [];

          for (let item in data) {
            vm.set_data.push(data[item]);
          }

          let game_group = result.data.repsoneContent.play_arr;

          for (const key in game_group) {
            game_group[key].pid = key;
            group.push(game_group[key]);
          }

          vm.game_group = group;
          vm.type = "list";
          vm.active_pid = group[0].pid;
          vm.checked_name = vm.game_group.find(item => item.pid == vm.active_pid).name;

          if (vm.$store.state.userinfo.user_id != to.query.user_id) {
            vm.user_id = to.query.user_id;
          }
        });
      }
    });
  },

  methods: {
    //切换降赔类型
    change_type(value, row) {
      // console.log(value, row);
      let list = [];

      if (row.name == '连肖二肖正肖' || row.name == '连肖二肖平肖') {
        list.push('连肖二肖正肖');
        list.push('连肖二肖平肖');
      }

      if (row.name == '连肖三肖正肖' || row.name == '连肖三肖平肖') {
        list.push('连肖三肖正肖');
        list.push('连肖三肖平肖');
      }

      if (row.name == '连肖四肖正肖' || row.name == '连肖四肖平肖') {
        list.push('连肖四肖正肖');
        list.push('连肖四肖平肖');
      }

      if (row.name == '连肖五肖正肖' || row.name == '连肖五肖平肖') {
        list.push('连肖五肖正肖');
        list.push('连肖五肖平肖');
      }

      if (row.name == '二肖连不中正肖' || row.name == '二肖连不中平肖') {
        list.push('二肖连不中正肖');
        list.push('二肖连不中平肖');
      }

      if (row.name == '三肖连不中正肖' || row.name == '三肖连不中平肖') {
        list.push('三肖连不中正肖');
        list.push('三肖连不中平肖');
      }

      if (row.name == '四肖连不中正肖' || row.name == '四肖连不中平肖') {
        list.push('四肖连不中正肖');
        list.push('四肖连不中平肖');
      }

      if (row.name == '五肖连不中正肖' || row.name == '五肖连不中平肖') {
        list.push('五肖连不中正肖');
        list.push('五肖连不中平肖');
      }

      if (row.name == '二尾连0' || row.name == '二尾连1-9') {
        list.push('二尾连0');
        list.push('二尾连1-9');
      }

      if (row.name == '三尾连0' || row.name == '三尾连1-9') {
        list.push('三尾连0');
        list.push('三尾连1-9');
      }

      if (row.name == '四尾连0' || row.name == '四尾连1-9') {
        list.push('四尾连0');
        list.push('四尾连1-9');
      }

      if (row.name == '五尾连0' || row.name == '五尾连1-9') {
        list.push('五尾连0');
        list.push('五尾连1-9');
      }

      if (row.name == '二尾连0不中' || row.name == '二尾连1-9不中') {
        list.push('二尾连0不中');
        list.push('二尾连1-9不中');
      }

      if (row.name == '三尾连0不中' || row.name == '三尾连1-9不中') {
        list.push('三尾连0不中');
        list.push('三尾连1-9不中');
      }

      if (row.name == '四尾连0不中' || row.name == '四尾连1-9不中') {
        list.push('四尾连0不中');
        list.push('四尾连1-9不中');
      }

      if (row.name == '五尾连0不中' || row.name == '五尾连1-9不中') {
        list.push('五尾连0不中');
        list.push('五尾连1-9不中');
      }

      list.forEach(name => {
        this.set_data.forEach(item => {
          if (name == item.name) {
            item.type = value;
          }
        });
      });
    },

    //定位胆设置
    ball_set(row) {
      let obj = {
        high_bonus: row.high_rate,
        play_str: row.play_str
      };

      if (this.$store.state.userinfo.user_id != this.user_id) {
        obj.user_id = this.user_id;
      }

      if (this.active_gid) {
        obj.gid = this.active_gid;
      } else {
        obj.pid = this.active_pid;
      }

      this.axios.post("play/auto_ball_rate", obj).then(result => {
        if (result.data.status == 200) {
          this.dialog.visible = '球号设定';
          this.$nextTick(() => {
            this.$refs['ballLossSet'].get_ball_set(result.data.repsoneContent.list, obj, this.user_id);
          });
        }
      });
    },

    //特殊用户设置
    get_users_set() {
      this.dialog.visible = '特殊用户';
      this.$nextTick(() => {
        this.$refs.usersBatchSetLoss.get_users();
      });
    },

    //提交赔率设置
    sub_set() {
      let post_data = "";
      let url = "";

      if (this.type === "one") {
        post_data = {
          gid: this.active_gid,
          play_arr_str: [],
          play_rate: [],
          low_rate: [],
          b_rate: [],
          c_rate: [],
          lower: [],
          rate: [],
          type: []
        };
        url = "play/bonus_set_exe";
      } else {
        post_data = {
          pid: this.active_pid,
          play_arr_str: [],
          play_rate: [],
          low_rate: [],
          b_rate: [],
          c_rate: [],
          lower: [],
          rate: [],
          type: []
        };
        url = "play/class_bonus_set";
      }

      for (let item of this.set_data) {
        if (isNaN(item.play_rate * 1) || isNaN(item.high_rate * 1) || isNaN(item.low_rate * 1) || isNaN(item.b_rate * 1) || isNaN(item.c_rate * 1) || isNaN(item.lower * 1) || isNaN(item.rate * 1)) {
          return this.$message.error('请输入正确的数字！');
        } else if (item.play_rate * 1 > item.high_rate * 1 || item.play_rate * 1 < item.low_rate * 1) {
          return this.$message.error(item.name + '赔率设定不能超过上限或者低于下限');
        } else if (item.b_rate * 1 > item.high_b_rate * 1) {
          return this.$message.error(item.name + '赔率设定不能超过上限');
        } else if (item.c_rate * 1 > item.high_c_rate * 1) {
          return this.$message.error(item.name + '赔率设定不能超过上限');
        } else {
          post_data.play_arr_str.push(item.play_str);
          post_data.play_rate.push(item.play_rate);
          post_data.low_rate.push(item.low_rate);
          post_data.b_rate.push(item.b_rate);
          post_data.c_rate.push(item.c_rate);
          post_data.lower.push(item.lower);
          post_data.rate.push(item.rate);
          post_data.type.push(item.type);
        }
      }

      if (this.user_id != '') {
        post_data.user_id = this.user_id;
      }

      this.axios.post(url, post_data).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.$message.success(result.data.shortMessage);
        }
      });
    },

    //重置
    reset() {
      this.active_gid = 1;
      this.get_game_set_data(this.type, 1, this.user_id);

      for (const key in this.fast) {
        this.fast[key] = "";
      }
    },

    //快速设置某一列
    fast_set_col() {
      for (const key in this.fast) {
        if (this.fast[key]) {
          for (let i = 0; i < this.set_data.length; i++) {
            this.set_data[i][key] = this.fast[key];
          }
        }
      }
    },

    //切换分类
    change_group(pid) {
      this.type = "list";
      this.get_game_set_data(this.type, pid, this.user_id);
    },

    //切换彩种
    change_game(gid) {
      this.type = "one";
      this.get_game_set_data(this.type, gid, this.user_id);
    },

    get_data(d = '', user_id = '', show = false) {
      this.game_list = d.game_list;
      let data = d.list;
      this.set_data = [];

      for (let item in data) {
        this.set_data.push(data[item]);
      }

      let group = [];
      let game_group = d.play_arr;

      for (const key in game_group) {
        game_group[key].pid = key;
        group.push(game_group[key]);
      }

      this.game_group = group;
      this.type = "list";
      this.parentMode = 'agency';
      this.active_pid = group[0].pid;
      this.user_id = user_id;
      this.checked_name = this.game_group.find(item => item.pid == this.active_pid).name;
      this.show_type = show;
    },

    get_game_set_data(type, id, user_id = '') {
      let group = [];
      this.type = type;

      if (type === "one") {
        this.active_pid = "";
        this.active_gid = id;
      } else {
        this.active_gid = "";
        this.active_pid = id;
      }

      if (user_id != '') {
        this.user_id = user_id;
        this.parentMode = 'agency';
      } else {
        this.user_id = '';
        this.parentMode = '';
      }

      let urlstr = '';

      if (this.$store.state.userinfo.group_id > 1) {
        if (type === "one") {
          urlstr = {
            gid: id // user_id: this.user_id,

          };
        } else {
          urlstr = {
            pid: id // user_id: this.user_id,

          };
        }
      } else {
        if (type === "one") {
          urlstr = {
            gid: id // user_id: this.user_id,

          };
        } else {
          urlstr = {
            pid: id // user_id: this.user_id,

          };
        }
      }

      if (this.user_id != this.$store.state.userinfo.user_id) {
        urlstr.user_id = this.user_id;
      }

      urlstr.login_user = this.$store.state.userinfo.user_id;
      this.axios.post("play/bonus_set", urlstr).then(result => {
        // console.log(result.data.repsoneContent)
        if (result.data.status == 200) {
          let data = result.data.repsoneContent.list;
          this.set_data = [];

          for (let item in data) {
            this.set_data.push(data[item]);
          }

          let game_group = result.data.repsoneContent.play_arr;

          for (const key in game_group) {
            game_group[key].pid = key;
            group.push(game_group[key]);
          }

          this.game_group = group;

          if (type === "one") {
            this.checked_name = this.game_list.find(item => item.id == this.active_gid).gname;
          } else {
            this.checked_name = this.game_group.find(item => item.pid == this.active_pid).name;
          }
        }
      });
    } // get_game_list(from = 'inner', user_id = '') {
    //     if (from == 'inner') {
    //         this.axios.post("main/game_list").then(result => {
    //             if (result.data.status == 200) {
    //                 this.game_list = result.data.repsoneContent.game_list;
    //                 let gid = this.game_list[0].id;
    //                 this.checked_name = this.game_list[0].gname;
    //                 this.get_game_set_data('one', gid);
    //             }
    //         })
    //     } else {
    //         this.axios.post("main/game_user_list", { user_id: user_id }).then(result => {
    //             if (result.data.status == 200) {
    //                 this.game_list = result.data.repsoneContent.game_list;
    //                 let gid = this.game_list[0].id;
    //                 this.get_game_set_data('one', gid, user_id)
    //             }
    //         })
    //     }
    // },


  }
};