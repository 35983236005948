export default {
  props: ["userinfo"],

  data() {
    return {
      cash_flow_data: [],
      page: 1,
      //请求页码
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      flag: "" //充值类型1充值，2提现，全部不传

    };
  },

  // created() {
  //     this.get_cash_flow_data();
  // },
  methods: {
    get_cash_flow_data() {
      this.axios.post("account/credit_flow_list", {
        uid: this.userinfo.id,
        page: this.page,
        limit: 20,
        username: this.userinfo.username,
        flag: this.flag
      }).then(result => {
        if (result.data.status == 200) {
          this.cash_flow_data = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;
        }
      });
    },

    change_page(p) {
      this.page = p;
      this.get_cash_flow_data();
    },

    get_data(data) {
      this.cash_flow_data = data.list;
      this.pagination = data.pagination;
    }

  }
};