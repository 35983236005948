export default {
  components: {},
  props: {
    from: "",
    //来自哪里调用当前组件
    //是否显示快捷按钮组
    showBtns: {
      type: Boolean,
      default: false
    },
    //是否在挂载完成后上报父级
    onlyUpdate: {
      type: Boolean,
      default: false
    },
    //禁用选择时间，之前多少天
    disabledDate: {
      type: Number,
      default: 0
    },
    //延时时间，每天起始时间往后推多少
    delayTime: {
      type: Number,
      default: 0
    },
    defalut: ""
  },

  data() {
    return {
      date: {
        start: new Date().setHours(0, 0, 0),
        end: new Date().setHours(23, 59, 59)
      },
      shortcuts: [{
        text: "今天",
        value: new Date()
      }]
    };
  },

  created() {
    this.setDate("今天");
  },

  methods: {
    //获取当前显示的默认时间
    getShowTimeValue() {
      return this.dateFormat(this.date);
    },

    //设置时间按钮
    setDate(text) {
      //当前为早6点起止，注释的是0点到0点
      if (text === "今天") {
        if (new Date().getHours() >= 6) {
          this.date.start = new Date().setHours(6, 0, 0);
          let end = new Date();
          end = new Date(end.setHours(5, 59, 59)).setDate(end.getDate() + 1);
          this.date.end = end;
        } else {
          let start = new Date();
          this.date.start = new Date(start.setHours(6, 0, 0)).setDate(start.getDate() - 1);
          let end = new Date();
          end = new Date(end.setHours(5, 59, 59));
          this.date.end = end;
        }
      }

      if (text === "昨天") {
        let start = new Date();
        let end = new Date();

        if (start.getHours() < 6) {
          start.setDate(start.getDate() - 1);
          end.setDate(end.getDate() - 1);
        }

        start.setDate(start.getDate() - 1);
        start.setHours(6, 0, 0);
        this.date.start = start;
        end.setHours(23, 59, 59);
        this.date.end = end.setTime(end.getTime() - 3600 * 1000 * 18);
      }

      if (text === "本周") {
        let start = new Date();
        let end = new Date();

        if (end.getDay() == 1 && end.getHours() < 6) {
          let weekNum = start.getDay();
          weekNum = weekNum == 0 ? 7 : weekNum;
          let fitstDate = new Date(start.getTime() - (weekNum + 6) * 24 * 60 * 60 * 1000);
          start = fitstDate;
        } else {
          const week = start.getDay();
          const minusDay = week != 0 ? week - 1 : 6; //本周 周一

          start = new Date(start.getTime() - minusDay * 1000 * 60 * 60 * 24);
          let weekNum = end.getDay();
          weekNum = weekNum == 0 ? 7 : weekNum;
          let fitstDate = new Date(end.getTime() + (7 - weekNum + 1) * 24 * 60 * 60 * 1000);
          end = fitstDate;
        }

        this.date.start = start.setHours(6, 0, 0);
        this.date.end = end.setHours(5, 59, 59);
      }

      if (text === "上周") {
        let weekday = new Date().getDay() || 7; //上周的开始时间

        this.date.start = this.getNowFormatDate(new Date(new Date().setDate(new Date().getDate() - weekday - 6))).split(" ")[0] + " 00:00:00"; // //上周的结束时间

        this.date.end = this.getNowFormatDate(new Date(new Date().setDate(new Date().getDate() - weekday))).split(" ")[0] + " 23:59:59"; // if (!`${new Date(this.date.start).getFullYear()}${new Date(this.date.start).getMonth()}${new Date(this.date.start).getDate()}` == '2022114') {
        // 	}

        this.date.start = new Date(this.date.start).setHours(6, 0, 0);
        let end = new Date(this.date.end);
        this.date.end = new Date(end.setHours(5, 59, 59)).setDate(end.getDate() + 1);
      }

      if (text === "本月") {
        //开始时间
        let newDate = new Date();

        if (newDate.getDate() == 1 && newDate.getHours() < 6) {
          if (newDate.getMonth() + 1 == 1) {
            this.date.start = new Date(`${newDate.getFullYear() - 1}/12/1 6:00:00`);
            this.date.end = newDate.setHours(5, 59, 59);
          } else {
            this.date.start = new Date(`${newDate.getFullYear()}/${newDate.getMonth()}/1 6:00:00`);
            this.date.end = new Date(newDate.setHours(5, 59, 59));
          }
        } else {
          this.date.start = new Date(`${newDate.getFullYear()}/${newDate.getMonth() + 1}/1 6:00:00`); // this.date.end = new Date(`${newDate.getFullYear()}/${newDate.getMonth() + 1}/1 5:59:59`);

          let y = new Date().getFullYear(); //获取年份

          let m = new Date().getMonth() + 1; //获取月份

          let d = new Date(y, m, 0).getDate(); //获取当月最后一日

          if (m == 12) {
            y = y + 1;
            m = 1;
            d = 1;
          } else {
            m = m < 10 ? "0" + m : m; //月份补 0

            d = d < 10 ? "0" + d : d; //日数补 0
          }

          this.date.end = new Date(`${[y, m, d].join("/")} 5:59:59`);
        }
      }

      if (text === "上月") {
        let nowdays = new Date();
        let year = nowdays.getFullYear();
        let month = nowdays.getMonth();

        if (month == 0) {
          month = 12;
          year = year - 1;
        }

        if (month < 10) {
          month = "0" + month;
        }

        let myDate = new Date(year, month, 0);
        this.date.start = year + "/" + month + "/01 00:00:00"; //上个月第一天

        this.date.end = year + "/" + month + "/" + myDate.getDate() + " 23:59:59"; //上个月最后一天

        this.date.start = new Date(this.date.start).setHours(6, 0, 0);
        let end = new Date(this.date.end);
        this.date.end = new Date(end.setHours(5, 59, 59)).setDate(end.getDate() + 1);
      }

      if (this.delayTime > 0) {
        this.date.start = new Date(this.date.start).getTime() + this.delayTime;
        this.date.end = new Date(this.date.end).getTime() + this.delayTime;

        if (text === "上周") {
          this.date.start = new Date(this.date.start).getTime();
          this.date.end = new Date(this.date.end).getTime();
        }

        if (text === "本周") {
          this.date.start = new Date(this.date.start).getTime() + 24 * 60 * 60 * 1000;
          this.date.end = new Date(this.date.end).getTime();
        }
      }

      this.$emit("getDate", this.dateFormat(this.date, text), "updata");
    },

    //进行初始化
    dateFormat(date, text = "") {
      if (this.from == "报表查询") {
        date.start = `${this.getNowFormatDate(date.start).split(" ")[0]} 6:00:00`;
        date.end = `${this.getNowFormatDate(date.end).split(" ")[0]} 5:59:59`;
      } else {
        date.start = this.getNowFormatDate(date.start);
        date.end = this.getNowFormatDate(date.end);
      } // console.log(date);


      return date;
    },

    //初始化时间格式为年/月/日："2019-09-25"
    getNowFormatDate(d) {
      let date = new Date(d);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hour = date.getHours();
      let min = date.getMinutes();
      let sec = date.getSeconds();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }

      if (day >= 0 && day <= 9) {
        day = "0" + day;
      }

      if (min >= 0 && min <= 9) {
        min = "0" + min;
      }

      if (sec >= 0 && sec <= 9) {
        sec = "0" + sec;
      }

      let currentdate = `${year}/${month}/${day} ${hour}:${min}:${sec}`; // console.log(currentdate)
      // alert(`${year}/${month}/${day} ${hour}:${min}:${sec}`)

      return currentdate;
    }

  }
};