import axios from '../../../axios/axios';
export default {
  data() {
    return {
      set_data: [],
      active_gid: '',
      game_list: [],
      game_group: [],

      /**彩种分组 */
      play_param: "",
      type: "",
      //当前模式，单个/分类
      active_pid: "",
      //当前激活的分类pid
      get_url: "",
      fast: {
        // bet_lock: "",
        straightbet_low: "",
        straightbet_high: "",
        single_high: "",
        // bonus: "",
        relax: ""
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("play/class_game_lock", {
      pid: 1
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          vm.game_list = vm.$store.state.game_list;
          let data = result.data.repsoneContent.list;
          vm.set_data = [];
          vm.type = "list";

          for (let item in data) {
            vm.set_data.push(data[item]);
          }

          let game_group = result.data.repsoneContent.play_arr;

          for (const key in game_group) {
            game_group[key].pid = key;
            vm.game_group.push(game_group[key]);
          }

          vm.active_pid = vm.game_group[0].pid;
        });
      }
    });
  },

  methods: {
    //切换分类
    change_group(pid) {
      this.type = "list";
      this.get_game_set_data(this.type, pid);
    },

    //切换彩种
    change_game(gid) {
      this.type = "one";
      this.get_game_set_data(this.type, gid);
    },

    //提交保存设置
    sub_set() {
      let post_data = "";
      let url = "";

      if (this.type === "one") {
        post_data = {
          gid: this.active_gid,
          play_arr_str: [],
          bet_lock: [],
          straightbet_low: [],
          straightbet_high: [],
          single_high: [],
          bonus: [],
          relax: []
        };
        url = "/play/game_lock_exe";
      } else {
        post_data = {
          pid: this.active_pid,
          play_arr_str: [],
          bet_lock: [],
          straightbet_low: [],
          straightbet_high: [],
          single_high: [],
          bonus: [],
          relax: []
        };
        url = "/play/class_game_lock_exe";
      }

      for (let item of this.set_data) {
        if (Number.isNaN(item.bet_lock * 1) || Number.isNaN(item.straightbet_low * 1) || Number.isNaN(item.straightbet_high * 1) || Number.isNaN(item.single_high * 1) || Number.isNaN(item.bonus * 1) || Number.isNaN(item.relax * 1)) {
          return this.$message.error('请输入正确的数值！');
        } else {
          post_data.play_arr_str.push(item.play_str);
          post_data.bet_lock.push(item.bet_lock);
          post_data.straightbet_low.push(item.straightbet_low);
          post_data.straightbet_high.push(item.straightbet_high);
          post_data.single_high.push(item.single_high);
          post_data.bonus.push(item.bonus);
          post_data.relax.push(item.relax);
        }
      }

      this.axios.post(url, post_data).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.$message.success(result.data.shortMessage);

          if (this.type === "one") {
            this.get_game_set_data("one", 1);
          } else {
            this.get_game_set_data("list", 1);
          }
        }
      });
    },

    //快速设置某一列
    fast_set_col() {
      for (const key in this.fast) {
        if (this.fast[key]) {
          for (let i = 0; i < this.set_data.length; i++) {
            this.set_data[i][key] = this.fast[key];
          }
        }
      }
    },

    //重置
    reset() {
      this.active_gid = 1;
      this.get_game_set_data("one", 1);

      for (const key in this.fast) {
        this.fast[key] = "";
      }
    },

    //获取设置数据
    get_game_set_data(type, id) {
      this.type = type;
      let obj = {};

      if (type === "one") {
        type = "gid";
        this.active_gid = id;
        this.active_pid = '';
        this.get_url = "/play/game_lock";
        obj = {
          gid: id
        };
      } else {
        type = "pid";
        this.active_gid = ""; // console.log(pid);

        this.active_pid = id;
        this.get_url = "/play/class_game_lock";
        obj = {
          pid: id
        };
      }

      this.axios.post(this.get_url, obj).then(result => {
        // console.log(result.data.repsoneContent)
        if (result.data.status == 200) {
          let data = result.data.repsoneContent.list;
          this.set_data = [];

          for (let item in data) {
            this.set_data.push(data[item]);
          }

          let game_group = result.data.repsoneContent.play_arr;

          if (game_group.length != this.game_group.length) {
            this.game_group = [];

            for (const key in game_group) {
              game_group[key].pid = key;
              this.game_group.push(game_group[key]);
            }
          }
        }
      });
    },

    get_game_list() {
      this.axios.post("main/game_list").then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.game_list = result.data.repsoneContent.game_list;
        }
      });
    }

  }
};