import axios from '../../../axios/axios';
export default {
  beforeRouteEnter(to, from, next) {
    axios.post(`user_version2/tuishui?uid=${to.query.uid}`, {
      uid: to.query.uid,
      gids: to.query.gids,
      all_gids: to.query.all_gids,
      play_ids: '',
      times: 1
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.userinfo = {
            uid: to.query.uid,
            level_id: to.query.level_id,
            group_id: to.query.group_id
          };
          vm.all_gids = to.query.all_gids;
          vm.get_data(result.data.repsoneContent);
        });
      }
    });
  },

  data() {
    return {
      userinfo: {
        uid: '',
        level_id: '',
        uidgroup_id: ''
      },
      list: [],
      type_list: [{
        game_type: "号码类（单码、冠亚……",
        single_high: "40000",
        straightbet_high: "20000",
        straightbet_low: "2",
        water_break_A: 0.6,
        water_break_B: 2.8,
        water_break_C: 3.5,
        type_color: '#ff877f'
      }, {
        game_type: "两面类（两面、龙虎……）",
        single_high: "40000",
        straightbet_high: "20000",
        straightbet_low: "2",
        water_break_A: 0.6,
        water_break_B: 2.8,
        water_break_C: 3.5,
        type_color: '#7ae774'
      }, {
        game_type: "连码类（任选二、任选三……）",
        single_high: "40000",
        straightbet_high: "20000",
        straightbet_low: "2",
        water_break_A: 0.6,
        water_break_B: 2.8,
        water_break_C: 3.5,
        type_color: '#7f96fe'
      }, {
        game_type: "杂类（方位、豹子、冠亚和……）",
        single_high: "40000",
        straightbet_high: "20000",
        straightbet_low: "2",
        water_break_A: 0.6,
        water_break_B: 2.8,
        water_break_C: 3.5,
        type_color: '#c67fff'
      }, {
        game_type: "六合彩：特码、正码、正码特",
        single_high: "40000",
        straightbet_high: "20000",
        straightbet_low: "2",
        water_break_A: 0.6,
        water_break_B: 2.8,
        water_break_C: 3.5,
        type_color: 'red'
      }, {
        game_type: "六合彩：连码",
        single_high: "40000",
        straightbet_high: "20000",
        straightbet_low: "2",
        water_break_A: 0.6,
        water_break_B: 2.8,
        water_break_C: 3.5,
        type_color: 'green'
      }, {
        game_type: "六合彩：两面、色波",
        single_high: "40000",
        straightbet_high: "20000",
        straightbet_low: "2",
        water_break_A: 0.6,
        water_break_B: 2.8,
        water_break_C: 3.5,
        type_color: '#e6a23c'
      }, {
        game_type: "六合彩：其他（特肖、生肖尾数、连肖连尾，五不中)",
        single_high: "40000",
        straightbet_high: "20000",
        straightbet_low: "2",
        water_break_A: 0.6,
        water_break_B: 2.8,
        water_break_C: 3.5,
        type_color: 'blue'
      }],
      all_gids: []
    };
  },

  created() {
    let gids = [];
    this.all_gids = [];
    this.$store.state.game_list.forEach(item => {
      gids.push(item.id);
      this.all_gids.push(item.id);
    });
    this.axios.post(`user_version2/tuishui?uid=${this.$route.query.uid}`, {
      uid: this.$route.query.uid,
      gids,
      all_gids: this.all_gids,
      play_ids: '',
      times: 2
    }).then(result => {
      if (result.data.status == 200) {
        this.get_data(result.data.repsoneContent);
      }
    });
  },

  methods: {
    ref_data() {
      let obj = {
        uid: this.userinfo.uid,
        group_id: this.userinfo.group_id,
        gids: this.all_gids,
        all_gids: this.all_gids,
        times: 2
      };
      this.axios.post("user_version2/tuishui", obj).then(result => {
        if (result.data.status == 200) {
          // this.$message.success(result.data.shortMessage);
          this.get_data(result.data.repsoneContent);
        }
      });
    },

    reset_ts() {
      this.$confirm('是否确认重置?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let obj = {
          uid: this.userinfo.uid,
          group_id: this.userinfo.group_id,
          is_reset: 1,
          gids: this.all_gids,
          all_gids: this.all_gids,
          play_ids: []
        };
        this.axios.post("user_version2/reset_tuishui", obj).then(result => {
          if (result.data.status == 200) {
            this.$message.success(result.data.shortMessage);
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消重置'
        });
      });
    },

    sub() {
      let list = [];

      for (const game of this.list) {
        for (const old of game.old_list) {
          for (const list_item of game.list) {
            for (const item of list_item) {
              if (item.play_id == old.play_id && item.name == old.name) {
                if (item.water_break_A * 1 != old.water_break_A * 1 || item.water_break_B * 1 != old.water_break_B * 1 || item.water_break_C * 1 != old.water_break_C * 1 || item.straightbet_low * 1 != old.straightbet_low * 1 || item.straightbet_high * 1 != old.straightbet_high * 1 || item.single_high * 1 != old.single_high * 1) {
                  item.old = old;
                  list.push(item);
                }
              }
            }
          }
        }
      }

      if (list.length > 0) {
        for (let index = 0; index < list.length; index++) {
          if (list[index].single_high_top && list[index].single_high * 1 > list[index].single_high_top * 1) {
            return this.$message.error(`${list[index].name} 的 单期限额 不能高于上限 ${list[index].single_high_top * 1}`);
          }

          if (list[index].straightbet_high_top && list[index].straightbet_high * 1 > list[index].straightbet_high_top * 1) {
            return this.$message.error(`${list[index].name} 的 单注限额 不能高于上限 ${list[index].straightbet_high_top * 1}`);
          }

          if (list[index].straightbet_low_top && list[index].straightbet_low * 1 < list[index].straightbet_low_top * 1) {
            return this.$message.error(`${list[index].name} 的 单注最低 不能低于下限 ${list[index].straightbet_low_top * 1}`);
          }

          if (list[index].water_break_A_top && list[index].water_break_A * 1 > list[index].water_break_A_top * 1) {
            return this.$message.error(`${list[index].name} 的 A盘退水 不能高于上限 ${list[index].water_break_A_top * 1}`);
          }

          if (list[index].water_break_B_top && list[index].water_break_B * 1 > list[index].water_break_B_top * 1) {
            return this.$message.error(`${list[index].name} 的 B盘退水 不能高于上限 ${list[index].water_break_B_top * 1}`);
          }

          if (list[index].water_break_C_top && list[index].water_break_C * 1 > list[index].water_break_C_top * 1) {
            return this.$message.error(`${list[index].name} 的 C盘退水 不能高于上限 ${list[index].water_break_C_top * 1}`);
          }
        }

        this.axios.post("user_version2/update_tuishui", {
          user_id: this.userinfo.uid,
          group_id: this.userinfo.group_id,
          update_tuishui: list
        }).then(result => {
          if (result.data.status == 200) {
            this.$message.success(result.data.shortMessage);
            this.ref_data();
          }
        });
      } else {
        this.$message('数据未改动，已保持以往设置');
      }
    },

    fast_set(row) {
      if (Number.isNaN(row.water_break_A * 1) || Number.isNaN(row.water_break_B * 1) || Number.isNaN(row.water_break_C * 1) || Number.isNaN(row.straightbet_high * 1) || Number.isNaN(row.straightbet_low * 1) || Number.isNaN(row.single_high * 1)) {
        this.$message.error('请填写正确的数值');
      }

      if (row.water_break_A * 1 < 0 || row.water_break_B * 1 < 0 || row.water_break_C * 1 < 0 || row.straightbet_high * 1 < 0 || row.straightbet_low * 1 < 0 || row.single_high * 1 < 0) {
        this.$message.error('数值不能小于0');
      }

      for (const game of this.list) {
        for (const list_item of game.list) {
          for (const item of list_item) {
            if (item.type_color == row.type_color) {
              item.water_break_A = row.water_break_A;
              item.water_break_B = row.water_break_B;
              item.water_break_C = row.water_break_C;
              item.straightbet_high = row.straightbet_high;
              item.straightbet_low = row.straightbet_low;
              item.single_high = row.single_high;
            }
          }
        }
      }
    },

    get_data(data) {
      var _data$data_list;

      // console.log(data);
      let list = data.list;

      if (((_data$data_list = data.data_list) === null || _data$data_list === void 0 ? void 0 : _data$data_list.length) > 0) {
        data.data_list.forEach(item => {
          this.type_list.forEach(e => {
            if (e.game_type == item.game_type) {
              item.type_color = e.type_color;
            }
          });
        });
        this.type_list = data.data_list;
      }

      list.forEach(item => {
        item.old_list = JSON.parse(JSON.stringify(item.list));
        item.list.forEach(g => {
          if (g.game_type == '定位胆' || g.game_type.includes('红黑')) {
            g.type_color = '#ff877f'; //橙色
          } else if (g.game_type == '整合' || g.game_type == '和值' || g.game_type == '万千(1vs2)' || g.game_type == '第一球' || g.game_type == '总和') {
            g.type_color = '#7ae774'; //绿色
          } else if (g.game_type.includes('和值') || g.game_type.includes('前三') || g.game_type == '不定位') {
            g.type_color = '#c67fff'; //紫色
          } else if (g.game_type.includes('同号') || g.game_type.includes('连号')) {
            g.type_color = '#7f96fe'; //蓝色
          } // 六合彩
          else if (g.game_type == '特码' || g.game_type == '特码B' || g.game_type == '正特') {
            g.type_color = 'red';
          } else if (g.game_type == '特码B') {
            g.type_color = '#fff';
          } else if (g.game_type == '两面' || g.game_type == '色波' || g.game_type.includes('正码')) {
            g.type_color = '#e6a23c';
          } else if (g.game_type == '特串' || g.game_type == '三全中' || g.game_type == '三中二' || g.game_type == '二全中' || g.game_type == '二中特') {
            g.type_color = 'green';
          } else {
            g.type_color = 'blue';
          }
        });
        item.list = this.reSetData(item.list, Math.round(item.list.length / 2));
      });
      this.list = list;
    },

    reSetData(data, num) {
      //把一个数组分割成想要的数组长度
      let index = 0;
      let newArray = [];

      while (index < data.length) {
        newArray.push(data.slice(index, index += num));
      }

      return newArray;
    }

  }
};