<template>
    <div class="top-up">
        <div class="myform">
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">上级账号</el-col>
                <el-col class="myform-item-value" :span="18">{{ userinfo.parent_name }}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">上级级别</el-col>
                <el-col class="myform-item-value" :span="18">{{ addinfo.sup_group_name }}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">上级名称</el-col>
                <el-col class="myform-item-value" :span="18">{{ userinfo.parent_nickname }}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">上级彩票余额</el-col>
                <el-col class="myform-item-value" :span="18">
                    <span v-if="userinfo.kusaicai_liuhe_status.is_kuaicai == 1">快彩：{{ new_user_info.kuaicai_pre_balance }}</span>
                    <span v-if="userinfo.kusaicai_liuhe_status.is_kuaicai == 1 && userinfo.kusaicai_liuhe_status.is_liuhe == 1">&nbsp;&nbsp;&nbsp;</span>
                    <span v-if="userinfo.kusaicai_liuhe_status.is_liuhe == 1">六合：{{ new_user_info.pre_balance }}</span>
                </el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">用户级别</el-col>
                <el-col class="myform-item-value" :span="18">{{ addinfo.sub_group_name }}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">用户模式</el-col>
                <el-col class="myform-item-value" :span="18">{{ userinfo.bill_reset == 0 ? "现金" : userinfo.bill_reset == 1 ? "混合" : "信用" }}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">用户账号</el-col>
                <el-col class="myform-item-value" :span="18">{{ userinfo.username }}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">用户名称</el-col>
                <el-col class="myform-item-value" :span="18">{{ userinfo.nickname }}</el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">用户彩票余额</el-col>
                <el-col class="myform-item-value" :span="18">
                    <span v-if="userinfo.kusaicai_liuhe_status.is_kuaicai == 1">快彩：{{ new_user_info.kuaicai_balance }}</span>
                    <span v-if="userinfo.kusaicai_liuhe_status.is_kuaicai == 1 && userinfo.kusaicai_liuhe_status.is_liuhe == 1">&nbsp;&nbsp;&nbsp;</span>
                    <span v-if="userinfo.kusaicai_liuhe_status.is_liuhe == 1">六合：{{ new_user_info.balance }}</span>
                </el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">金额类型</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-radio-group v-model="jine_type">
                        <!-- <el-radio label="1">彩票</el-radio> -->
                        <el-radio label="0">人民币</el-radio>
                        <!-- <el-radio label="52">USDT（{{ new_user_info.usdt }}）</el-radio> -->
                    </el-radio-group>
                </el-col>
            </el-row>
            <el-row class="myform-item" v-if="userinfo.kusaicai_liuhe_status.is_kuaicai == 1">
                <el-col class="myform-item-lable" :span="6">充值金额(快彩)</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-input ref="top_up_value" v-model="kuaicai_top_up.value" size="small" style="width: 100px" :min="0" autofocus></el-input>
                    <span class="redcolor">
                        <span v-if="jine_type == '52'">&nbsp;(usdt:{{ (kuaicai_top_up.value * new_user_info.usdt).toFixed(0) }})</span>
                        <span>&nbsp;请谨慎确认金额</span>
                    </span>
                    <div>{{ jine_type == 0 ? $filters.capAmountToString(kuaicai_top_up.value) : jine_type == 52 ? $filters.capAmountToString((kuaicai_top_up.value * new_user_info.usdt).toFixed(0)) : "" }}</div>
                    <div style="text-align: center; color: red" v-show="kuaicai_top_up.show">{{ kuaicai_top_up.text }}</div>
                </el-col>
            </el-row>
            <el-row class="myform-item" v-if="userinfo.kusaicai_liuhe_status.is_liuhe == 1">
                <el-col class="myform-item-lable" :span="6">充值金额(六合)</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-input ref="top_up_value" v-model="top_up.value" size="small" style="width: 100px" :min="0" autofocus></el-input>
                    <span class="redcolor">
                        <span v-if="jine_type == '52'">&nbsp;(usdt:{{ (top_up.value * new_user_info.usdt).toFixed(0) }})</span>
                        <span>&nbsp;请谨慎确认金额</span>
                    </span>
                    <div>{{ jine_type == 0 ? $filters.capAmountToString(top_up.value) : jine_type == 52 ? $filters.capAmountToString((top_up.value * new_user_info.usdt).toFixed(0)) : "" }}</div>
                    <div style="text-align: center; color: red" v-show="top_up.show">{{ top_up.text }}</div>
                </el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">备注</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-input ref="top_up_remark" v-model="remark" size="small"></el-input>
                </el-col>
            </el-row>
        </div>
        <el-button style="margin-top: 10px" type="primary" size="small" @click="sub_top_up">确认充值</el-button>
    </div>
</template>

<script>
import { h } from "vue";
export default {
    props: ["userinfo", "addinfo"],
    data() {
        return {
            usertype: "",
            new_user_info: "",
            top_up: {
                value: "",
                show: false,
                text: "",
            },
            kuaicai_top_up: {
                value: "",
                show: false,
                text: "",
            },
            is_liuhe: 0,
            is_kuaicai: 0,
            remark: "",
            jine_type: "0",
        };
    },
    created() {
        this.usertype = this.userinfo.group_id == 0 ? "会员" : "代理";
        this.get_user_info(this.userinfo.kusaicai_liuhe_status.is_liuhe, this.userinfo.kusaicai_liuhe_status.is_kuaicai);
    },
    mounted() {
        this.$refs["top_up_value"].focus();
        // console.log(this.$refs);
    },
    methods: {
        reset() {
            this.top_up.value = "";
            this.kuaicai_top_up.value = "";
        },
        sub_top_up() {
            if (this.top_up.value == "" && this.kuaicai_top_up.value == "") {
                return;
            }
            if (Number.isNaN(this.kuaicai_top_up.value * 1) || this.kuaicai_top_up.value * 1 < 0) {
                this.kuaicai_top_up.show = true;
                this.kuaicai_top_up.text = "请输入正确的充值金额(快彩)";
                return;
            }
            if (Number.isNaN(this.kuaicai_top_up.value * 1) || this.kuaicai_top_up.value * 1 < 0) {
                this.kuaicai_top_up.show = true;
                this.kuaicai_top_up.text = "请输入正确的充值金额(六合)";
                return;
            }
            if (this.top_up.show || this.kuaicai_top_up.show) {
                return;
            }
            let obj = {
                uid: this.userinfo.id,
                agent_id: this.userinfo.agent_id,
                type: 1,
                pre_username: this.userinfo.parent_name,
                username: this.userinfo.username,
                group_id: this.userinfo.group_id,
                is_liuhe: this.is_liuhe,
                is_kuaicai: this.is_kuaicai,
                remark: this.remark,
                item_id: this.jine_type,
                usdt: this.top_up.value,
            };
            if (this.is_liuhe == 1) {
                obj.txt_recharge = this.jine_type == 0 ? this.top_up.value : this.jine_type == 52 ? (this.top_up.value * this.new_user_info.usdt).toFixed(0) : "";
            }
            if (this.is_kuaicai == 1) {
                obj.kuaicai_txt_recharge = this.jine_type == 0 ? this.kuaicai_top_up.value : this.jine_type == 52 ? (this.kuaicai_top_up.value * this.new_user_info.usdt).toFixed(0) : "";
            }
            this.$confirm(
                h("div", null, [obj.kuaicai_txt_recharge * 1 > 0 ? h("p", null, `充值快彩：${obj.kuaicai_txt_recharge}元`) : "", obj.txt_recharge * 1 > 0 ? h("p", null, `充值六合：${obj.txt_recharge}元`) : ""]),
                `确定给：${this.userinfo.username} 充值吗？`,
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    customClass: "myMessageBox",
                    customStyle: {
                        backgroundColor: this.$store.state.themeColorLight,
                        border: ` 2px solid`,
                        borderColor: ` ${this.$store.state.themeColor}`,
                        width: "200px",
                    },
                }
            )
                .then(() => {
                    this.axios.post("account/credit_recharge_kc", obj).then((result) => {
                        if (result.data.status == 200) {
                            this.$message.success(result.data.shortMessage);
                            this.get_user_info();
                            this.$emit("other_view_back");
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                });
        },
        get_user_info(is_liuhe = 0, is_kuaicai = 0) {
            this.is_liuhe = is_liuhe;
            this.is_kuaicai = is_kuaicai;
            this.axios
                .post("account/credit_recharge_kc", {
                    uid: this.userinfo.id,
                    agent_id: this.userinfo.agent_id,
                    type: "",
                    group_id: this.userinfo.group_id,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.new_user_info = result.data.repsoneContent;
                    }
                });
        },
    },
    watch: {
        "top_up.value": function top_up(newVal, oldVal) {
            if (this.jine_type == 0) {
                if (newVal > this.new_user_info.pre_balance * 1) {
                    this.top_up.show = true;
                    this.top_up.text = "不能超过最大金额：" + this.new_user_info.pre_balance;
                } else {
                    this.top_up.show = false;
                }
            } else if (this.jine_type == 52) {
                if (newVal * this.new_user_info.usdt > this.new_user_info.pre_balance * 1) {
                    this.top_up.show = true;
                    this.top_up.text = "不能超过最大金额：" + this.new_user_info.pre_balance;
                } else {
                    this.top_up.show = false;
                }
            }
        },
        "kuaicai_top_up.value": function kuaicai_top_up(newVal, oldVal) {
            if (this.jine_type == 0) {
                if (newVal > this.new_user_info.kuaicai_pre_balance * 1) {
                    this.kuaicai_top_up.show = true;
                    this.kuaicai_top_up.text = "不能超过最大金额：" + this.new_user_info.kuaicai_pre_balance;
                } else {
                    this.kuaicai_top_up.show = false;
                }
            } else if (this.jine_type == 52) {
                if (newVal * this.new_user_info.usdt > this.new_user_info.kuaicai_pre_balance * 1) {
                    this.kuaicai_top_up.show = true;
                    this.kuaicai_top_up.text = "不能超过最大金额：" + this.new_user_info.kuaicai_pre_balance;
                } else {
                    this.kuaicai_top_up.show = false;
                }
            }
        },
    },
};
</script>
