export default {
  props: ['trader_diff'],

  data() {
    return {
      gid: '',
      game_list: [],
      loss_data: [],
      uid: "",
      username: "",

      /**彩种分组 */
      play_arr: [],
      fast: {
        inputnum: 0
      },
      type: "",
      //设置类型
      gids: '',
      pid: ''
    };
  },

  created() {// console.log(this.trader_diff);
  },

  methods: {
    fastset(val) {
      this.loss_data.forEach(item => {
        item.inputnum = val;
      });
    },

    sub_set() {
      let post_data = "";

      if (this.type === "one") {
        post_data = {
          gid: this.gid,
          uid: this.uid,
          play_arr_odds: [],
          play_rate: [],
          old_play_rate: []
        };
      } else {
        post_data = {
          gids: this.gids,
          pid: this.pid,
          uid: this.uid,
          play_arr_odds: [],
          play_rate: [],
          old_play_rate: []
        };
      }

      for (let item of this.loss_data) {
        item.play_rate = (item.high_rate - item.inputnum).toFixed(4);

        if (item.play_rate * 1 > item.high_rate * 1 || item.play_rate * 1 < item.low_rate * 1) {
          // console.log(item);
          return this.$message({
            message: item.name + ' 赔率设定不能超过上限或者低于下限',
            type: 'error'
          });
          ;
        }

        if (item.old_play_rate != item.play_rate) {
          post_data.play_arr_odds.push(item.play_str);
          post_data.play_rate.push(item.play_rate);
          post_data.old_play_rate.push(item.old_play_rate);
        }
      }

      if (post_data.play_rate.length) {
        this.axios.post("play/member_agent_set_exe", post_data).then(result => {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            this.$message({
              message: result.data.shortMessage,
              type: 'success'
            });
            this.get_loss_data(this.gid, this.pid);
          }
        });
      } else {
        this.$message.warning('您未作出任何修改，数据已保持');
      }
    },

    fast_set_col() {
      for (const key in this.fast) {
        if (this.fast[key]) {
          for (let i = 0; i < this.loss_data.length; i++) {
            this.loss_data[i][key] = this.fast[key];
          }
        }
      }
    },

    get_data(data, uid) {
      this.game_list = this.$store.state.game_list;
      this.gid = this.game_list[0].id;
      this.uid = uid;
      let play_arr = data.play_arr;

      if (this.play_arr.length < 1) {
        for (const key in play_arr) {
          play_arr[key].pid = key;
          this.play_arr.push(play_arr[key]);
        }
      }

      if (data.list.find(item => item.name == '特码B') != undefined) {
        let item = data.list.splice(data.list.findIndex(item => item.name == '特码B'), 1)[0];
        data.list.splice(1, 0, item);
      }

      this.loss_data = [...data.list];

      for (let i = 0; i < this.loss_data.length; i++) {
        this.loss_data[i].old_play_rate = this.loss_data[i].play_rate;
        this.loss_data[i].inputnum = (this.loss_data[i].high_rate - this.loss_data[i].play_rate).toFixed(4) * 1; // this.loss_data[i].inputnum_min = this.trader_diff.diff[0] * 1;

        this.loss_data[i].inputnum_min = 0;
        this.loss_data[i].inputnum_max = this.trader_diff.diff[1];
      }

      if (this.gid != '') {
        this.type = "one";
      }

      if (this.pid != '') {
        this.type = "group";
        pid == 18 ? this.gids = 'ssc' : "";
        pid == 19 ? this.gids = 'pk10' : "";
        pid == 31 ? this.gids = 'k3' : "";
        pid == 20 ? this.gids = 'lhc' : "";
        pid == 22 ? this.gids = 'pcdd' : "";
      }
    },

    get_loss_data(gid = '', pid = '') {
      // console.log(this.trader_diff);
      this.gid = gid;
      this.pid = pid; // console.log(`gid: ${this.gid}--pid:${this.pid}`);

      this.axios.post("play/member_agent_set", {
        gid,
        pid,
        gids: this.gids,
        user_id: this.uid
      }).then(result => {
        if (result.data.status == 200) {
          let play_arr = result.data.repsoneContent.play_arr;
          let list = result.data.repsoneContent.list;

          if (this.play_arr.length < 1) {
            for (const key in play_arr) {
              play_arr[key].pid = key;
              this.play_arr.push(play_arr[key]);
            }
          }

          if (list.find(item => item.name == '特码B') != undefined) {
            let item = list.splice(list.findIndex(item => item.name == '特码B'), 1)[0];
            list.splice(1, 0, item);
          }

          this.loss_data = [...list];

          for (let i = 0; i < this.loss_data.length; i++) {
            this.loss_data[i].old_play_rate = this.loss_data[i].play_rate;
            this.loss_data[i].inputnum = (this.loss_data[i].high_rate - this.loss_data[i].play_rate).toFixed(4) * 1; // this.loss_data[i].inputnum_min = this.trader_diff.diff[0] * 1;

            this.loss_data[i].inputnum_min = 0;
            this.loss_data[i].inputnum_max = this.trader_diff.diff[1] * 1; // this.$nextTick(() => {
            // })
          }

          if (gid != '') {
            this.type = "one";
          }

          if (pid != '') {
            this.type = "group";
            pid == 18 ? this.gids = 'ssc' : "";
            pid == 19 ? this.gids = 'pk10' : "";
            pid == 31 ? this.gids = 'k3' : "";
            pid == 20 ? this.gids = 'lhc' : "";
            pid == 22 ? this.gids = 'pcdd' : "";
          }
        }
      });
    }

  }
};