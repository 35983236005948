import axios from '../../../axios/axios';
export default {
  name: 'dailiipshezhi',
  components: {},

  data() {
    return {
      list: [],
      title: '代理ip设置',
      dialog: {
        username: '',
        dip: '',
        qip: '',
        sip: '',
        ips: [],
        remark: '',
        id: ''
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("user_version2/user_ips", {}).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          vm.list = result.data.repsoneContent.list;
          vm.list.forEach(item => {
            if (item.ips.includes(',')) {
              item.ips = item.ips.split(',');
            } else {
              item.ips = [item.ips];
            }
          });
        });
      }
    });
  },

  created() {},

  methods: {
    //随机排列
    shuffle() {
      let arr = JSON.parse(JSON.stringify(this.dialog.ips));
      let temp = [];

      for (let i = arr.length; i > 0; i--) {
        let temRandom = Math.floor(Math.random() * i);
        temp.push(arr[temRandom]);
        arr.splice(temRandom, 1); //抽取一张后，要除去这张牌，然后在剩下的牌中继续抽
      }

      this.dialog.ips = temp;
    },

    //新增
    add() {
      this.dialog.username = '';
      this.dialog.ip = '';
      this.dialog.remark = '';
      this.title = '新增代理ip';
    },

    //加单个
    add_ip() {
      if (this.dialog.dip == '') {
        return this.$message.error('请输入代理ip');
      } else {
        this.dialog.ips.push({
          text: this.dialog.dip
        });
        this.dialog.dip = '';
      }
    },

    //加起始段
    add_ip_qs() {
      if (this.dialog.qip == '') {
        return this.$message.error('请输入开始ip');
      }

      if (this.dialog.sip == '') {
        return this.$message.error('请输入结束ip');
      }

      let qip = this.dialog.qip;
      let sip = this.dialog.sip;

      if (!this.isValidIP(qip)) {
        return this.$message.error('开始ip不正确');
      }

      if (!this.isValidIP(sip)) {
        return this.$message.error('结束ip不正确');
      }

      let q_list = qip.split('.');
      let s_list = sip.split('.');
      q_list.forEach(item => {
        if (item > 255) {
          return this.$message.error('每段数值最大为255');
        } else {
          item = item * 1;
        }
      });
      s_list.forEach(item => {
        if (item > 255) {
          return this.$message.error('每段数值最大为255');
        } else {
          item = item * 1;
        }
      }); // console.log(q_list, s_list);

      if (q_list[0] == s_list[0] && q_list[1] == s_list[1]) {
        let arr = [];
        let one = q_list[0];
        let two = q_list[1];

        if (q_list[2] == s_list[2]) {
          let three = q_list[2];

          if (q_list[3] == s_list[3]) {
            let four = q_list[3];
            this.dialog.ips.push({
              text: `${one}.${two}.${three}.${four}`
            });
          }

          if (q_list[3] < s_list[3]) {
            let num = s_list[3] * 1 + 1;
            let list = [];

            for (let index = q_list[3]; index < num; index++) {
              list.push(index);
            }

            list.forEach(item => {
              this.dialog.ips.push({
                text: `${one}.${two}.${three}.${item}`
              });
            });
          }
        }

        if (q_list[2] < s_list[2]) {
          let three = q_list[2];
          let three1 = s_list[2];
          let tou_list = [];
          let wei_list = [];
          let list1 = [];
          let list2 = [];

          for (let index = q_list[3]; index < 256; index++) {
            tou_list.push(index);
          }

          tou_list.forEach(item => {
            list1.push({
              text: `${one}.${two}.${three}.${item}`
            });
          });

          for (let index = 0; index <= s_list[3]; index++) {
            wei_list.push(index);
          }

          wei_list.forEach(item => {
            list2.push({
              text: `${one}.${two}.${three1}.${item}`
            });
          });
          let num = s_list[2] - q_list[2] - 1;
          let list3 = [];
          let three2 = q_list[2] * 1 + 1;

          if (num > 0) {
            for (let index = 0; index < num; index++) {
              for (let index1 = 0; index1 < 256; index1++) {
                list3.push({
                  text: `${one}.${two}.${three2}.${index1}`
                });
                ;
              }

              three2 += 1;

              if (three2 >= s_list[2]) {
                break;
              }
            }
          }

          this.dialog.ips = [...list1, ...list2, ...list3];
        } // console.log(this.dialog.ips);

      } else {
        return this.$message.error('请确认起止ip段的一二节数字一致');
      }
    },

    //ip验证
    isValidIP(ip) {
      let reg = /^((?:(?:25[0-5]|2[0-4]\d|[01]?\d?\d)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d?\d))$/;
      return reg.test(ip);
    },

    //删除单个已经ip标签
    handleClose(index) {
      this.dialog.ips.splice(index, 1);
    },

    //取消添加
    cancel_add() {
      this.get_list();
      this.title = '代理ip设置';
      this.dialog.username = '';
      this.dialog.dip = '';
      this.dialog.qip = '';
      this.dialog.sip = '';
      this.dialog.remark = '';
      this.dialog.ips = [];
    },

    //提交添加
    sub_add() {
      let ips = [];
      this.dialog.ips.forEach(item => {
        if (item.text != '') {
          ips.push(item.text);
        }
      });

      if (ips.length == 0) {
        return this.$message.error('请输入代理ip');
      }

      this.axios.post("user_version2/user_ips_add", {
        username: this.dialog.username,
        ips: ips.join(','),
        remark: this.dialog.remark,
        act: 'add'
      }).then(result => {
        if (result.data.status == 200 || result.data.status) {
          this.$message.success(result.data.repsoneContent || '添加成功！');
          this.get_list();
          this.title = '代理ip设置';
        }
      });
    },

    //修改
    change_ip(row) {
      this.dialog.username = row.username;
      this.dialog.remark = row.remark;
      this.dialog.id = row.id;
      let ips = [];
      row.ips.forEach(item => {
        ips.push({
          text: item
        });
      });
      this.dialog.ips = ips;
      this.title = `修改代理ip`;
    },

    sub_change() {
      let ips = [];
      this.dialog.ips.forEach(item => {
        if (item.text != '') {
          ips.push(item.text);
        }
      });

      if (ips.length == 0) {
        return this.$message.error('请输入代理ip');
      }

      this.axios.post("user_version2/user_ips_edit", {
        username: this.dialog.username,
        ips: ips.join(','),
        remark: this.dialog.remark,
        id: this.dialog.id,
        act: 'edit'
      }).then(result => {
        if (result.data.status == 200 || result.data.status) {
          this.$message.success(result.data.repsoneContent || '修改成功！');
          this.get_list();
          this.title = '代理ip设置';
        }
      });
    },

    //删除
    del(id) {
      this.axios.post("user_version2/user_ips_edit", {
        id,
        act: 'del'
      }).then(result => {
        if (result.data.status == 200 || result.data.status) {
          this.$message.success(result.data.shortMessage || '删除成功！');
          this.get_list();
        }
      });
    },

    get_list() {
      this.axios.post("user_version2/user_ips", {}).then(result => {
        if (result.data.status == 200) {
          this.list = result.data.repsoneContent.list;
          this.list.forEach(item => {
            if (item.ips.includes(',')) {
              item.ips = item.ips.split(',');
            } else {
              item.ips = [item.ips];
            }
          });
        }
      });
    }

  }
};