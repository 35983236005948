export default {
  components: {},

  data() {
    return {};
  },

  created() {},

  methods: {}
};