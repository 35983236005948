export default {
  props: ["userinfo"],

  data() {
    return {
      game_id: '',
      game_list: [],
      buhuo_data: [],

      /**自动补货变更记录 */
      dialog: {
        visible: false,
        list: [],
        pagination: {
          page_size: 0,
          total_pages: 0,
          total_records: 0
        }
      },
      post_auto: {
        type: "1",
        //个人管理：0,查看自己补货，用户管理：1查看下级补货
        uid: "",
        lid: "",
        page: 1,
        limit: 10
      }
    };
  },

  methods: {
    change_page(page) {
      this.post_auto.page = page;
      this.get_buhuo_log();
    },

    /**查看自动补货记录 */
    get_buhuo_log() {
      this.post_auto.uid = this.userinfo.id;
      this.post_auto.lid = this.game_id;
      this.axios.post("user/viewAutoSaleLog", this.post_auto).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.dialog.list = result.data.repsoneContent.list;
          this.dialog.pagination = result.data.repsoneContent.pagination;
          this.dialog.visible = true;
        }
      });
    },

    game_change(gid) {
      this.axios.post("user/viewAutoLet", {
        uid: this.userinfo.id,
        gid
      }).then(result => {
        if (result.data.status == 200) {
          this.buhuo_data = result.data.repsoneContent;
        }
      });
    },

    /**获取彩种补货 */
    get_buhuo_data(data = '') {
      this.game_list = this.$store.state.game_list;
      this.game_id = this.game_list[0].id;
      this.buhuo_data = data;
    },

    /**获取彩种列表 */
    get_game_list(user_id = this.userinfo.id) {
      this.axios.post("main/game_user_list", {
        user_id: user_id
      }).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.game_list = result.data.repsoneContent.game_list;

          if (this.game_list.length > 0) {
            this.game_id = this.game_list[0].id;
            this.get_buhuo_data();
          }
        }
      });
    }

  }
};