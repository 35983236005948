// import axios from "../../axios/axios";
import gameinfo from "./gameinfo";
import fc3d from "./games/fc3d";
import k3 from "./games/k3";
import lhc from "./games/lhc";
import pcdd from "./games/pcdd";
import pk10 from "./games/pk10";
import pl35 from "./games/pl35";
import ssc from "./games/ssc";
import { get_lhc_sx } from "../../plugin/sx";
import { lhcNum } from "../../plugin/lhcNum";
import { pcddNum } from "../../plugin/pcddNum";
import { el } from "element-plus/lib/locale";
export default {
  name: "instant",
  components: {
    gameinfo,
    fc3d,
    k3,
    lhc,
    pcdd,
    pk10,
    pl35,
    ssc
  },

  data() {
    return {
      params: {
        link: ""
      },
      comp: "",
      game_info_data: {
        title: ""
      },
      pk: "0",
      szsz: "1",
      chang_long: [],
      sum: {
        all_balance: "0",
        own_balance: "0",
        sums: []
      },
      loss_data: [],
      sub_link: "",
      is_manlet: "-1",
      //补货状态
      zupai: {
        //排行/组排/快速补货
        list: [],
        page: 1,
        pagination: {
          total_pages: 0,
          total_records: 0,
          page_size: 0
        },
        type: "",
        show_ksbh: false,
        ksbh_all_input: ""
      },
      s_type: "1",
      balls: "",
      pl_value: 0.005,
      //每次赔率加减值
      can_change_pl: true,
      //注单明细
      zdmx: {
        visible: false,
        page: 1,
        pagination: {
          page_size: "",
          total_pages: "",
          total_records: ""
        },
        row: "",
        data_list: []
      },
      source: null,
      //存放取消的请求方法
      tm_zupai: [],
      bhmx: {
        visible: false,
        list: [],
        sum: {
          num: 0,
          control_let: 0
        },
        page: 1,
        pagination: {
          page_size: "",
          total_pages: "",
          total_records: ""
        },
        ball_mx: {
          visible: false,
          list: []
        }
      }
    };
  },

  watch: {
    "game_info_data.title": function (val, oldVal) {
      if (val != oldVal) {
        this.$nextTick(() => {
          this.$refs[this.comp].pl_popver.show = false;
          this.$refs[this.comp].bh.show = false;
        });
        this.change_background();
      }
    },
    "$route.query": function (toParams, previousParams) {
      // 对路由变化做出响应...
      this.sub_link = ""; // console.log(toParams, previousParams);

      if (toParams.link != undefined && toParams.link.split("=")[0] == "trader/index?ball" && previousParams.link != undefined && previousParams.link.split("=")[0] == "trader/index?ball") {
        this.params = toParams;
        this.game_info_data.title = toParams.title;
        this.comp = toParams.comp;
        let balls = toParams.link.split("=")[1];
        this.$nextTick(() => {
          this.$refs[this.comp].is_int_submenu = true;

          if ((toParams.gid == 20 || toParams.gid == 34 || toParams.gid == 48 || toParams.gid == 49) && this.$store.state.instant_cacheData[balls] != null) {
            this.$refs["lhc"].proc(this.$store.state.instant_cacheData[balls]);
          }
        });

        if (toParams.link.split("=")[0] == "trader/index?ball" && previousParams.link.split("=")[0] == "trader/index?ball") {
          this.change_submenu(toParams);
        } else {
          this.$refs["gameinfo"].clear_all_timer();
        }

        this.get_game_info(this.pk, this.sub_uid, "noloss");
        this.$refs["gameinfo"].clear_all_timer();
      } else {
        this.params = this.$store.state.checked_menu;
        this.comp = this.params.comp;
      }
    }
  },

  created() {
    // console.log(this.$store.state);
    this.params = this.$store.state.checked_menu;
    this.comp = this.params.comp;
    this.game_info_data = this.$store.state.instant_gameinfo;
    this.change_background();
    this.game_info_data.title = this.params.title; // console.log(this.comp, this.game_info_data);

    let strs = "";

    if (typeof this.game_info_data.upopennumber == "string" && this.game_info_data.upopennumber != "") {
      strs = this.game_info_data.upopennumber.split(",");
    } else {
      strs = this.game_info_data.upopennumber;
    }

    let arr = [];
    let arr1 = [];
    /**判断解析六合彩球号，颜色，生肖 */

    if (this.game_info_data.gid == 20 || this.game_info_data.gid == 34 || this.game_info_data.gid == 48 || this.game_info_data.gid == 49) {
      this.$nextTick(() => {
        this.$refs["lhc"].int_submenu(); //读取六合彩缓存数据

        this.$refs["lhc"].proc(this.$store.state.instant_cacheData["tm"]);
      });

      for (let i = 0; i < strs.length; i++) {
        arr.push(lhcNum(strs[i], this.game_info_data.upopenstarttime.split(" ")[0]));
      } // console.log(strs);


      if (this.game_info_data.upopennumber != "") {
        arr = get_lhc_sx("开奖", arr);
      }

      if (this.game_info_data.upopennumber_cs) {
        for (let i = 0; i < this.game_info_data.upopennumber_cs.length; i++) {
          arr1.push(lhcNum(this.game_info_data.upopennumber_cs[i], this.game_info_data.upopenstarttime.split(" ")[0]));
        }

        arr1 = get_lhc_sx("开奖", arr1);
      }
    } //pc蛋蛋，加拿大28，幸运28
    else if (this.game_info_data.gid == "22" || this.game_info_data.gid == "23") {
      for (let i = 0; i < strs.length; i++) {
        arr.push(pcddNum(strs[i]));
      }
    } else {
      /**其他彩种球号，cqssc,pk10 */
      for (let i = 0; i < strs.length; i++) {
        arr.push(strs[i]);
      }
    }

    this.game_info_data.upopennumber = arr;
    this.game_info_data.upopennumber_cs = arr1;
    this.sub_link = "";
    this.pk = this.$store.state.instant_pk;
    this.get_loss_data();
    this.getsum();
    this.get_chang_long(this.game_info_data.gid);
    this.change_is_manlet(this.game_info_data.gid);
    this.$nextTick(() => {
      this.$refs["gameinfo"].time_down(this.game_info_data.stop_time);
      this.$refs["gameinfo"].updeta_data();
    });
  },

  beforeUnmount() {
    this.sub_uid = "";
    this.$refs["gameinfo"].clear_all_timer();
  },

  methods: {
    change_play(name) {
      this.$emit("change_play", name);
    },

    zdmx_change_page(page) {
      this.zdmx.page = page;
      this.get_zdmx_data(this.zdmx.row);
    },

    get_zdmx_data(row) {
      // console.log(row);
      this.zdmx.row = row;
      let obj = {
        gid: this.game_info_data.gid,
        phaseid: this.game_info_data.p_id,
        page: this.zdmx.page,
        limit: 30,
        status: 2,
        qnum: this.game_info_data.nn,
        BeginDate: "",
        EndDate: ""
      };

      if (row.level_s != undefined) {
        if (row.level_two != undefined) {
          obj.levle_s = `${row.level_two}_${row.level_three}_${row.level_four}`;
          obj.four_key = row.four_key;
        } else {
          obj.levle_s = row.level_s;
        }
      } else if (row.key != undefined) {
        obj.levle_s = row.key;
      } else {
        if (this.game_info_data.gid == 20 || this.game_info_data.gid == 34 || this.game_info_data.gid == 49) {
          if (row.level_two != undefined) {
            obj.levle_s = row.level_two;

            if (row.level_three != undefined) {
              obj.levle_s += `_${row.level_three}`;

              if (row.level_four != undefined) {
                obj.levle_s += `_${row.level_four}`;
              }
            }
          }
        } // console.log(obj.levle_s);


        if (obj.id != row.newid) {
          obj.oddsid = row.newid;
        } else {
          obj.oddsid = row.id;
        }
      }

      if (this.game_info_data.nn == this.game_info_data.upopenphase) {
        obj.status = 1;
      }

      if (this.balls == "tm") {
        obj.phaseid_tmab = 1;
      }

      if (this.game_info_data.gid == 20 || this.game_info_data.gid == 34 || this.game_info_data.gid == 49) {
        if (obj.status == 1) {
          let start = new Date();
          start.setDate(start.getDate() - 1);
          obj.BeginDate = this.$filters.time(start.setHours(6, 0, 0) / 1000);
          obj.EndDate = this.$filters.time(new Date() / 1000);
        } else {
          obj.BeginDate = this.$filters.time(new Date().setHours(6, 0, 0) / 1000);
          obj.EndDate = this.$filters.time(new Date() / 1000);
        }
      } else {
        obj.BeginDate = this.$filters.time(new Date().setHours(0, 0, 0) / 1000);
        obj.EndDate = this.$filters.time(new Date().setHours(23, 59, 59) / 1000);
      }

      this.axios.post("betbackup/order_detail_new", obj).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.zdmx.pagination = result.data.repsoneContent.pagination;
          let obj = result.data.repsoneContent.list;
          this.zdmx.data_list = [];

          for (const key in obj) {
            this.zdmx.data_list.push(obj[key]);
          }

          this.zdmx.visible = true;
        }
      });
    },

    change_pl(plobj, type = "") {
      // console.log(plobj);
      if (!this.can_change_pl) {
        return;
      }

      this.can_change_pl = false;
      let row = plobj.row;
      let uid = this.$store.state.isUserSetLoss;
      uid === "" ? uid = this.$store.state.userinfo.user_id : "";
      let plusminus = plobj.plusminus;
      let wtvalue = "";

      if (plusminus == "plus") {
        wtvalue = plobj.pl * 1 + this.pl_value * 1;
      } else if (plusminus == "minus") {
        wtvalue = plobj.pl * 1 - this.pl_value * 1;
      } else {
        wtvalue = plobj.pl_change_value * 1;
      }

      wtvalue = wtvalue.toFixed(3) * 1;

      if (type == "") {
        if (wtvalue > row.maxpl || wtvalue < row.minpl) {
          this.can_change_pl = true;
          return this.$message.error(`超出修改 ${row.name} 的赔率范围`);
        }

        if (row.name == "不连0尾") {
          // console.log(this.list);
          let list = this.list[0];
          let oddsid = [];
          let wtvalue = [];
          let old_pl = [];

          for (let i = 1; i < list.length; i++) {
            // console.log(list[i]);
            if (row.pl < list[i].minpl || row.pl > list[i].maxpl) {
              this.get_loss_data();
              this.can_change_pl = true;
              return this.$message.error(`超出修改 ${list[i].name} 的赔率范围`);
            } else {
              oddsid.push(list[i].newid);
              wtvalue.push(plusminus == "plus" ? list[i].pl + this.pl_value : list[i].pl - this.pl_value);
              old_pl.push(list[i].old_pl);
            }
          }

          row.dis_pl = true;
          this.axios.post("trader/update_rate", {
            gid: this.game_info_data.gid,
            oddsid: oddsid,
            wtvalue: wtvalue,
            old_pl: old_pl,
            // userid: uid,
            userid: this.$store.state.userinfo.user_id,
            user: "",
            phaseid: this.$store.state.lottery.p_id,
            tmab: this.balls == "tm" ? 1 : ""
          }).then(result => {
            if (result.data.status == 200) {
              this.$message.closeAll(); // console.log(result.data.repsoneContent)

              this.$message.success(result.data.shortMessage);
              this.get_loss_data();
            }
          });
        } else {
          row.dis_pl = true;
          let plusminus = row.plusminus;
          this.axios.post("trader/update_rate", {
            gid: this.game_info_data.gid,
            oddsid: row.newid,
            wtvalue: wtvalue,
            maxpl: row.maxpl,
            minpl: row.minpl,
            old_pl: plobj.old_pl,
            // userid: uid,
            userid: this.$store.state.userinfo.user_id,
            user: "",
            phaseid: this.game_info_data.p_id,
            tmab: this.balls == "tm" ? 1 : ""
          }).then(result => {
            if (result.data.status == 200) {
              this.$message.closeAll(); // console.log(result.data.repsoneContent)

              this.$message.success(result.data.shortMessage);
              this.get_loss_data();
            }
          });
        }
      } else {
        row.dis_pl = true;
        this.axios.post("trader/update_dz_rate", {
          gid: this.game_info_data.gid,
          qnum: this.game_info_data.nn,
          // oddsid: row.newid,
          wtvalue: wtvalue,
          // maxpl: row.maxpl,
          // minpl: row.minpl,
          old_pl: plobj.old_pl,
          // userid: uid,
          // userid: this.$store.state.userinfo.user_id,
          // user: '',
          // phaseid: this.game_info_data.p_id,
          // tmab: this.balls == 'tm' ? 1 : '',
          ball: plobj.row.ball,
          level_four: plobj.row.level_s.level_four,
          level_three: plobj.row.level_s.level_three,
          level_two: plobj.row.level_s.level_two
        }).then(result => {
          if (result.data.status == 200) {
            this.$message.closeAll(); // console.log(result.data.repsoneContent)

            this.$message.success(result.data.shortMessage);
            this.get_loss_data();
          }
        });
      }
    },

    updeta_pl_value(value) {
      this.pl_value = value;
    },

    change_submenu(menu) {
      // console.log(menu);
      this.zupai.page = 1;
      this.sub_link = menu.link;

      if (this.sub_link.includes("tm") || this.sub_link.includes("lma")) {
        this.get_olny_pl();
      } else {
        this.get_loss_data();
      }

      this.change_background();
    },

    //切换盘口
    changepk(pk) {
      // console.log(pk);
      this.$store.state.instant_pk = this.pk = pk;
      this.get_loss_data();
    },

    //切换实占虚占
    change_xuzhu_shizhan(value) {
      this.szsz = value;
      this.zupai.page = 1;
      this.get_loss_data();
      this.get_zupai();
      this.getsum();
    },

    change_zupai_type(value) {
      this.s_type = value;
      this.zupai.page = 1;
      this.get_zupai();
    },

    change_zupai_page(page) {
      this.zupai.page = page;
      this.get_zupai();
    },

    sub_ksbh_all_input() {
      if (Number.isNaN(Number(this.zupai.ksbh_all_input))) {
        return this.$message.error(`请填写正确的金额`);
      }

      this.zupai.list.forEach(item => {
        item.let.control_let = Number(this.zupai.ksbh_all_input);
      });
    },

    //补货明细合计
    bhmx_sum(param) {
      const {
        columns,
        data
      } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }

        if (index === 3) {
          sums[index] = "-";
          return;
        }

        const values = data.map(item => Number(item[column.property]));

        if (column.property != "yk") {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);

            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index];
        }
      });
      return sums;
    },

    //补货明细
    get_bhmx(row = "") {
      let postobj = {
        qnum: this.game_info_data.nn,
        play_id: this.game_info_data.gid,
        is_manlet: this.is_manlet,
        type: row == "" ? 1 : 2,
        balls: this.balls
      };

      if (row !== "") {
        //号码补货明细
        postobj = { ...postobj,
          ...row
        };
      }

      this.axios.post("userlet/getAllLetList", postobj).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          let list = result.data.repsoneContent.list;
          let zupai = [];

          if ((this.balls.includes("lma") || this.balls.includes("lx") || this.balls.includes("lxyz") || this.balls.includes("wl") || this.balls.includes("bz")) && this.balls != "wsbz") {
            zupai = this.zupai.list;
          } else {
            zupai = this.tm_zupai;
          } // console.log(zupai);


          list.forEach(item => {
            zupai.forEach(ball => {
              if (item.ball == ball.name) {
                item.color = ball.color || "";
                item.yk = ball.yk || 0;
              }
            });
          });

          if (row == "") {
            list = list.sort((a, b) => a.let_res - b.let_res);
            this.bhmx.list = list;
            this.bhmx.visible = true;
          } else {
            this.bhmx.ball_mx.list = list;
            this.bhmx.ball_mx.visible = true;
          }
        }
      });
    },

    //快速补货
    fast_bh(type = "", fxz = "") {
      if (type == "yjbh") {
        if (fxz == "" || fxz == "0" || fxz == undefined || Number.isNaN(Number(fxz)) || fxz < 1) {
          return this.$message.error(`请填写正确的风险值`);
        }

        fxz = Number(fxz);

        if (fxz.toString().includes(".")) {
          return this.$message.error(`请填写整数风险值`);
        } // console.log(fxz);


        this.get_zupai(false, fxz).then(() => {
          this.sub_ksbh("fxzbh");
        });
      } else {
        this.get_zupai(true);
      }
    },

    //快速补货提交
    sub_ksbh(type = "") {
      // console.log(2);
      let data = [];
      let list = this.zupai.list; // console.log(list);

      for (let index = 0; index < list.length; index++) {
        if (list[index].let.control_let * 1 > list[index].let.oc_bill * 1) {
          return this.$message.error(`${list[index].let.name} 的补货金额不能大于下注额`);
        }

        if (list[index].let.control_let != "" && list[index].let.control_let != 0 && list[index].let.control_let * 1 < 1) {
          return this.$message.error(`${list[index].let.name} 的补货金额不能小于1`);
        }

        if (list[index].let.oc_bill != "" && list[index].let.control_let > 0) {
          data.push({
            level_two: list[index].level_two,
            level_three: list[index].level_three,
            level_four: list[index].level_four,
            place: list[index].place,
            ball: list[index].ball,
            control_let: list[index].let.control_let,
            rebate_level: (list[index].let.rebate_level * 1).toFixed(1),
            rate: list[index].let.rate != "" ? list[index].let.rate : list[index].let.list_bonus,
            level_s: list[index].let.level_s,
            oc_bill_s: list[index].let.occupy_bill_s,
            oc_bill_tm: list[index].let.oc_bill_tm,
            title: list[index].let.title
          }); // return console.log(data);
        }
      }

      if (data.length < 1) {
        if (type == "fxzbh") {
          this.$message.warning(`当前风险值计算后，暂无可补数据`);
        }

        return false;
      }

      let obj = {
        is_manlet: this.is_manlet,
        play_id: this.game_info_data.gid,
        balls: this.balls,
        qnum: this.game_info_data.nn,
        pk: this.active_pk,
        szsz: this.zhancheng,
        periods_id: this.game_info_data.p_id,
        data: data,
        is_update: "0"
      };
      this.axios.post("userlet/userAllLet", obj).then(result => {
        if (result.data.shortMessage.includes("是否确认补货")) {
          this.$confirm(result.data.shortMessage, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            obj.is_update = "1";
            this.axios.post("userlet/userAllLet", obj).then(result => {
              if (result.data.status == 200) {
                this.$message.success(result.data.shortMessage);
                this.get_loss_data();
              }
            });
          }).catch(() => {
            obj.is_update = "0";
            this.$message({
              type: "info",
              message: "已取消操作"
            });
          });
        } else {
          this.$message(result.data.shortMessage);
        }
      });
    },

    update_zupai(list) {
      let tm_zupai = JSON.parse(JSON.stringify(list));
      this.tm_zupai = tm_zupai.filter(item => item.szsz_amount[4] < 0); // console.log(this.tm_zupai);
    },

    //六合彩获取组排/补货
    get_zupai(show_ksbh, fxz) {
      return new Promise((resolve, reject) => {
        if ((this.balls.includes("lma") || this.balls.includes("lx") || this.balls.includes("lxyz") || this.balls.includes("wl") || this.balls.includes("bz")) && this.balls != "wsbz") {
          //获取有投注的信息，进行整合
          this.axios.post("userlet/getAllLet", {
            is_manlet: this.is_manlet,
            play_id: this.params.gid,
            balls: this.balls,
            pk: this.pk,
            qnum: this.game_info_data.nn,
            szsz: this.szsz,
            s_type: this.s_type,
            page: this.zupai.page
          }).then(result => {
            if (result.data.status == 200) {
              let data_list = result.data.repsoneContent.list; // console.log(data_list);

              this.zupai.pagination = result.data.repsoneContent.pagination;
              this.zupai.pagination.total_records *= 1;
              this.zupai.type = result.data.repsoneContent.type; // console.log(data_list);

              if (data_list.length < 1) {
                if (show_ksbh) {
                  this.$message.error(`暂无可补数据`);
                  this.zupai.show_ksbh = false;
                }

                return this.zupai.list = [];
              }

              let allyk = 0;
              data_list.forEach(item => {
                item.name = item.level_s.name;
                item.ball = item.level_s.name;
                item.place = item.level_s.place;
                item.yk = item.profit_bill;
                item.let = {};
                item.let.control_let = "";
                item.let.rate = "";
                item.let.rebate_level = "";
                item.let.level_s = item.level_s;
                item.let.name = item.level_s.name;
                item.let.title = item.title;
                item.let.profit_bill = item.profit_bill;
                item.let.level_key = item.level_key;
                item.let.list_bonus = item.list_bonus;
                item.let.oc_bill = item.oc_bill;
                item.let.occupy_bill_s = item.occupy_bill_s;
                item.info = {
                  color: ""
                }; // console.log(fxz);
                // if (fxz && Math.abs(item.let.profit_bill) > fxz) {
                //     let value = m((Math.abs(item.let.profit_bill) - fxz) / item.let.list_bonus);
                //     if (value > 0) {
                //         item.let.control_let = value;
                //     }
                // }

                if (fxz && Math.abs(item.let.profit_bill) > fxz) {
                  allyk += Math.ceil(Math.abs(item.let.profit_bill) - fxz);
                }
              });

              if (allyk > 0) {
                data_list.forEach(item => {
                  let value = Math.ceil((Math.abs(item.let.profit_bill) - fxz + Math.ceil(allyk / item.let.list_bonus)) / item.let.list_bonus);

                  if (value > 0) {
                    item.let.control_let = value;
                  }
                });
              }

              this.zupai.list = data_list.sort((a, b) => b.let.oc_bill - a.let.oc_bill);

              if (show_ksbh != undefined) {
                this.zupai.show_ksbh = show_ksbh;
              }

              resolve(true); // console.log(this.zupai.list);
            }
          });
        } else {
          let allyk = 0;
          this.tm_zupai.forEach(item => {
            item.let = {};
            item.let.name = item.name;
            item.ball = item.name;
            item.place = item.place;
            item.let.control_let = "";
            item.let.rate = "";
            item.let.rebate_level = "";
            item.let.level_s = {
              ball: item.ball,
              level_two: item.level_two,
              level_three: item.level_three,
              level_four: item.level_four,
              name: item.ball,
              place: item.place
            };
            item.let.level_key = item.level_key;
            item.let.list_bonus = item.pl * 1 || "";
            item.let.oc_bill = item.szsz_amount[1];
            item.let.occupy_bill_s = item.szsz_amount[6];
            item.let.title = item.game_type;
            item.let.oc_bill_tm = "";
            item.color = item.color || "";
            item.info = {
              color: item.color
            };
            item.let.profit_bill = item.szsz_amount[4] || item.profit_bill;

            if (item.let.title == "特码A+B" && !Number.isNaN(Number(item.name))) {
              if (item.szsz_amount_tmb != undefined) {
                if (item.szsz_amount_tmb[6] == "" || item.szsz_amount_tmb[6] == "0") {
                  item.let.oc_bill_tm = 0;
                } else {
                  item.let.oc_bill_tm = item.szsz_amount_tmb[6];
                }
              } else {
                item.let.oc_bill_tm = 0;
              }
            }

            if (Number.isNaN(Number(item.let.level_s.name))) {
              item.let.level_s.ball = "";
            }

            if (fxz && Math.abs(item.let.profit_bill) > fxz) {
              allyk += Math.ceil(Math.abs(item.let.profit_bill) - fxz);
            } // if (fxz && Math.abs(item.let.profit_bill) > fxz) {
            //     let value = Math.ceil((Math.abs(item.let.profit_bill) - fxz) / item.let.list_bonus);
            //     if (value > 0) {
            //         item.let.control_let = value;
            //     }
            // }

          });

          if (allyk > 0) {
            this.tm_zupai.forEach(item => {
              let value = Math.ceil((Math.abs(item.let.profit_bill) - fxz + Math.ceil(allyk / item.let.list_bonus)) / item.let.list_bonus);

              if (value > 0) {
                item.let.control_let = value;
              }
            });
          }

          this.zupai.list = this.tm_zupai.sort((a, b) => a.szsz_amount[4] - b.szsz_amount[4]); // console.log(this.balls, this.zupai.list);

          if (show_ksbh != undefined) {
            this.zupai.show_ksbh = show_ksbh;
          } // console.log(1);


          resolve(true);
        }
      });
    },

    //获取赔率,匹配盘口和实占1,虚占0
    get_loss_data() {
      const _this = this;

      this.cancelQuest();
      let postobj = {
        gid: this.params.gid,
        pk: this.pk,
        szsz: this.szsz,
        //实占1,虚占0
        type: "0",
        //1，代表会员，0代理
        periods_status: this.game_info_data.nn == this.game_info_data.upopenphase ? 1 : 0,
        ws: this.game_info_data.result_res.ws ? this.game_info_data.result_res.ws : "",
        zodiac: this.game_info_data.result_res.zodiac ? this.game_info_data.result_res.zodiac : ""
      }; // if (this.game_info_data.result_res.ws) {
      // }

      let link = ""; // console.log(this.$store.state,this.params);

      if (this.sub_link == "") {
        link = this.params.link;
      } else {
        link = this.sub_link;
      }

      let balls = this.sub_link == "" ? this.params.link.split("=")[1] : this.sub_link.split("=")[1]; // console.log(balls);

      this.balls = balls; // console.log(this.$store.state.checked_menu);
      //加载缓存数据

      for (const key in this.$store.state.instant_cacheData) {
        if (key == balls && this.$store.state.instant_cacheData[key] != null) {
          this.$nextTick(() => {
            this.$refs[this.comp].proc(this.$store.state.instant_cacheData[key]);
          });
        }
      }

      this.axios.post(link, postobj, {
        cancelToken: new this.axios.CancelToken(function executor(c) {
          _this.source = c;
        })
      }).then(result => {
        if (result.data.status == 200) {
          this.loss_data = result.data.repsoneContent; //缓存数据

          this.$store.state.instant_cacheData[balls] = JSON.parse(JSON.stringify(this.loss_data)); // //缓存首页tm数据
          // if (balls == 'tm') {
          //     this.$store.state.instant_cacheData['tm'] = JSON.parse(JSON.stringify(this.loss_data));
          // }

          this.$refs[this.comp].proc(this.loss_data);

          if (!this.can_change_pl) {
            this.can_change_pl = true;
          }
        }
      }); //判断是否获取六合彩组排数据

      if (this.params.title == "连码" || this.params.title == "自选不中" || this.params.title == "连肖" || this.params.title == "六肖一中" || this.params.title == "尾连") {
        this.get_zupai();
      }
    },

    get_game_info(pk = "", user_id = "", loos = "") {
      // console.log(this.params);
      this.get_chang_long(this.params.gid);
      this.pk = pk;
      this.axios.post("trader/index_header", {
        gid: this.params.gid,
        pk,
        user_id
      }).then(result => {
        if (result.data.status == 200) {
          let game_info = result.data.repsoneContent;
          this.$store.state.instant_gameinfo = game_info;
          let strs = "";

          if (typeof game_info.upopennumber == "string" && game_info.upopennumber != "") {
            strs = game_info.upopennumber.split(",");
          }

          let arr = [];
          let arr1 = [];
          game_info.upopennumber = arr;
          game_info.upopennumber_cs = arr1;
          this.game_info_data = game_info;
          this.game_info_data.title = this.params.title;
          /**判断解析六合彩球号，颜色，生肖 */

          if (game_info.gid == 20 || game_info.gid == 34 || game_info.gid == 48 || game_info.gid == 49) {
            this.$nextTick(() => {
              this.$refs["lhc"].int_submenu();
            });

            for (let i = 0; i < strs.length; i++) {
              arr.push(lhcNum(strs[i], game_info.upopenstarttime.split(" ")[0]));
            } // console.log(game_info.upopennumber);


            if (strs != "") {
              arr = get_lhc_sx("开奖", arr);
            }

            if (game_info.upopennumber_cs) {
              for (let i = 0; i < game_info.upopennumber_cs.length; i++) {
                arr1.push(lhcNum(game_info.upopennumber_cs[i], game_info.upopenstarttime.split(" ")[0]));
              }

              arr1 = get_lhc_sx("开奖", arr1);
            }
          } //pc蛋蛋，加拿大28，幸运28
          else if (game_info.gid == "22" || game_info.gid == "23") {
            for (let i = 0; i < strs.length; i++) {
              arr.push(pcddNum(strs[i]));
            }
          } else {
            /**其他彩种球号，cqssc,pk10 */
            for (let i = 0; i < strs.length; i++) {
              arr.push(strs[i]);
            }
          }

          if (loos == "") {
            this.get_loss_data();
          }

          this.getsum();
          this.change_is_manlet(game_info.gid);
          this.$nextTick(() => {
            this.$refs["gameinfo"].time_down(game_info.stop_time);
            this.$refs["gameinfo"].updeta_data();
          });
          this.change_background();
        }
      });
    },

    //两面长龙
    get_chang_long(gid = "") {
      if (gid == "" || gid == 20 || gid == 34 || gid == 48 || gid == 49) {
        return false;
      }

      this.axios.post("ajax/changlong", {
        gid
      }).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          if (result.data.repsoneContent !== "") {
            let data = result.data.repsoneContent.data.msg;
            let list = [...data[0], ...data[1]]; // console.log(list);

            list.sort((a, b) => b.b_s_qnum - a.b_s_qnum);
            list.forEach(item => {
              item.name = item.big_small == undefined ? item.odd_even : item.big_small;
            });
            this.chang_long = list;
          }
        }
      });
    },

    //处理总额数据
    getsum() {
      this.axios.post("trader/bet_count", {
        gid: this.game_info_data.gid,
        qnum: this.game_info_data.nn
      }).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          if (result.data.repsoneContent !== "") {
            this.sum.all_balance = result.data.repsoneContent.all_balance;
            this.sum.own_balance = result.data.repsoneContent.own_balance;
            let list = result.data.repsoneContent.bet_count_level || [];
            let sums = [];
            list.forEach(item => {
              sums.push(item);
            });
            this.sum.sums = sums;
          }
        }
      });
    },

    //判断是否可以补货
    change_is_manlet(gid) {
      if (this.$store.state.userinfo.is_manlet != "-1") {
        if (gid == 20 || gid == 34 || gid == 49) {
          if (this.$store.state.userinfo.group_id == 2) {
            this.is_manlet = "0";
          } else if (this.$store.state.userinfo.group_id > 2 && this.$store.state.userinfo.is_manlet == "1") {
            this.is_manlet = "1";
          }
        } else {
          this.is_manlet = "-1";
        }
      }
    },

    change_background() {
      if (this.game_info_data.gid != 20 && this.game_info_data.gid != 34 && this.game_info_data.gid != 49) {
        if (this.game_info_data.isopen == 0 || this.game_info_data.stop_time == "00:00:00") {
          let tags = document.getElementsByClassName("el-table__cell");
          this.$nextTick(() => {
            // console.log(tags);
            for (let index = 0; index < tags.length; index++) {
              if (tags[index].style.backgroundColor == "" || tags[index].style.backgroundColor == "#ffffff" || tags[index].style.backgroundColor == "rgb(255, 255, 255)") {
                tags[index].style.backgroundColor = "#eeeeee";
              }
            }
          });
        } else {
          let tags = document.getElementsByClassName("el-table__cell");
          this.$nextTick(() => {
            for (let index = 0; index < tags.length; index++) {
              if (tags[index].style.backgroundColor == "#eeeeee" || tags[index].style.backgroundColor == "rgb(238, 238, 238)") {
                tags[index].style.backgroundColor = "#ffffff";
              }
            }
          });
        }
      }
    },

    //只快速获取赔率，不含盈亏
    get_olny_pl() {
      let postobj = {
        gid: this.params.gid,
        pk: this.pk,
        szsz: this.szsz,
        //实占1,虚占0
        type: "0",
        //1，代表会员，0代理
        periods_status: this.game_info_data.nn == this.game_info_data.upopenphase ? 1 : 0
      };
      let link = ""; // console.log(this.$store.state,this.params);

      if (this.sub_link == "") {
        link = this.params.link;
      } else {
        link = this.sub_link;
      }

      let balls = this.sub_link == "" ? this.params.link.split("=")[1] : this.sub_link.split("=")[1]; // console.log(balls);

      postobj.ball = balls;
      this.axios.post("trader/index_backup", postobj).then(result => {
        if (result.data.status == 200) {
          this.loss_data = result.data.repsoneContent;
          this.$refs[this.comp].proc(this.loss_data);

          if (!this.can_change_pl) {
            this.can_change_pl = true;
          }
        }

        this.get_loss_data();
      });
    },

    cancelQuest() {
      if (typeof this.source === "function") {
        this.source("终止请求", "终止请求"); //取消请求
      }
    }

  }
};