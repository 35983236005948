export default {
  components: {},

  data() {
    return {
      pwd: {
        old: "",
        value: "",
        confirm: ""
      }
    };
  },

  created() {},

  methods: {
    reset() {
      this.pwd.old = "";
      this.pwd.value = "";
      this.pwd.confirm = "";
    },

    sub() {
      let old = this.pwd.old;
      let value = this.pwd.value;
      let confirm = this.pwd.confirm;

      if (old == "" || value == "" || confirm == "") {
        return this.$alert("密码不能为空", "错误提示", {
          confirmButtonText: "确定",
          type: "error",
          callback: action => {}
        });
      }

      if (!/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,20}$/.test(value)) {
        return this.$alert("新密码必须包含大小写字母和数字组合，8-20位", {
          confirmButtonText: "确定",
          type: "error",
          callback: action => {}
        });
      }

      if (confirm != value) {
        return this.$alert("两次输入的新密码不一样，请重新输入", "错误提示", {
          confirmButtonText: "确定",
          type: "error",
          callback: action => {}
        });
      }

      this.axios.post("user_version2/user_info_save", {
        txtid: this.$store.state.userinfo.user_id,
        txtoldpwd: old,
        txtnewpwd: value,
        txtnewpwdcf: confirm
      }).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent);
          this.$alert(result.data.shortMessage, "成功提示", {
            confirmButtonText: "确定",
            type: "success",
            callback: action => {
              this.reset();
            }
          });
        }
      });
    }

  }
};