import axios from '../../../axios/axios';
export default {
  data() {
    return {
      news_data: [],
      page: 1,
      act: "",
      id: "",
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      dialog: {
        visible: '消息列表',
        title: "",
        do: "",
        row: {
          id: "",
          level: "",
          //层级1.代理 2.会员 0.所有人
          title: "",
          text: "",
          s_type: "",
          //公告类型1跑马灯 2弹窗
          stick: "" //是否置顶0-不置顶，1置顶

        }
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("play/news", {
      page: 1
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          vm.news_data = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination;
        });
      }
    });
  },

  methods: {
    //新增消息
    add_new() {
      this.dialog.visible = '新增站内消息';
      this.dialog.do = "add";
      this.dialog.row.level = "0";
      this.dialog.row.title = "";
      this.dialog.row.text = "";
      this.dialog.row.s_type = "1";
      this.dialog.row.stick = "0";
    },

    //修改新增消息
    sub_change_new() {
      console.log(this.dialog.do);

      if (this.dialog.do == "change") {
        // console.log(this.dialog.row);
        this.axios.post("play/news_edit", this.dialog.row).then(result => {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            this.$message({
              message: result.data.shortMessage,
              type: 'success'
            });
            this.dialog.visible = '消息列表';
          }
        });
      } else {
        console.log(this.dialog.row);
        this.axios.post("play/news_insert", {
          title: this.dialog.row.title,
          text: this.dialog.row.text,
          s_type: this.dialog.row.s_type,
          level: this.dialog.row.level,
          stick: this.dialog.row.stick
        }).then(result => {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            this.$message({
              message: result.data.shortMessage,
              type: 'success'
            });
            this.dialog.visible = '消息列表';
          }
        });
      }
    },

    change_new(row) {
      this.dialog.do = "change";
      this.dialog.visible = "修改站内消息";
      this.dialog.row = row;
    },

    //删除消息
    del_new(id) {
      this.$confirm("此操作将永久删除该条消息, 是否继续?", "警告", {
        confirmButtonText: "确定删除",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("play/news", {
          page: this.page,
          act: 'del',
          id
        }).then(result => {
          if (result.data.status == 200) {
            this.$message({
              type: 'success',
              message: result.data.shortMessage,
              offset: 400
            });
            this.get_news_data();
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
      ;
    },

    //分页
    change_page(page) {
      this.page = page;
      this.get_news_data("", "");
    },

    get_news_data() {
      this.axios.post("play/news", {
        page: this.page
      }).then(result => {
        if (result.data.status == 200) {
          this.news_data = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;
          this.dialog.visible = '消息列表';
        }
      });
    }

  }
};