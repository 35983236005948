export default {
  data() {
    return {};
  },

  methods: {
    change_submenu(name, params) {
      this.$emit('change_submenu', name, params);
    }

  }
};