import axios from '../../../axios/axios';
export default {
  name: 'anquanmaguanli',
  components: {},

  data() {
    return {
      list: [],
      title: '安全码管理',
      dialog: {
        code: '',
        remark: '',
        id: ''
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("check/index", {}).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          vm.list = result.data.repsoneContent.list;
        });
      }
    });
  },

  created() {},

  methods: {
    change(row) {
      this.dialog.code = row.code;
      this.dialog.remark = row.remark;
      this.dialog.id = row.id;
      this.title = '修改安全码';
    },

    add() {
      this.dialog.code = '';
      this.dialog.remark = '';
      this.title = '新增安全码';
    },

    cancel_add() {
      this.get_list();
      this.title = '安全码管理';
    },

    sub_add() {
      let postobj = {
        code: this.dialog.code,
        remark: this.dialog.remark,
        act: 'add'
      };

      if (this.dialog.id != '') {
        postobj.id = this.dialog.id;
      }

      this.axios.post("check/check_add", postobj).then(result => {
        if (result.data.status == 200 || result.data.status) {
          this.$message.success(result.data.repsoneContent || '添加成功！');
          this.get_list();
          this.title = '安全码管理';
          this.dialog.id = '';
          this.dialog.code = '';
          this.dialog.remark = '';
        }
      });
    },

    del(id) {
      this.axios.post("check/check_edit", {
        id,
        act: 'del'
      }).then(result => {
        if (result.data.status == 200 || result.data.status) {
          this.$message.success(result.data.shortMessage || '删除成功！');
          this.get_list();
        }
      });
    },

    get_list() {
      this.axios.post("check/index", {}).then(result => {
        if (result.data.status == 200) {
          this.list = result.data.repsoneContent.list;
        }
      });
    }

  }
};