<!--
 * @Author: your name
 * @Date: 2022-03-11 16:35:56
 * @LastEditTime: 2023-10-13 16:19:18
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\views\Login.vue
-->
<template>
    <div class="login" :style="{ width: screenwidth }">
        <div style="background-color: #fff; height: 3px"></div>
        <div class="logintitle">WELCOME</div>
        <div class="loginbox" v-if="!first_change_pwd.visible">
            <form name="form_login" id="form_login" onsubmit="return CheckForm();" method="post" onkeypress="CheckKey(event);">
                <div class="tx">
                    <img src="../assets/manager.png" />
                    <input type="hidden" name="action" value="login" />
                    <input type="hidden" name="v1" value="c0fb3f85a5b70519029c75a89c92477f" />
                    <input type="hidden" name="v2" value />
                </div>
                <div class="shenfen">
                    <img src="../assets/manager_tit.png" />
                </div>
                <div class="usemsg">
                    <div class="user">
                        <div class="ueser1">
                            <img src="../assets/user.png" />
                        </div>
                        <div class="user2">
                            <input v-model="form.user.user_name" type="text" id="user" name="user" tabindex="1" autocomplete="off" maxlength="16" placeholder="請輸入用戶名" />
                        </div>
                    </div>
                    <div class="user">
                        <div class="ueser1">
                            <img src="../assets/pwd.png" />
                        </div>
                        <div class="user2">
                            <input v-model="form.user.passwd" type="password" id="pass" name="pass" tabindex="2" autocomplete="off" maxlength="16" placeholder="請輸入密碼" />
                        </div>
                    </div>
                    <div class="user" v-show="error_times >= 3">
                        <div class="ueser1">
                            <img src="../assets/code.png" />
                        </div>
                        <div class="user2">
                            <input v-model="form.user.checkcode" type="text" id="code" name="code" tabindex="3" autocomplete="off" maxlength="4" placeholder="請輸入驗證碼" />
                        </div>
                        <div class="code2">
                            <img id="pic_code" :src="codeimg" align="middle" style="height: 28px; width: 85px; cursor: pointer" title="點擊更換驗證碼" @click="getcode" />
                        </div>
                    </div>
                </div>
                <a class="loginbtn">
                    <button type="button" id="login_btn" @click="login">登 錄</button>
                </a>
            </form>
            {{ $store.state.version }}
        </div>
        <!-- 首次修改密码 -->
        <div v-else class="panel" style="width: 600px">
            <div class="panel-title">
                <h2>首次登录或密码已重置，安全原因，请输入新的登录密码</h2>
            </div>
            <div class="panel-body">
                <div class="myform">
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="6">旧密码</el-col>
                        <el-col class="myform-item-value" :span="18">
                            <el-popover :visible="err.txtoldpwd.visible" placement="right">
                                <template #reference>
                                    <el-input style="width: 140px" v-model.trim="first_change_pwd.txtoldpwd" size="small" clearable show-password type="password" @focus="err.txtoldpwd.visible = false" />
                                </template>
                                <span>
                                    <el-icon color="red" size="20px">
                                        <warning-filled />
                                    </el-icon>
                                    <span>{{ err.txtoldpwd.content }}</span>
                                </span>
                            </el-popover>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="6">新密码</el-col>
                        <el-col class="myform-item-value" :span="18">
                            <el-popover :visible="err.txtnewpwd.visible" placement="right">
                                <template #reference>
                                    <el-input style="width: 140px" v-model.trim="first_change_pwd.txtnewpwd" size="small" clearable show-password type="password" @focus="err.txtnewpwd.visible = false" />
                                </template>
                                <span>
                                    <el-icon color="red" size="20px">
                                        <warning-filled />
                                    </el-icon>
                                    <span>{{ err.txtnewpwd.content }}</span>
                                </span>
                            </el-popover>
                            <span>&nbsp;&nbsp;登录密码必需包含大小写字母和数字，最少8位</span>
                        </el-col>
                    </el-row>
                    <el-row class="myform-item">
                        <el-col class="myform-item-lable" :span="6">重复新密码</el-col>
                        <el-col class="myform-item-value" :span="18">
                            <el-popover :visible="err.txtnewpwdcf.visible" placement="right">
                                <template #reference>
                                    <el-input style="width: 140px" v-model.trim="first_change_pwd.txtnewpwdcf" size="small" clearable show-password type="password" @focus="err.txtnewpwdcf.visible = false" />
                                </template>
                                <span>
                                    <el-icon color="red" size="20px">
                                        <warning-filled />
                                    </el-icon>
                                    <span>{{ err.txtnewpwdcf.content }}</span>
                                </span>
                            </el-popover>
                        </el-col>
                    </el-row>
                </div>
                <el-button style="margin-top: 10px" size="small" type="warning" @click="sub_change">确认修改</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "login",
    props: {},
    data() {
        return {
            form: {
                user: {
                    user_name: "",
                    passwd: "",
                    checkcode: "",
                    platform: "pc",
                },
            },
            year: "",
            first_change_pwd: {
                visible: false,
                txtid: "",
                txtoldpwd: "",
                txtnewpwd: "",
                txtnewpwdcf: "",
                salt: "",
                user_id: "",
                username: "",
                old_password: "",
            },
            err: {
                txtoldpwd: {
                    visible: false,
                    content: "",
                },
                txtnewpwd: {
                    visible: false,
                    content: "",
                },
                txtnewpwdcf: {
                    visible: false,
                    content: "",
                },
            },
            screenwidth: "auto",
            error_times: 0,
            codeimg: "",
        };
    },
    created() {
        this.year = new Date().getFullYear();
        this.$store.state.now_menu = "";
    },
    methods: {
        login() {
            // console.log(document.cookie);
            if (localStorage.getItem(`login_user_token`)) {
                document.cookie = "PHPSESSID=;expires=-1";
            }
            if (this.error_times >= 3 && this.checkcode == "") {
                return this.$message.error("請輸入驗證碼");
            }
            if (this.form.user.user_name != "" && this.form.user.passwd != "") {
                this.axios.post("login/ajax_login", this.form.user).then((result) => {
                    // console.log(result);
                    if (result.data.status == 200 && result.data.shortMessage === "登录成功") {
                        this.$store.state.isLogin = true;
                        this.$store.state.userinfo = result.data.repsoneContent;
                        this.$store.state.userinfo.user_name = this.form.user.user_name;
                        let group_id = result.data.repsoneContent.group_id;
                        let group_name = "";
                        if (group_id == 1) {
                            group_name = "总公司";
                        }
                        if (group_id == 2) {
                            group_name = "大总监";
                        }
                        if (group_id == 3) {
                            group_name = "总监";
                        }
                        if (group_id == 4) {
                            group_name = "分公司";
                        }
                        if (group_id == 5) {
                            group_name = "大股东";
                        }
                        if (group_id == 6) {
                            group_name = "股东";
                        }
                        if (group_id == 7) {
                            group_name = "总代理";
                        }
                        if (group_id == 8) {
                            group_name = "代理";
                        }
                        this.$store.state.userinfo.group_name = group_name;
                        this.$store.state.url_query_info = "user=" + this.$store.state.userinfo.user_id + "&token=" + this.$store.state.userinfo.token;
                        sessionStorage.setItem("islogin", "true");
                        this.$store.state.watchsub_show = false;
                        this.$store.state.watchsub_sub_id = "";

                        this.axios.post("account/get_balance?").then((result) => {
                            if (result.data.status === 200) {
                                // console.log(this.$store.state.userinfo)
                                this.$store.state.top_data = result.data.repsoneContent;
                            }
                        });
                        //获取网站名称
                        this.axios.post("login/get_web_info").then((result) => {
                            // let website = result.data.msg.website;
                            if (result.data.status == 1 && result.data.msg.web_name != "") {
                                this.$store.state.web_name = result.data.msg.web_name;
                                this.axios.post("main/game_list").then((result) => {
                                    if (result.data.status === 200) {
                                        let list = result.data.repsoneContent.game_list;
                                        if (list.length > 0) {
                                            list.sort((a, b) => a.order - b.order);
                                            this.$store.state.game_list = list;
                                            this.$store.state.instant_checked_gid = result.data.repsoneContent.game_list[0].id;
                                            this.$store.state.instant_checked_gname = result.data.repsoneContent.game_list[0].gname;
                                        } else {
                                            this.$message.warning("暂无彩种信息，请联系管理");
                                        }
                                    }
                                });
                                this.$router.replace("/home/notice");
                            }
                        });
                    } else if (result.data.status === 200 && (result.data.shortMessage === "第一次登陆需要修改密码" || result.data.shortMessage === "密码已重置请重新设置新密码")) {
                        this.first_change_pwd.txtid = result.data.repsoneContent.id;
                        this.first_change_pwd.user_id = result.data.repsoneContent.id;
                        this.first_change_pwd.salt = result.data.repsoneContent.salt;
                        this.first_change_pwd.username = this.form.user.user_name;
                        this.first_change_pwd.old_password = result.data.repsoneContent.old_password;
                        this.$alert(result.data.shortMessage, "安全提示", {
                            confirmButtonText: "确定",
                            type: "warning",
                            callback: () => {
                                this.first_change_pwd.visible = true;
                                // console.log(this.first_change_pwd);
                            },
                        });
                    } else if (result.data.shortMessage === "登录失败，账号或密码错误!" || result.data.shortMessage === "账号或密码错误") {
                        this.error_times += 1;
                        if (this.error_times >= 3) {
                            this.getcode();
                        }
                    } else if (result.data.shortMessage === "请输入正确的验证码") {
                        this.error_times = 3;
                        this.getcode();
                    }
                });
            }
        },
        getcode() {
            this.axios
                .get(`login/vcode`, {
                    responseType: "arraybuffer",
                })
                .then((result) => {
                    if (true) {
                        const bufferUrl = btoa(new Uint8Array(result.data).reduce((data, byte) => data + String.fromCharCode(byte), ""));
                        this.codeimg = "data:image/png;base64," + bufferUrl;
                    }
                });
        },
        sub_change() {
            if (this.first_change_pwd.txtoldpwd == "") {
                return (this.err.txtoldpwd = { visible: true, content: "请输入旧密码" });
            }
            if (this.first_change_pwd.txtnewpwd == "" || !/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,20}$/.test(this.first_change_pwd.txtnewpwd)) {
                return (this.err.txtnewpwd = { visible: true, content: "密码为8-20位，大小写字母和数字组合" });
            }
            if (this.first_change_pwd.txtnewpwdcf !== this.first_change_pwd.txtnewpwd) {
                return (this.err.txtnewpwdcf = { visible: true, content: "两次输入的新密码不一致" });
            }
            this.axios.post("login/ResetPasswd", this.first_change_pwd).then((result) => {
                if (result.data.status == 200) {
                    this.$alert(result.data.shortMessage, "成功提示", {
                        confirmButtonText: "确定",
                        type: "success",
                        callback: () => {
                            this.form.user.passwd = this.first_change_pwd.txtnewpwdcf;
                            this.login();
                            // this.first_change_pwd.visible = false;
                        },
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
.loginbtn button {
    width: 86%;
    height: 38px;
    line-height: 38px;
    background: #093;
    color: #fff;
    font-size: 16px;
    font-family: "Microsoft YaHei";
    cursor: pointer;
    border: none;
}
.loginbtn {
    display: block;
    text-align: center;
    margin: 6px;
}
.user2 input {
    width: 96%;
    float: right;
    height: 28px;
    line-height: 28px;
    border: none;
    background: no-repeat;
    outline: 0;
    font-size: 16px;
    font-family: sans-serif;
}
.ueser1 img {
    vertical-align: middle;
}
.user2 {
    float: left;
    width: 60%;
}
.ueser1 {
    float: left;
    border-right: 1px solid #ddd;
    width: 10%;
    text-align: center;
}
.user {
    overflow: hidden;
    width: 90%;
    margin: 10px auto;
    background: #edf3eb;
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 8px;
}
.shenfen img {
    width: 400px;
    height: 35px;
}
.loginbtn {
    display: block;
    text-align: center;
    margin: 6px;
}
.usemsg {
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
}
.tx,
.shenfen {
    text-align: center;
}
.loginbox {
    border: 1px solid #ddd;
    width: 400px;
    background: #f6f9f5;
    margin: 60px auto;
    overflow: hidden;
    padding: 20px 0;
}
.logintitle {
    background: #093;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin: 0;
    overflow: hidden;
    color: #fff;
    padding: 10px 0;
}
.login .panel .panel-body {
    padding: 3px;
}
.login .panel .panel-title {
    height: 28px;
    line-height: 28px;
    padding: 0 3px;
    text-align: left;
    background-image: linear-gradient(#fff, rgb(238, 255, 227));
    border-bottom: 1px solid #093;
}
.login .panel .panel-title h2 {
    font-size: 12px;
    font-weight: bold;
    background: url("../assets/title_icon.gif") no-repeat 0 8px;
    padding: 0 0 0 18px;
    margin: 0 0 0 5px;
    float: left;
}
.login .panel {
    border: 1px solid #e6e6e6;
    box-shadow: 0 2px 5px #ccc;
    background-image: linear-gradient(#fff, #fff);
    border: 1px solid #093;
    padding-bottom: 10px;
    margin: 10px auto;
}
.login .sub-menu {
    background-image: linear-gradient(#fff, #fff);
}
.myform {
    border: 1px solid #093;
    margin-top: 3px;
    line-height: 28px;
}
.myform .myform-item:hover .el-col {
    background-color: #ffff2b !important;
}
.myform-item-lable {
    text-align: right;
    padding: 0 10px;
    border-right: 1px solid #093;
    border-bottom: 1px solid #093;
}
.myform-item-value {
    padding: 0 10px;
    text-align: left;
    border-bottom: 1px solid #093;
    background-color: #fff;
}
.myform:nth-last-child(1),
.myform:nth-last-child(2) {
    border-bottom: 0;
}
.code2 {
    float: left;
    width: 25%;
    text-align: right;
}
.code2 img {
    width: 85px;
    height: 28px;
    vertical-align: middle;
}
</style>
