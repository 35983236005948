import axios from '../../../axios/axios';
export default {
  data() {
    return {
      notice_list: [],
      page: 1,
      //查询页码
      limit: 10,
      //每页条数
      // 分页信息
      pagination: {
        page_size: "0",
        //每页条数
        total_pages: 0,
        //总页数
        total_records: "0" //总条数

      }
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("party/notice", {
      page: 1,
      limit: 10,
      s_type: 2
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.notice_list = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination; // 通过 `vm` 访问组件实例
        });
      }
    });
  },

  // created() {
  //     this.get_notice()
  // },
  methods: {
    get_notice() {
      this.axios.post(`/party/notice`, {
        page: this.page,
        limit: this.limit,
        s_type: 1
      }).then(result => {
        if (result.data.status == 200 && result.data.shortMessage === "获取成功") {
          this.notice_list = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;
        }
      });
    },

    change_page(p) {
      this.page = p;
      this.get_notice();
    }

  }
};