<!--
 * @Author: your name
 * @Date: 2022-03-17 23:46:54
 * @LastEditTime: 2023-10-07 10:05:23
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\user\views\daili.vue
-->
<template>
    <div class="daili">
        <div class="panel" :style="{ width: panelwidth }">
            <div class="panel-title">
                <h2>{{ title }}</h2>
                <el-button v-show="other_view.visible" style="float: right; margin-top: 3px" size="small" type="warning" @click="other_view_back">返回</el-button>
                <div v-show="!other_view.visible" style="float: right">代理总余额：{{ amounts[0] }}，会员总余额：{{ amounts[2] }}</div>
            </div>
            <div class="panel-body">
                <div v-show="!other_view.visible">
                    <div style="text-align: left; padding: 3px 0">
                        <el-button size="small" @click="add_user" type="warning">新增代理</el-button>
                        <div style="float: right">
                            <el-select v-model="post_agency.userState" class="m-2" placeholder="Select" size="small" @change="get_users" style="width: 70px">
                                <el-option v-for="item in post_agency.filter" :key="item.value" :label="item.label" :value="item.value" />
                            </el-select>
                            <el-input v-model="post_agency.searchkey" placeholder="账号或名称" style="width: 150px; margin-left: 5px; margin-bottom: 3px" size="small" clearable>
                                <template #append>
                                    <el-button size="small" @click="get_users">搜索</el-button>
                                </template>
                            </el-input>
                        </div>
                    </div>
                    <el-table :data="users" border size="mini" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row" table-layout="auto">
                        <!-- 在线 -->
                        <el-table-column property="is_online" label="在线" align="center" min-width="35">
                            <template #default="scope">
                                <img :src="scope.row.is_online == 1 ? require('../../../assets/zaixian.png') : require('../../../assets/lixian.png')" />
                            </template>
                        </el-table-column>
                        <el-table-column property="parent_name" label="上级总代理" align="center" :min-width="width.parent_name.minwidth">
                            <template #header>
                                <div class="theme-text">上级总代理</div>
                            </template>
                        </el-table-column>
                        <el-table-column v-show="$store.state.userinfo.group_id > 0 && $store.state.userinfo.group_id < 11 && $store.state.userinfo.occupy_status == 1" property="level_num.num_member" align="center" label="占成" min-width="60">
                            <template #header>
                                <span class="theme-text">占成</span>
                            </template>
                            <template #default="scope">
                                <span v-show="scope.row.kusaicai_liuhe_status.is_kuaicai == 1">{{ scope.row.kuaicai_sup_occupy_lock * 1 }}</span>
                                <span v-show="scope.row.kusaicai_liuhe_status.is_kuaicai == 1 && scope.row.kusaicai_liuhe_status.is_liuhe == 1">/</span>
                                <span v-show="scope.row.kusaicai_liuhe_status.is_liuhe == 1">{{ scope.row.sup_occupy_lock * 1 }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="username" label="代理" align="center" :min-width="width.username.minwidth">
                            <template #header>
                                <div class="theme-text">代理</div>
                            </template>
                            <template #default="scope">
                                <span style="color: red">{{ scope.row.username }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column v-show="$store.state.userinfo.group_id > 0 && $store.state.userinfo.group_id < 11 && $store.state.userinfo.occupy_status == 1" property="proportion" label="限占" align="center" min-width="60">
                            <template #default="scope">
                                <span v-show="scope.row.kusaicai_liuhe_status.is_kuaicai == 1">{{ scope.row.kuaicai_proportion * 1 }}</span>
                                <span v-show="scope.row.kusaicai_liuhe_status.is_kuaicai == 1 && scope.row.kusaicai_liuhe_status.is_liuhe == 1">/</span>
                                <span v-show="scope.row.kusaicai_liuhe_status.is_liuhe == 1">{{ scope.row.proportion * 1 }}</span>
                            </template>
                        </el-table-column>
                        <!-- 名称 -->
                        <el-table-column property="nickname" label="名称" align="center" :min-width="width.nickname.minwidth">
                            <template #header>
                                <div class="theme-text">名称</div>
                            </template>
                        </el-table-column>
                        <el-table-column property="level_num[6].num" label="会员" align="center" min-width="50">
                            <template #default="scope">
                                <el-link :underline="false" @click="go_user_subview(scope.row, '会员', scope.row.level_num[7].num)">{{ scope.row.level_num[7].num }}</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column property label="模式" align="center" min-width="60">
                            <template #header>
                                <span class="theme-text">模式</span>
                            </template>
                            <template #default="scope">
                                <span>{{ scope.row.bill_reset == 0 ? "现金" : scope.row.bill_reset == 1 ? "混合" : "信用" }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="balance" label="彩票余额" align="center" :min-width="width.balance.minwidth">
                            <template #header>
                                <div class="theme-text">彩票余额</div>
                            </template>
                            <template #default="scope">
                                <el-tooltip placement="top-start" effect="light">
                                    <template #content>
                                        <div v-if="scope.row.kusaicai_liuhe_status.is_kuaicai == 1">
                                            <span>快彩：</span>
                                            <span>{{ scope.row.kuaicai_balance }}</span>
                                        </div>
                                        <div v-if="scope.row.kusaicai_liuhe_status.is_liuhe == 1">
                                            <span>六合：</span>
                                            <span>{{ scope.row.balance }}</span>
                                        </div>
                                    </template>
                                    <span>
                                        <template v-if="scope.row.kusaicai_liuhe_status.is_kuaicai == 1 && scope.row.kusaicai_liuhe_status.is_liuhe == 1"> {{ scope.row.kuaicai_balance * 1 + scope.row.balance * 1 }}</template>
                                        <template v-if="scope.row.kusaicai_liuhe_status.is_kuaicai == 1 && scope.row.kusaicai_liuhe_status.is_liuhe == 0"> {{ scope.row.kuaicai_balance }}</template>
                                        <template v-if="scope.row.kusaicai_liuhe_status.is_kuaicai == 0 && scope.row.kusaicai_liuhe_status.is_liuhe == 1"> {{ scope.row.balance * 1 }}</template>
                                    </span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column property="today_cash_in" label="今日充值" align="center" :min-width="width.today_cash_in.minwidth">
                            <template #header>
                                <div class="theme-text">今日充值</div>
                            </template>
                            <template #default="scope">
                                <el-tooltip placement="top-start" effect="light">
                                    <template #content>
                                        <div v-if="scope.row.kusaicai_liuhe_status.is_kuaicai == 1 || scope.row.kuaicai_today_cash_in > 0">
                                            <span>快彩：</span>
                                            <span>{{ scope.row.kuaicai_today_cash_in }}</span>
                                        </div>
                                        <div v-if="scope.row.kusaicai_liuhe_status.is_liuhe == 1 || scope.row.today_cash_in > 0">
                                            <span>六合：</span>
                                            <span>{{ scope.row.today_cash_in }}</span>
                                        </div>
                                    </template>
                                    <span>{{ scope.row.kuaicai_today_cash_in * 1 + scope.row.today_cash_in * 1 }}</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column property="today_cash_out" label="今日提现" align="center" :min-width="width.today_cash_out.minwidth">
                            <template #header>
                                <div class="theme-text">今日提现</div>
                            </template>
                            <template #default="scope">
                                <el-tooltip placement="top-start" effect="light">
                                    <template #content>
                                        <div v-if="scope.row.kusaicai_liuhe_status.is_kuaicai == 1 || scope.row.kuaicai_today_cash_out > 0">
                                            <span>快彩：</span>
                                            <span>{{ scope.row.kuaicai_today_cash_out }}</span>
                                        </div>
                                        <div v-if="scope.row.kusaicai_liuhe_status.is_liuhe == 1 || scope.row.today_cash_out > 0">
                                            <span>六合：</span>
                                            <span>{{ scope.row.today_cash_out }}</span>
                                        </div>
                                    </template>
                                    <span>{{ scope.row.kuaicai_today_cash_out * 1 + scope.row.today_cash_out * 1 }}</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column property="posttime" label="新增日期" align="center" min-width="60">
                            <template #header>
                                <span class="theme-text">新增日期</span>
                            </template>
                            <template #default="scope">{{ $filters.time(scope.row.posttime, "month_day") }}</template>
                        </el-table-column>
                        <el-table-column property="level_id" label="盘口" align="center" min-width="35">
                            <!-- 0不限，123,-abc-->
                            <template #default="scope">
                                <el-tooltip placement="top-start" effect="light">
                                    <template #content>
                                        <div v-if="scope.row.kusaicai_liuhe_status.is_kuaicai == 1">
                                            <span>快彩：</span>
                                            <span v-show="scope.row.kuaicai_level_id == 0">不限</span>
                                            <span v-show="scope.row.kuaicai_level_id.indexOf('1') != -1">A</span>
                                            <span v-show="scope.row.kuaicai_level_id.indexOf('2') != -1">B</span>
                                            <span v-show="scope.row.kuaicai_level_id.indexOf('3') != -1">C</span>
                                        </div>
                                        <div v-if="scope.row.kusaicai_liuhe_status.is_liuhe == 1">
                                            <span>六合：</span>
                                            <span v-show="scope.row.level_id == 0">不限</span>
                                            <span v-show="scope.row.level_id.indexOf('1') != -1">A</span>
                                            <span v-show="scope.row.level_id.indexOf('2') != -1">B</span>
                                            <span v-show="scope.row.level_id.indexOf('3') != -1">C</span>
                                        </div>
                                    </template>
                                    <div>
                                        <template v-if="scope.row.kusaicai_liuhe_status.is_kuaicai == 1 && scope.row.kusaicai_liuhe_status.is_liuhe == 0">
                                            <span v-show="scope.row.kuaicai_level_id == 0">不限</span>
                                            <span v-show="scope.row.kuaicai_level_id.indexOf('1') != -1">A</span>
                                            <span v-show="scope.row.kuaicai_level_id.indexOf('2') != -1">B</span>
                                            <span v-show="scope.row.kuaicai_level_id.indexOf('3') != -1">C</span>
                                        </template>
                                        <template v-if="scope.row.kusaicai_liuhe_status.is_liuhe == 1 && scope.row.kusaicai_liuhe_status.is_kuaicai == 0">
                                            <span v-show="scope.row.level_id == 0">不限</span>
                                            <span v-show="scope.row.level_id.indexOf('1') != -1">A</span>
                                            <span v-show="scope.row.level_id.indexOf('2') != -1">B</span>
                                            <span v-show="scope.row.level_id.indexOf('3') != -1">C</span>
                                        </template>
                                        <template v-if="scope.row.kusaicai_liuhe_status.is_liuhe == 1 && scope.row.kusaicai_liuhe_status.is_kuaicai == 1">
                                            <span style="color: #409eff">查看</span>
                                        </template>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column property label="补货" align="center" min-width="35">
                            <template #default="scope">
                                <el-link v-show="scope.row.autolet_status == '1'" type="success" icon="Search" @click.prevent="open_buhuo(scope.row)">补</el-link>
                                <span v-show="!scope.row.autolet_status == '1'">-</span>
                            </template>
                        </el-table-column>
                        <el-table-column property="status" label="状态" align="center" min-width="50">
                            <template #default="scope">
                                <!-- 是直属会员才有功能 -->
                                <div v-show="$store.state.userinfo.transfer_status_agent == 1">
                                    <a class="status-btn" v-show="scope.row.status == 1" @click="change_status(scope.row)">启用</a>
                                    <a class="status-btn" v-show="scope.row.status == 0" @click="change_status(scope.row)">停用</a>
                                    <a class="status-btn" v-show="scope.row.status == 2" @click="change_status(scope.row)">冻结</a>
                                </div>
                                <div v-show="!$store.state.userinfo.transfer_status_agent == 1">
                                    <span v-show="scope.row.status == 1" style="color: #67c23a">启用</span>
                                    <span v-show="scope.row.status == 0" style="color: #f56c6c">停用</span>
                                    <span v-show="scope.row.status == 2" style="color: #909399">冻结</span>
                                </div>
                            </template>
                        </el-table-column>
                        <!-- 日志 -->
                        <el-table-column property label="日志" align="center" min-width="100">
                            <template #default="scope">
                                <a class="a-icon i-rizhi theme-text" href="#" @click.prevent="open_loginLog(scope.row)">日志</a>
                                <a class="a-icon i-changeLog theme-text" style="margin-right: 0" href="#" @click.prevent="open_changeLog(scope.row)">修改</a>
                            </template>
                        </el-table-column>
                        <!-- 资金 -->
                        <el-table-column v-show="$store.state.userinfo.money_power == 1" property="real_name" label="资金" align="center" min-width="160">
                            <template #default="scope">
                                <!-- 是直属会员才有功能 -->
                                <span v-show="$store.state.userinfo.transfer_status_agent == 1">
                                    <a class="a-icon i-topUp theme-text" href="#" @click.prevent="open_topUp(scope.row)">充值</a>
                                    <a class="a-icon i-cashOut theme-text" href="#" @click.prevent="open_cashOut(scope.row)">提现</a>
                                </span>
                                <a class="a-icon i-cashFlow theme-text" style="margin-right: 0" href="#" @click.prevent="open_cashFlow(scope.row)">现金流</a>
                            </template>
                        </el-table-column>
                        <!-- 操作 -->
                        <el-table-column label="操作" align="center" min-width="110">
                            <template #default="scope">
                                <a class="a-icon i-tuishui theme-text" href="#" @click.prevent="open_tuishui(scope.row)">退水</a>
                                <a class="a-icon i-Edit theme-text" href="#" @click.prevent="edit_user(scope.row)">修改</a>
                                <template v-if="axios.defaults.baseURL === '/' || axios.defaults.baseURL === 'https://api_admin.1688center.com' || axios.defaults.baseURL === 'http://adm.dist.com'">
                                    <a class="a-icon i-three theme-text" href="#" v-show="$store.state.userinfo.trader_diff_user == 1 && scope.row.trader_diff.status == 1" @click.prevent="open_zhuanpei(scope.row)">赚赔</a>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div>
                        <el-pagination
                            class="paging"
                            hide-on-single-page
                            @current-change="change_page"
                            small
                            v-model="pagination.page"
                            :page-size="post_agency.limit"
                            :pager-count="5"
                            :total="pagination.total_records * 1"
                            layout=" total,prev, pager, next, jumper,"
                        />
                    </div>
                </div>
                <template v-if="other_view.visible">
                    <component :ref="other_view.activated_name" :is="other_view.activated_name" :userinfo="other_view.row" :addinfo="addinfo" :add_user_data="add_user_data" :trader_diff="trader_diff" @other_view_back="other_view_back"></component>
                </template>
            </div>
        </div>
        <!-- 修改状态 -->
        <el-dialog v-model="change_user_status.visible" width="260px" class="mydialogclass">
            <template #header>
                <span class="mydialogtitle">{{ `修改 ${row_user.username} 状态` }}</span>
            </template>
            <el-radio-group v-model="change_user_status.status">
                <el-radio label="1">启用</el-radio>
                <el-radio label="2">冻结</el-radio>
                <el-radio label="0">停用</el-radio>
            </el-radio-group>
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="small" @click="cancel_change_status">取消</el-button>
                    <el-button size="small" type="primary" @click="change_status(row_user, true)">确定修改</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import axios from "../../../axios/axios";
import loginLog from "../plublic/loginLog.vue";
import changeLog from "../plublic/changeLog.vue";
import topUp from "../plublic/topUp.vue";
import cashOut from "../plublic/cashOut.vue";
import cashFlow from "../plublic/cashFlow.vue";
import tuishui from "../plublic/tuishui.vue";
import buhuo from "../plublic/buhuo.vue";
import adddaili from "../adduser/addDaili.vue";
import editDaili from "../edituser/editDaili.vue";
import zhuanpei from "../plublic/zhuanpei.vue";
export default {
    components: { loginLog, changeLog, topUp, cashOut, cashFlow, tuishui, buhuo, adddaili, editDaili, zhuanpei },
    data() {
        return {
            users: [],
            title: "代理管理",
            panelwidth: "100%",
            amounts: [0, 0, 0],
            post_agency: {
                type: 8,
                page: 1, //页码
                userState: 1, //状态
                sortName: "0", //排序类型：时间0 账号1
                userFlag: "0", //搜索类型：账号0 昵称1
                sortType: "0", //排序顺序：降0 升1
                zstype: "", //所属会员，zgs直属会员，fgs普通会员
                searchkey: "", //搜索时输入的内容
                limit: 30,
                uid: "",
                start_time: "",
                end_time: "",
                zc_time: "",
                filter: [
                    { value: 1, label: "启用" },
                    { value: 3, label: "全部" },
                    { value: 2, label: "冻结" },
                    { value: 0, label: "停用" },
                ],
            },
            pagination: {
                total_records: 0,
                limit: 30,
                page: 1,
            },
            // 修改状态
            change_user_status: {
                visible: false,
                status: "",
            },
            //选中的用户信息
            row_user: {
                id: "",
                username: "",
                status: "",
            },
            other_view: {
                activated_name: "",
                visible: false,
                row: "",
            },
            add_user_data: {},
            addinfo: {
                sup_group_name: "总代理",
                sub_group_name: "代理",
                sub_list: [],
            },
            trader_diff: { status: "", diff: "" }, //赚赔
            width: {
                parent_name: {
                    minwidth: "",
                    label: "上级总代理",
                },
                username: {
                    minwidth: "",
                    label: "代理",
                },
                nickname: {
                    minwidth: "",
                    label: "名称",
                },
                balance: {
                    minwidth: "",
                    label: "彩票余额",
                },
                today_cash_in: {
                    minwidth: "",
                    label: "今日充值",
                },
                today_cash_out: {
                    minwidth: "",
                    label: "今日提现",
                },
            },
        };
    },
    beforeRouteEnter(to, from, next) {
        axios
            .post("user_version2/member_list", {
                type: 8,
                page: 1, //页码
                userState: 1, //状态
                sortName: "0", //排序类型：时间0 账号1
                userFlag: "0", //搜索类型：账号0 昵称1
                sortType: "0", //排序顺序：降0 升1
                zstype: "", //所属会员，zgs直属会员，fgs普通会员
                searchkey: "", //搜索时输入的内容
                limit: 30,
                uid: to.query.uid,
                start_time: "",
                end_time: "",
                zc_time: "",
            })
            .then((result) => {
                if (result.data.status == 200) {
                    next((vm) => {
                        vm.width = vm.$filters.stringlength(result.data.repsoneContent.list, vm.width);
                        vm.users = result.data.repsoneContent.list;
                        vm.pagination = result.data.repsoneContent.pagination;
                        vm.amounts = result.data.repsoneContent.amounts;
                        if (to.query.uid) {
                            vm.post_agency.uid = to.query.uid;
                        }
                    });
                }
            });
    },
    computed: {
        relod_sub_menu_data() {
            return this.$store.state.relod_sub_menu_data;
        },
    },
    watch: {
        relod_sub_menu_data(newVal, oldVal) {
            // console.log(newVal, oldVal);
            if (newVal) {
                this.get_users();
                this.$store.state.relod_sub_menu_data = false;
            }
        },
    },
    methods: {
        //跳转下级
        go_user_subview(row, type, num) {
            // console.log(row, type, num);
            if (num > 0) {
                this.$emit("change_submenu", type, row.id);
            }
        },
        //修改
        edit_user(row) {
            this.other_view.row = row;
            this.other_view.row.sup_group_name = "总代理";
            this.other_view.row.sub_group_name = "代理";
            this.other_view.row.groupid_zs = "7";
            this.axios
                .post("user_version2/member_add", {
                    groupid_zs: row.groupid_zs,
                    sltuid: "",
                })
                .then((result) => {
                    if (result.data.status === 200) {
                        this.add_user_data = result.data.repsoneContent;
                        this.axios
                            .post("user_version2/member_edit", {
                                uid: row.id,
                                par_uid: row.agent_id,
                                groupid: row.group_id,
                                userState: row.status,
                            })
                            .then((result) => {
                                if (result.data.status == 200) {
                                    this.other_view.activated_name = "editDaili";
                                    this.title = "修改代理";
                                    this.panelwidth = "50%";
                                    this.other_view.visible = true;
                                    this.$nextTick(() => {
                                        this.$refs["editDaili"].get_user_edit(result.data.repsoneContent);
                                    });
                                }
                            });
                    }
                });
        },
        //新增
        add_user() {
            this.other_view.row = {
                sup_group_name: "总代理",
                sub_group_name: "代理",
                groupid_zs: "7",
            };
            this.axios
                .post("user_version2/member_add", {
                    groupid_zs: 7,
                    sltuid: "",
                })
                .then((result) => {
                    if (result.data.status === 200) {
                        this.addinfo.sub_list = result.data.repsoneContent.member_list;
                        this.axios
                            .post("user_version2/member_add", {
                                sltuid: this.addinfo.sub_list[0].id,
                                groupid_zs: "",
                            })
                            .then((result) => {
                                if (result.data.status === 200) {
                                    this.other_view.activated_name = "adddaili";
                                    this.title = "新增代理";
                                    this.panelwidth = "50%";
                                    this.other_view.visible = true;
                                    this.$nextTick(() => {
                                        this.$refs["adddaili"].get_user_edit(result.data.repsoneContent);
                                    });
                                }
                            });
                    }
                });
        },
        //赚赔
        open_zhuanpei(row) {
            this.trader_diff.diff = row.trader_diff.diff.split(",");
            this.trader_diff.diff[0] = Number(this.trader_diff.diff[0]);
            this.trader_diff.diff[1] = Number(this.trader_diff.diff[1]);
            this.other_view.row = row;
            this.axios
                .post("play/member_agent_set", {
                    pid: "",
                    user_id: row.id,
                    gid: this.$store.state.game_list[0].id,
                    gids: [20, 48],
                })
                .then((result) => {
                    // console.log(result.data.repsoneContent)
                    if (result.data.status == 200) {
                        this.other_view.activated_name = "zhuanpei";
                        this.title = `赚取赔率差(总代理：${row.username})`;
                        this.panelwidth = "100%";
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["zhuanpei"].get_data(result.data.repsoneContent, row.id);
                        });
                    }
                });
        },
        //打开补货
        open_buhuo(row) {
            this.other_view.row = row;
            this.other_view.activated_name = "buhuo";
            this.title = "补货";
            this.panelwidth = "100%";
            let game_list = this.$store.state.game_list;
            if (game_list.length > 0) {
                let game_id = game_list[0].id;
                this.axios
                    .post("user/viewAutoLet", {
                        uid: row.id,
                        gid: game_id,
                    })
                    .then((result) => {
                        if (result.data.status == 200) {
                            // console.log(result.data.repsoneContent)
                            this.other_view.visible = true;
                            this.$nextTick(() => {
                                this.$refs["buhuo"].get_buhuo_data(result.data.repsoneContent);
                            });
                        }
                    });
            }
        },
        //打开退水设置
        open_tuishui(row) {
            this.other_view.row = row;
            this.other_view.activated_name = "tuishui";
            this.title = "退水设置";
            this.panelwidth = "100%";
            let gids = [];
            let all_gids = [];
            this.$store.state.game_list.forEach((item) => {
                gids.push(item.id);
                all_gids.push(item.id);
            });
            gids.length = 4;
            this.axios
                .post(`user_version2/tuishui?uid=${row.id}`, {
                    uid: row.id,
                    gids,
                    all_gids,
                    play_ids: "",
                    times: 1,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["tuishui"].get_data(result.data.repsoneContent);
                        });
                    }
                });
        },
        //打开用户现金流
        open_cashFlow(row) {
            this.other_view.row = row;
            this.other_view.activated_name = "cashFlow";
            this.title = "现金流";
            this.panelwidth = "100%";
            this.axios
                .post("account/credit_flow_list", {
                    uid: row.id,
                    page: 1,
                    limit: 20,
                    username: row.username,
                    flag: "",
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["cashFlow"].get_data(result.data.repsoneContent);
                        });
                    }
                });
        },
        //打开用户提现
        open_cashOut(row) {
            this.other_view.row = row;
            this.other_view.activated_name = "cashOut";
            this.title = "提现";
            this.panelwidth = "30%";
            this.other_view.visible = true;
        },
        //打开用户充值
        open_topUp(row) {
            this.other_view.row = row;
            this.other_view.activated_name = "topUp";
            this.title = "充值";
            this.panelwidth = "30%";
            this.other_view.visible = true;
        },
        //打开用户修改记录
        open_changeLog(row) {
            this.other_view.row = row;
            this.other_view.activated_name = "changeLog";
            this.title = "修改记录";
            this.panelwidth = "100%";
            this.axios
                .post("log/admin_log", {
                    uid: row.id,
                    page: 1,
                    limit: 20,
                    username: row.username,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["changeLog"].get_data(result.data.repsoneContent);
                        });
                    }
                });
        },
        //打开用户登陆日志
        open_loginLog(row) {
            // console.log(row);
            this.other_view.row = row;
            this.other_view.activated_name = "loginLog";
            this.title = "登陆日志";
            this.panelwidth = "100%";
            this.axios
                .post("log/admin_login_log", {
                    uid: row.id,
                    page: 1,
                    limit: 10,
                })
                .then((result) => {
                    if (result.data.status == 200) {
                        // console.log(result.data.repsoneContent)
                        this.other_view.visible = true;
                        this.$nextTick(() => {
                            this.$refs["loginLog"].get_data(result.data.repsoneContent);
                        });
                    }
                });
        },
        //取消修改用户状态
        cancel_change_status() {
            this.change_user_status.visible = false;
            this.get_users();
        },
        //修改用户状态
        change_status(row, submit = false) {
            //修改会员状态
            if (!submit) {
                this.row_user = row;
                this.change_user_status.status = row.status;
                this.change_user_status.visible = true;
            } else {
                this.axios.post("user_version2/member_status", { status: this.change_user_status.status, uid: this.row_user.id }).then((result) => {
                    if (result.data.status == 200) {
                        this.$message({
                            message: result.data.shortMessage,
                            type: "success",
                        });
                        this.change_user_status.visible = false;
                        //刷新列表
                        this.get_users(this.link);
                    }
                });
            }
        },
        //股东用户list分页查询
        change_page(page) {
            this.post_agency.page = page;
            this.get_users();
        },
        //获取股东用户list
        get_users() {
            this.axios.post("user_version2/member_list", this.post_agency).then((result) => {
                if (result.data.status == 200) {
                    this.users = result.data.repsoneContent.list;
                    this.pagination = result.data.repsoneContent.pagination;
                    this.amounts = result.data.repsoneContent.amounts;
                    this.other_view.visible = false;
                    this.panelwidth = "100%";
                }
            });
        },
        //其他页面返回
        other_view_back() {
            this.title = "代理管理";
            this.get_users();
        },
    },
};
</script>