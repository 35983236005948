import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-5e9d3736"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "cash-flow"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  const _component_el_pagination = _resolveComponent("el-pagination");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $data.cash_flow_data,
    border: "",
    size: "mini",
    "highlight-current-row": "",
    "header-cell-class-name": "table-header-cell",
    "header-row-class-name": "table-header-row",
    "table-layout": "auto"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      property: "id",
      label: "编号",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "posttime",
      label: "时间",
      align: "center"
    }, {
      default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.$filters.time(scope.row.posttime)), 1)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      property: "from_id",
      label: "类型",
      align: "center"
    }, {
      default: _withCtx(scope => [scope.row ? (_openBlock(), _createElementBlock("span", _hoisted_2, "[彩票]")) : _createCommentVNode("", true)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      property: "item_type",
      label: "类别",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "balance",
      label: "金额",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "pre_balance",
      label: "变更前金额",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "amount",
      label: "变更后金额",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "remark",
      label: "备注",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "aname",
      label: "操作人",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "ip",
      label: "操作ip",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "ip_address",
      label: "操作地址",
      align: "center"
    })]),
    _: 1
  }, 8, ["data"]), _createVNode(_component_el_pagination, {
    class: "paging",
    small: "",
    onCurrentChange: $options.change_page,
    modelValue: $data.page,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.page = $event),
    "page-size": 20,
    "pager-count": 5,
    total: $data.pagination.total_records * 1,
    layout: "total,prev,pager,next,jumper",
    "prev-text": "上一页",
    "next-text": "下一页",
    "hide-on-single-page": ""
  }, null, 8, ["onCurrentChange", "modelValue", "total"])]);
}