import axios from '../../../axios/axios';
export default {
  data() {
    return {
      game_list: [],
      play_arr: [],
      gid: '',
      pid: '',
      list: [],
      formula_mode: [//计算方式
      {
        id: "1",
        name: "下注额"
      }, {
        id: "2",
        name: "下注额单组计"
      }],
      group_id: '',
      is_occupy_status: '',
      occupy_catch: '1',
      //是否拦截下级补货
      fastset: {
        control_let: '',
        display: '0'
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    console.time();
    axios.post("user_version2/autoLet", {
      type: 0,
      gid: to.query.gid,
      pid: ''
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.$forceUpdate();
          vm.game_list = vm.$store.state.game_list;
          vm.list = result.data.repsoneContent.list;
          vm.gid = to.query.gid; //保存旧值

          for (let i = 0; i < vm.list.length; i++) {
            vm.list[i].old_value = vm.list[i].control_let;
            vm.list[i].old_display = vm.list[i].display;
          }

          let play_arr = result.data.repsoneContent.play_arr;

          for (const key in play_arr) {
            play_arr[key].pid = key; // play_arr.push(play_arr[key]);
          }

          vm.play_arr = play_arr;
          console.timeEnd();
        });
      }
    });
  },

  methods: {
    change_fast_control_let() {
      if (Number.isNaN(this.fastset.control_let * 1) || this.fastset.control_let * 1 < 0) {
        return this.$message.error('请填写正确的数值');
      }

      this.list.forEach(i => i.control_let = this.fastset.control_let);
    },

    change_fast_display(v) {
      this.list.forEach(i => i.display = v);
    },

    //提交修改是否拦截下级补货
    change_occupy_catch() {
      this.axios.post("user_version2/member_save", {
        occupy_catch: this.occupy_catch,
        all_agents: 1 //固定为1

      }).then(result => {
        if (result.data.status == 200) {
          this.$message({
            message: result.data.shortMessage,
            type: 'success',
            offset: 400
          });
        }
      });
    },

    sub() {
      let sub_list = [];
      let cancle_id = []; //判断是否进行过修改，并保存修改的数据

      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].old_display == "0" && this.list[i].display == "1" || this.list[i].old_value != this.list[i].control_let) {
          sub_list.push(this.list[i]);
        }

        if (this.list[i].old_display == "1" && this.list[i].display == "0") {
          cancle_id.push(this.list[i].p_key);
        }

        if (this.list[i].display == "1" && this.list[i].control_let * 1 <= 0) {
          return this.$message.error(`${this.list[i].name}的控制额度不能为0或空`);
        }
      }

      if (sub_list.length == 0 && cancle_id.length == 0) {
        return this.$message(`您未进行任何修改哦`);
      }

      if (cancle_id.length) {
        cancle_id += "";
      } //提交后台验证


      this.axios.post("user_version2/autoLet", {
        type: 1,
        gid: this.gid,
        pid: this.pid,
        cancle_id: cancle_id,
        update_autolet: sub_list
      }).then(result => {
        if (result.data.status == 200) {
          this.$message({
            message: result.data.shortMessage,
            type: 'success',
            offset: 500
          });
          this.get_auto_set_menu(this.gid, this.pid);
        }
      });
    },

    //切换分类
    change_group(pid) {
      this.pid = pid;
      this.gid = '';
      this.get_auto_set_menu('', pid); // console.log(`gid: ${this.gid}--pid:${this.pid}`);
    },

    //切换彩种
    change_game(gid) {
      this.gid = gid;
      this.pid = '';
      this.get_auto_set_menu(gid, ''); // console.log(`gid: ${this.gid}--pid:${this.pid}`);
    },

    get_auto_set_menu(gid = '', pid = '') {
      this.axios.post("user_version2/autoLet", {
        type: 0,
        gid,
        pid
      }).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.list = result.data.repsoneContent.list; //保存旧值

          for (let i = 0; i < this.list.length; i++) {
            this.list[i].old_value = this.list[i].control_let;
            this.list[i].old_display = this.list[i].display;
          }

          if (this.play_arr.length < 1) {
            let play_arr = result.data.repsoneContent.play_arr;

            for (const key in play_arr) {
              play_arr[key].pid = key;
              this.play_arr.push(play_arr[key]);
            }
          }
        }
      }); //获取是否拦截下级补货
      // this.axios.post("user/member_add", { groupid: this.$store.state.userinfo.group_id, }
      // ).then((result) => {
      //     if (result.data.status === 200) {
      //         // console.log(result.data.repsoneContent)
      //         this.group_id = result.data.repsoneContent.group_id;
      //         this.is_occupy_status = result.data.repsoneContent.is_occupy_status;
      //         this.occupy_catch = result.data.repsoneContent.occupy_catch;
      //     }
      // });
    }

  }
};