export default {
  data() {
    return {
      uname: "",
      //名称
      name: '',
      pwd: '',

      /**权限 */
      menu: [],
      err: {
        name: {
          visible: false,
          content: ''
        },
        uname: {
          visible: false,
          content: ''
        },
        pwd: {
          visible: false,
          content: ''
        }
      }
    };
  },

  methods: {
    //分组全选
    change_item_fz(item, status) {
      // console.log(item, status);
      item.mk.forEach(mk => {
        mk.info.is_select = status;

        if (mk.ff.length > 0) {
          mk.ff.forEach(ff => {
            ff.info.is_select = status;
          });
        }
      });
    },

    /**获取新增用户菜单选项，权限 */
    get_user_edit(data) {
      let res = data.powers; // console.log(res);

      this.menu = []; //生成分组

      for (let i = 0; i < res.length; i++) {
        res[i].is_select = false;

        if (res[i].class == 1) {
          this.menu.push({
            name: res[i].function,
            info: res[i],
            mk: []
          });
        }
      } //生成分组对应的模块
      //提取大股东排序


      if (res.find(item => item.function == '大股东') != undefined) {
        let obj = res.splice(res.findIndex(item => item.function == '大股东'), 1)[0];
        res.splice(res.findIndex(item => item.function == '股东'), 0, obj);
      }

      for (let i = 0; i < res.length; i++) {
        if (res[i].class == 2) {
          for (let j = 0; j < this.menu.length; j++) {
            if (res[i].parent_id == this.menu[j].info.id) {
              if (res[i].function == '大总监') {
                this.menu[j].mk.unshift({
                  name: res[i].function,
                  info: res[i],
                  ff: []
                });
              } else {
                this.menu[j].mk.push({
                  name: res[i].function,
                  info: res[i],
                  ff: []
                });
              }
            }
          }
        }
      } //生成模块对应的方法


      for (let i = 0; i < res.length; i++) {
        if (res[i].class == 3) {
          for (let j = 0; j < this.menu.length; j++) {
            for (let k = 0; k < this.menu[j].mk.length; k++) {
              if (res[i].parent_id == this.menu[j].mk[k].info.id) {
                this.menu[j].mk[k].ff.push({
                  name: res[i].function,
                  info: res[i]
                });
              }
            }
          }
        }
      } // console.log(this.menu);

    },

    /**提交修改 */
    post_change() {
      if (this.name.length < 2) {
        return this.err.name = {
          visible: true,
          content: '账号最少2个字符'
        };
      } // if (this.uname.length < 1) {
      //     return this.err.uname = { visible: true, content: '名称最少1个字符', };
      // }


      if (!/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,20}$/.test(this.pwd)) {
        return this.err.pwd = {
          visible: true,
          content: '密码为8-20位，大小写字母和数字组合'
        };
      }

      let list = [];
      this.menu.forEach(fz => {
        if (fz.info.is_select) {
          list.push(fz.info.id);
        }

        fz.mk.forEach(mk => {
          if (mk.info.is_select) {
            list.push(fz.info.id);
            list.push(mk.info.id);
          }

          mk.ff.forEach(ff => {
            if (ff.info.is_select) {
              list.push(fz.info.id);
              list.push(ff.info.id);
            }
          });
        });
      });
      list = Array.from(new Set(list));
      list = list.toString(); // return console.log(list)

      this.axios.post("user_version2/user_save", {
        userName: this.name,
        userPassword: this.pwd,
        userNicker: this.uname,
        checkbox: list
      }).then(result => {
        // console.log(result.data)
        if (result.data.status == "200") {
          this.$message.success(result.data.shortMessage);
        }

        this.finish_cancel();
      });
    },

    /**修改成功和取消修改，上报父级 */
    finish_cancel() {
      this.$emit("other_view_back", "");
    }

  }
};