import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-355a0e6e"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "ssc"
};
const _hoisted_2 = {
  style: {
    "text-align": "center"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, {
    data: $props.history_data,
    border: "",
    size: "small",
    "highlight-current-row": "",
    "header-cell-class-name": "table-header-cell",
    "header-row-class-name": "table-header-row",
    "table-layout": "auto",
    "row-key": "id",
    "expand-row-keys": $data.expands,
    onRowClick: $options.clickRowHandle
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      property: "qnum",
      label: "期号",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "qnum",
      label: "开奖日期",
      align: "center"
    }, {
      default: _withCtx(scope => [_createElementVNode("span", null, _toDisplayString(_ctx.$filters.time(scope.row.starttime)), 1)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      property: "res",
      label: "开奖号码",
      align: "center"
    }, {
      default: _withCtx(scope => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.res, item => {
        return _openBlock(), _createElementBlock("span", {
          class: "cqssc_nums",
          style: _normalizeStyle({
            'background-color': $props.gid == 4 || $props.gid == 10 ? 'red' : '#409eff'
          }),
          key: item.index
        }, _toDisplayString(item), 5);
      }), 128))]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      property: "sum",
      label: "总和",
      align: "center"
    }), _createVNode(_component_el_table_column, {
      property: "sum_big",
      label: "大小",
      align: "center"
    }, {
      default: _withCtx(scope => [_createElementVNode("span", {
        style: _normalizeStyle({
          color: scope.row.sum_big == '大' ? 'red' : '#409EFF'
        })
      }, _toDisplayString(scope.row.sum_big), 5)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      property: "sum_single",
      label: "单双",
      align: "center"
    }, {
      default: _withCtx(scope => [_createElementVNode("span", {
        style: _normalizeStyle({
          color: scope.row.sum_single == '雙' ? 'red' : '#409EFF'
        })
      }, _toDisplayString(scope.row.sum_single), 5)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      property: "dragon_tiger",
      label: "龙虎",
      align: "center"
    }, {
      default: _withCtx(scope => [_createElementVNode("span", {
        style: _normalizeStyle({
          color: scope.row.dragon_tiger == '龙' ? 'red' : scope.row.dragon_tiger == '和' ? 'green' : '#409EFF'
        })
      }, _toDisplayString(scope.row.dragon_tiger), 5)]),
      _: 1
    }), _createVNode(_component_el_table_column, {
      property: "front",
      label: "前三",
      align: "center"
    }), _withDirectives(_createVNode(_component_el_table_column, {
      property: "in",
      label: "中三",
      align: "center"
    }, null, 512), [[_vShow, $props.gid != 4]]), _withDirectives(_createVNode(_component_el_table_column, {
      property: "back",
      label: "后三",
      align: "center"
    }, null, 512), [[_vShow, $props.gid != 4]]), _createVNode(_component_el_table_column, {
      property: "res_info[0]",
      align: "center",
      width: "1",
      type: "expand"
    }, {
      default: _withCtx(scope => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.djm, item => {
        return _openBlock(), _createElementBlock("span", {
          key: item.index,
          style: _normalizeStyle({
            color: item.color
          })
        }, _toDisplayString(item.text), 5);
      }), 128))])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data", "expand-row-keys", "onRowClick"])]);
}