export default {
  data() {
    return {
      ball_list: [],
      gid: "",
      pid: "",
      play_str: '',
      fast: {
        low_rate: "",
        b_rate: "",
        c_rate: "",
        added: "",
        lower: ""
      },
      obj: {},
      user_id: ''
    };
  },

  methods: {
    //重置
    reset() {
      this.get_ball_set(this.obj);

      for (const key in this.fast) {
        this.fast[key] = "";
      }
    },

    //快速设置某一列
    fast_set_col() {
      for (const key in this.fast) {
        if (this.fast[key]) {
          for (let i = 0; i < this.ball_list.length; i++) {
            this.ball_list[i][key] = this.fast[key];
          }
        }
      }
    },

    //提交赔率设置
    sub_set() {
      let post_data = {
        oid: [],
        ball: [],
        place: [],
        low_rate: [],
        b_rate: [],
        c_rate: [],
        added: [],
        lower: [],
        play_str: [],
        play_rate: []
      };

      if (this.user_id != '') {
        post_data.user_id = this.user_id;
      }

      let url = "play/auto_ball_rate_exe";

      if (this.gid) {
        post_data.gid = this.gid;
      } else {
        post_data.pid = this.pid;
      }

      for (let item of this.ball_list) {
        if (Number.isNaN(item.play_rate * 1) || Number.isNaN(item.high_rate * 1) || Number.isNaN(item.low_rate * 1) || Number.isNaN(item.b_rate * 1) || Number.isNaN(item.c_rate * 1) || Number.isNaN(item.lower * 1) || Number.isNaN(item.added * 1)) {
          this.$message({
            message: '请输入正确的数值！',
            type: 'error',
            offset: 400
          });
          return;
        } else if (item.play_rate * 1 > item.high_rate * 1 || item.play_rate * 1 < item.low_rate * 1) {
          return this.$message.error(item.ball + '赔率设定不能超过上限或者低于下限');
        } else if (item.b_rate * 1 > item.high_b_rate * 1) {
          return this.$message.error(item.ball + '赔率设定不能超过上限');
        } else if (item.c_rate * 1 > item.high_c_rate * 1) {
          return this.$message.error(item.ball + '赔率设定不能超过上限');
        } else {
          post_data.oid.push(item.id);
          post_data.ball.push(item.ball);
          post_data.place.push(item.place);
          post_data.low_rate.push(item.low_rate);
          post_data.b_rate.push(item.b_rate);
          post_data.c_rate.push(item.c_rate);
          post_data.added.push(item.added);
          post_data.lower.push(item.lower);
          post_data.play_rate.push(item.play_rate);
          post_data.play_str.push(this.play_str);
        }
      }

      if (this.user_id != '') {
        post_data.user_id = this.user_id;
      }

      this.axios.post(url, post_data).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.$message({
            message: result.data.shortMessage,
            type: 'success',
            offset: 400
          });
        }
      });
    },

    get_ball_set(data = '', obj, user_id = '') {
      // console.log(user_id);
      this.obj = obj;
      this.play_str = obj.play_str;

      if (obj.gid) {
        this.gid = obj.gid;
        this.pid = "";
      } else {
        this.pid = obj.pid;
        this.gid = "";
      }

      if (user_id != '') {
        this.user_id = user_id;
        obj.user_id = user_id;
      } else {
        this.user_id = '';
      }

      if (data !== '') {
        this.ball_list = data;
      } else {
        this.axios.post("play/auto_ball_rate", obj).then(result => {
          if (result.data.status == 200) {
            // console.log(result.data.repsoneContent)
            this.ball_list = result.data.repsoneContent.list;
          }
        });
      }
    }

  }
};