import gameDetail from "./gameDetail";
import zhudanmingxi from "./zhudanmingxi.vue";
export default {
  components: {
    gameDetail,
    zhudanmingxi
  },
  props: ["form", "group_id", "bh_cs", "show_waibu", "search_title_info", "game_list"],

  data() {
    return {
      int_data: "",
      data_list: [],
      game_detail_data: {
        //彩种明细账
        open: false,
        // loading: false,
        activeNames: [],
        list: [],
        dialogDetailGameClassID: "",
        //明细账金额dialog gid
        text: "+ 显示彩种明细"
      },
      total: "",
      page: 1,
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      autolet: [{
        result: "0"
      }],
      //补货表
      dialog: {
        visible: "报表",
        detail_data: [],
        page: 1,
        pagination: {
          //分页信息
          total_pages: "",
          total_records: "",
          page_size: ""
        },
        row: {}
      },
      back_list: [],
      //返回上次查询操作用户id列
      username: "",
      isGetBuHuoDetail: false,
      bh: {
        //外补注额明细
        page: 1,
        pagination: {
          page_size: "",
          total_pages: "",
          total_records: ""
        },
        // row: "",
        data_list: [],
        show_wb: false //是否显示外补数据，只有一级代理显示

      },
      rowinfo: {},
      width: {
        real_name: {
          minwidth: "",
          label: "名称"
        },
        bet_count: {
          minwidth: "",
          label: "笔数"
        },
        sum_bet: {
          minwidth: "",
          label: "总投注额"
        },
        balance: {
          minwidth: "",
          label: "有效金额"
        },
        hy_shuyin: {
          minwidth: "",
          label: "会员输赢"
        },
        receive_subline: {
          minwidth: "",
          label: "应收下线"
        },
        occu_balance: {
          minwidth: "",
          label: "实占注额"
        },
        occu_winlose: {
          minwidth: "",
          label: "实占输赢"
        },
        occu_rebate: {
          minwidth: "",
          label: "实占退水"
        },
        real_occupy_result: {
          minwidth: "",
          label: "实占结果"
        },
        earn_rebate: {
          minwidth: "",
          label: "赚取退水"
        },
        real_result: {
          minwidth: "",
          label: "退水后结果"
        },
        cont_sup: {
          minwidth: "",
          label: "贡献上级"
        },
        receive_supline: {
          minwidth: "",
          label: "应付上级"
        }
      }
    };
  },

  created() {
    this.dialog.visible = "报表";
  },

  updated() {
    if (this.dialog.visible == "报表") {
      this.$refs.tableRef.doLayout();
    }
  },

  methods: {
    back_search() {
      if (this.back_list.length == 0) {
        this.total = "";
        this.game_detail_data.open = false;
        this.$emit("back_search");
      } else {
        this.get_back_list();
      }
    },

    //报表列的颜色
    cellClassName({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex != 0 && columnIndex != 1) {
        //合计列
        this.$nextTick(() => {
          let dom = this.$refs.tableRef.$refs.footerWrapper.querySelector(".el-table__footer").querySelectorAll("td")[columnIndex].querySelectorAll(".cell")[0];
          let val = this.$refs.tableRef.$refs.footerWrapper.querySelector(".el-table__footer").querySelectorAll("td")[columnIndex].querySelectorAll(".cell")[0].innerText;
          dom.style.color = !Number.isNaN(val) && val < 0 ? "red" : "#000";
        }); //数据列

        let columnProperty = this.data_list[rowIndex][column.property];

        if (columnProperty < 0) {
          return "redcolor"; // class名称
        } else {
          return "blackcolor"; // class名称
        }
      }
    },

    //报表合计
    baobiao_sum(param) {
      let {
        columns,
        data
      } = param;
      let sums = [];
      columns.forEach((column, index) => {
        // console.log(column.label,index);
        if (column.label == "级别") {
          return sums[index] = "";
        }

        if (column.label == "名称") {
          return sums[index] = "合计";
        } //笔数


        if (column.label == "笔数") {
          return sums[index] = this.total.bet_count;
        } //总投注额


        if (column.label == "总投注额") {
          return sums[index] = this.total.sum_bet;
        } //有效金额


        if (column.label == "有效金额") {
          return sums[index] = this.total.balance;
        } //会员输赢


        if (column.label == "会员输赢") {
          return sums[index] = this.total.bonus - this.total.balance;
        } //应收下线


        if (column.label == "应收下线") {
          return sums[index] = this.total.receive_subline;
        } //实占成数


        if (column.label == "实占成数") {
          return sums[index] = "";
        } //实占注额


        if (column.label == "实占注额") {
          return sums[index] = this.total.occu_balance;
        } //占货比


        if (column.label == "占货比") {
          return sums[index] = "";
        } //实占输赢


        if (column.label == "实占输赢") {
          return sums[index] = this.total.occu_winlose;
        } //实占退水


        if (column.label == "实占退水") {
          return sums[index] = this.total.occu_rebate;
        } //实占结果


        if (column.label == "实占结果") {
          return sums[index] = this.total.real_occupy_result;
        } //赚取退水


        if (column.label == "赚取退水") {
          return sums[index] = this.total.earn_rebate;
        } //赚取赔差


        if (column.label == "赚取赔差") {
          return sums[index] = this.total.own_eran;
        } //退水后结果


        if (column.label == "退水后结果") {
          return sums[index] = this.total.real_result;
        } //贡献上级


        if (column.label == "贡献上级") {
          return sums[index] = this.total.cont_sup;
        } //应付上级


        if (column.label == "应付上级") {
          return sums[index] = this.total.receive_supline;
        }
      });
      return sums;
    },

    //外补注额明细
    get_out_buhuo_detail(page = 1) {
      this.bh_cs.page = page;
      this.axios.post("userlet/getLetList", this.bh_cs).then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.bh.pagination = result.data.repsoneContent.pagination ? result.data.repsoneContent.pagination : {
            page_size: "",
            total_pages: "",
            total_records: ""
          };
          this.bh.page = page;
          this.bh.data_list = result.data.repsoneContent.list;
          this.dialog.visible = "外补注额明细";
        }
      });
    },

    //关闭注单明细
    close_balance() {
      this.dialog.visible = "报表";
      this.close_money_detail_dialog(this.dialog.row);
    },

    //补货详情
    get_buhuo_detail(fly = "", is_fly = "1") {
      this.isGetBuHuoDetail = true;
      this.search_detail(this.rowinfo, "", "", 1); // console.log(this.dialog.row);
    },

    //返回操作
    get_back_list() {
      this.back_list.pop();
      let row = this.back_list[this.back_list.length - 1];
      this.$emit("search_sub", this.back_list.length == 0 ? "" : row.user_id);
      this.game_detail_data.activeNames = [];
      this.game_detail_data.open = false; // this.game_detail_data.loading = false;

      this.game_detail_data.list = [];
      this.dialog.visible = "报表";
    },

    //关闭金额详细dialog
    close_money_detail_dialog() {
      this.dialog.page = 1;
      this.game_detail_data.dialogDetailGameClassID = "";
      this.isGetBuHuoDetail = false;
    },

    //会员查看详情
    search_detail(row, gameClassID = "", page = 1) {
      this.rowinfo = row; // console.log(row, gameClassID, this.form.gid);

      let obj = {
        gid: gameClassID === "" ? this.form.gids : this.gameClassID,
        //判断是总账还是明细账
        userid: row.id,
        page: 1,
        limit: 20,
        t_status: this.form.settle_state,
        BeginDate: this.form.date.start,
        EndDate: this.form.date.end,
        phaseid: this.form.qid,
        bet_count: this.isGetBuHuoDetail ? this.autolet[0].num : row.bet_count
      };

      if (this.isGetBuHuoDetail) {
        obj.is_manlet = 1;
        obj.is_fly = 1;
      }

      if (row.fly != undefined && row.fly != "") {
        obj.fly = row.fly;
      }

      if (row.is_fly != undefined && row.is_fly != "") {
        obj.is_fly = row.is_fly;
      }

      if (obj.fly == "1" || obj.is_fly == "1") {
        obj.userid = this.form.uid != "" && this.form.uid != undefined ? this.form.uid : "";
      } else {}

      this.axios.post("operate/ReportDetail_B_kc", obj).then(result => {
        if (result.data.status == 200) {
          this.dialog.visible = "注单明细";
          this.$nextTick(() => {
            this.$refs["zhudanmingxi"].proc_data(result.data.repsoneContent, this.isGetBuHuoDetail);
          });
        }
      });
    },

    // 点击代理查询下级
    search_sub(row) {
      this.back_list.push(row); //存入id,以便返回上次查询操作
      // console.log(this.back_list);

      this.$emit("search_sub", row.user_id);
      this.game_detail_data.activeNames = [];
      this.game_detail_data.list = [];
      this.game_detail_data.open = false;
      this.game_detail_data.text = "- 显示彩种明细";
      this.rowinfo = row;
    },

    change_page(page) {
      this.game_detail_data.activeNames = [];
      this.game_detail_data.list = [];
      this.$emit("change_page", page);
    },

    proc_data(data) {
      this.int_data = data;
      this.data_list = data.bet;
      this.width = this.$filters.stringlength(this.data_list, this.width, "weight", data.small); // console.log(this.width);

      this.data_list.forEach(item => {
        if (this.form.settle_state != 2) {
          item.hy_shuyin = Math.floor(item.bonus * 1 - item.balance * 1);
        } else {
          item.hy_shuyin = "";
        }
      });
      this.total = data.small;
      this.autolet = [];
      let autolet = data.autolet;
      autolet.result = autolet.let_balance + autolet.rebate_balance;
      this.autolet[0] = autolet;

      if (data.pagination) {
        this.pagination = data.pagination;
      }
    },

    get_game_detail_data() {
      if (this.game_detail_data.open) {
        this.game_detail_data.open = false;
        this.game_detail_data.text = "- 显示彩种明细";
      } else {
        this.game_detail_data.open = true;
        this.$emit("get_game_detail");
        this.game_detail_data.text = "- 隐藏彩种明细";
      }
    },

    proc_game_detail_data(data) {
      this.game_detail_data.list = data;
    },

    tableRowClassName_del_red_line({
      row,
      rowIndex
    }) {
      if (row.remark === "后台撤单") {
        return "tableRowClassName_del_red_line";
      }

      return "";
    }

  }
};