<!--
 * @Author: your name
 * @Date: 2022-05-02 23:40:21
 * @LastEditTime: 2023-09-18 19:16:38
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\instant\gameinfo.vue
-->
<template>
    <div class="gameinfo">
        <div class="theme-border-half" style="border-bottom: 1px solid; padding: 0px 0 8px 0">
            <div class="info-item" style="width: 350px">
                <div>
                    <span class="icon"></span>
                    <span style="font-weight: bold">{{ game_info_data.title }}</span>
                    <span>
                        【第
                        <span class="theme-text" style="font-weight: bold">{{ game_info_data.nn }}</span>
                        期】
                    </span>
                </div>
                <div style="margin-top: 10px">
                    <span style="margin-left: 24px">
                        <span>{{ time_text }}：</span>
                        <span class="redcolor" style="font-weight: bold">
                            <template v-if="time_text == '已封盘'">00 : 00 : 00</template>
                            <template v-else>{{ countDown_h }} : {{ countDown_m }} : {{ countDown_s }}</template>
                        </span>
                    </span>
                    <span style="margin-left: 24px">
                        <span>今天输赢：</span>
                        <span class="redcolor" style="font-weight: bold">{{ game_info_data.profit }}</span>
                    </span>
                </div>
            </div>
            <div class="info-item">
                <div v-show="game_info_data.upopenphase != null && game_info_data.upopenphase != '' && game_info_data.upopenphase.length > 0">
                    <div style="margin: 0 0 5px 0">
                        <span>
                            第
                            <span style="font-weight: bold; color: blue">{{ game_info_data.upopennumber_cs.length > 0 ? game_info_data.nn : game_info_data.upopenphase }}</span>
                            期结果：
                        </span>
                    </div>
                    <div>
                        <!-- 重庆时时彩 -->
                        <span
                            v-if="
                                game_info_data.gid == 1 || game_info_data.gid == 7 || game_info_data.gid == 18 || game_info_data.gid == 38 || game_info_data.gid == 39 || game_info_data.gid == 44 || game_info_data.gid == 45 || game_info_data.gid == 46
                            "
                        >
                            <span class="cqssc_nums" style="background-color: #409eff" v-for="item in game_info_data.upopennumber" :key="item.index">{{ item }}</span>
                        </span>
                        <!-- 北京PK10 -->
                        <span
                            v-if="
                                game_info_data.gid == 3 ||
                                game_info_data.gid == 19 ||
                                game_info_data.gid == 24 ||
                                game_info_data.gid == 28 ||
                                game_info_data.gid == 37 ||
                                game_info_data.gid == 40 ||
                                game_info_data.gid == 43 ||
                                game_info_data.gid == 47
                            "
                        >
                            <span class="lottery_num_pk10" v-for="item in game_info_data.upopennumber" :key="item.index">
                                <span v-if="item == 1" class="pk10_num1">{{ item }}</span>
                                <span v-if="item == 2" class="pk10_num2">{{ item }}</span>
                                <span v-if="item == 3" class="pk10_num3">{{ item }}</span>
                                <span v-if="item == 4" class="pk10_num4">{{ item }}</span>
                                <span v-if="item == 5" class="pk10_num5">{{ item }}</span>
                                <span v-if="item == 6" class="pk10_num6">{{ item }}</span>
                                <span v-if="item == 7" class="pk10_num7">{{ item }}</span>
                                <span v-if="item == 8" class="pk10_num8">{{ item }}</span>
                                <span v-if="item == 9" class="pk10_num9">{{ item }}</span>
                                <span v-if="item == 10" class="pk10_num10">{{ item }}</span>
                            </span>
                        </span>
                        <!-- 六合彩,极速六合彩 -->
                        <template v-if="game_info_data.gid == 20 || game_info_data.gid == 34 || game_info_data.gid == 48 || game_info_data.gid == 49">
                            <template v-if="game_info_data.upopennumber_cs.length == 0">
                                <!-- <span v-for="item in game_info_data.upopennumber" :key="item.index">
                                    <span class="nums-lhc" :style="{'background-color':item.color}">{{item.num}}</span>
                                    {{item.sx}}
                                </span>-->
                                <span v-if="game_info_data.upopennumber[0] != undefined" class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber[0].color }">{{ game_info_data.upopennumber[0].num }}</span>
                                <span v-if="game_info_data.upopennumber[0] != undefined" style="margin-left: 2px">{{ game_info_data.upopennumber[0].sx }}</span>
                                <span v-if="game_info_data.upopennumber[1] != undefined" class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber[1].color }">{{ game_info_data.upopennumber[1].num }}</span>
                                <span v-if="game_info_data.upopennumber[1] != undefined" style="margin-left: 2px">{{ game_info_data.upopennumber[1].sx }}</span>
                                <span v-if="game_info_data.upopennumber[2] != undefined" class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber[2].color }">{{ game_info_data.upopennumber[2].num }}</span>
                                <span v-if="game_info_data.upopennumber[2] != undefined" style="margin-left: 2px">{{ game_info_data.upopennumber[2].sx }}</span>
                                <span v-if="game_info_data.upopennumber[3] != undefined" class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber[3].color }">{{ game_info_data.upopennumber[3].num }}</span>
                                <span v-if="game_info_data.upopennumber[3] != undefined" style="margin-left: 2px">{{ game_info_data.upopennumber[3].sx }}</span>
                                <span v-if="game_info_data.upopennumber[4] != undefined" class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber[4].color }">{{ game_info_data.upopennumber[4].num }}</span>
                                <span v-if="game_info_data.upopennumber[4] != undefined" style="margin-left: 2px">{{ game_info_data.upopennumber[4].sx }}</span>
                                <span v-if="game_info_data.upopennumber[5] != undefined" class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber[5].color }">{{ game_info_data.upopennumber[5].num }}</span>
                                <span v-if="game_info_data.upopennumber[5] != undefined" style="margin-left: 2px">{{ game_info_data.upopennumber[5].sx }}</span>
                                <el-icon v-if="game_info_data.upopennumber[6] != undefined" color="#000" :size="30">
                                    <Plus />
                                </el-icon>
                                <span v-if="game_info_data.upopennumber[6] != undefined" class="nums-lhc" style="margin: 0" :style="{ backgroundColor: game_info_data.upopennumber[6].color }">{{ game_info_data.upopennumber[6].num }}</span>
                                <span v-if="game_info_data.upopennumber[6] != undefined" style="margin-left: 2px">{{ game_info_data.upopennumber[6].sx }}</span>
                            </template>
                            <template v-else>
                                <!-- <span v-for="item in game_info_data.upopennumber_cs" :key="item.index">
                                    <span class="nums-lhc" :style="{'background-color':item.color}">{{item.num}}</span>
                                    {{item.sx}}
                                </span>-->
                                <span class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber_cs[0].color }">{{ game_info_data.upopennumber_cs[0].num }}</span>
                                <span style="margin-left: 2px">{{ game_info_data.upopennumber_cs[0].sx }}</span>
                                <span class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber_cs[1].color }">{{ game_info_data.upopennumber_cs[1].num }}</span>
                                <span style="margin-left: 2px">{{ game_info_data.upopennumber_cs[1].sx }}</span>
                                <span class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber_cs[2].color }">{{ game_info_data.upopennumber_cs[2].num }}</span>
                                <span style="margin-left: 2px">{{ game_info_data.upopennumber_cs[2].sx }}</span>
                                <span class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber_cs[3].color }">{{ game_info_data.upopennumber_cs[3].num }}</span>
                                <span style="margin-left: 2px">{{ game_info_data.upopennumber_cs[3].sx }}</span>
                                <span class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber_cs[4].color }">{{ game_info_data.upopennumber_cs[4].num }}</span>
                                <span style="margin-left: 2px">{{ game_info_data.upopennumber_cs[4].sx }}</span>
                                <span class="nums-lhc" :style="{ backgroundColor: game_info_data.upopennumber_cs[5].color }">{{ game_info_data.upopennumber_cs[5].num }}</span>
                                <span style="margin-left: 2px">{{ game_info_data.upopennumber_cs[5].sx }}</span>
                                <el-icon v-if="game_info_data.upopennumber_cs[6].num != ''" color="#000" :size="30">
                                    <Plus />
                                </el-icon>
                                <span class="nums-lhc" style="margin: 0" :style="{ backgroundColor: game_info_data.upopennumber_cs[6].color }">{{ game_info_data.upopennumber_cs[6].num }}</span>
                                <span style="margin-left: 2px">{{ game_info_data.upopennumber_cs[6].sx }}</span>
                            </template>
                        </template>
                        <!-- pc蛋蛋，加拿大28，幸运28 -->
                        <span v-if="game_info_data.gid == '22' || game_info_data.gid == '23'" style="padding-top: 2px">
                            <span class="betclassname_old_pcdd" :style="{ 'background-color': game_info_data.upopennumber[0].color }">{{ game_info_data.upopennumber[0].num }}</span
                            >＋ <span class="betclassname_old_pcdd" :style="{ 'background-color': game_info_data.upopennumber[1].color }">{{ game_info_data.upopennumber[1].num }}</span
                            >＋ <span class="betclassname_old_pcdd" :style="{ 'background-color': game_info_data.upopennumber[2].color }">{{ game_info_data.upopennumber[2].num }}</span
                            >＝
                            <span class="betclassname_old_pcdd" :style="{ 'background-color': game_info_data.upopennumber[3].color }">{{ game_info_data.upopennumber[3].num }}</span>
                        </span>
                        <span
                            v-if="
                                game_info_data.gid == 4 || game_info_data.gid == 6 || game_info_data.gid == 10 || game_info_data.gid == 29 || game_info_data.gid == 30 || game_info_data.gid == 31 || game_info_data.gid == 32 || game_info_data.gid == 36
                            "
                        >
                            <span class="cqssc_nums" style="background: #f56c6c; color: #fff" v-for="item of game_info_data.upopennumber" :key="item.index">{{ item }}</span>
                        </span>
                        <span class="res-msg" v-show="game_info_data.res_msg != undefined && game_info_data.res_msg != ''">{{ game_info_data.res_msg }}</span>
                    </div>
                </div>
                <div v-show="game_info_data.upopenphase == null || game_info_data.upopenphase == '' || game_info_data.upopenphase.length == 0">暂无开奖期号</div>
            </div>
            <div class="info-item" style="float: right">
                <template v-if="$store.state.userinfo.group_id < 4 && ($store.state.userinfo.trader_status == 1 || $store.state.userinfo.trader_diff_status == 1)">
                    <span>赔率：</span>
                    <el-select v-model="pl_value" size="small" style="width: 70px" @change="updeta_pl_value">
                        <el-option label="0.0001" :value="0.0001" />
                        <el-option label="0.0002" :value="0.0002" />
                        <el-option label="0.0003" :value="0.0003" />
                        <el-option label="0.0005" :value="0.0005" />
                        <el-option label="0.001" :value="0.001" />
                        <el-option label="0.002" :value="0.002" />
                        <el-option label="0.003" :value="0.003" />
                        <el-option label="0.005" :value="0.005" />
                        <el-option label="0.01" :value="0.01" />
                        <el-option label="0.02" :value="0.02" />
                        <el-option label="0.03" :value="0.03" />
                        <el-option label="0.05" :value="0.05" />
                        <el-option label="0.1" :value="0.1" />
                        <el-option label="0.2" :value="0.2" />
                        <el-option label="0.3" :value="0.3" />
                        <el-option label="0.5" :value="0.5" />
                        <el-option label="1" :value="1" />
                        <el-option label="1.5" :value="1.5" />
                        <el-option label="2" :value="2" />
                        <el-option label="3" :value="3" />
                        <el-option label="5" :value="5" />
                        <el-option label="10" :value="10" />
                        <el-option label="15" :value="15" />
                        <el-option label="20" :value="20" />
                    </el-select>
                </template>
                <span style="margin: 0 0 0 5px">更新：</span>
                <el-select v-model="update_time" size="small" style="width: 60px" @change="updeta_data_now">
                    <!-- <el-option label="1秒" :value="1000" />
                    <el-option label="2秒" :value="2000" />
                    <el-option label="3秒" :value="3000" />
                    <el-option label="4秒" :value="4000" />-->
                    <el-option label="5秒" :value="5000" />
                    <el-option label="5秒" :value="8000" />
                    <el-option label="10秒" :value="10000" />
                    <el-option label="15秒" :value="15000" />
                    <el-option label="20秒" :value="20000" />
                    <el-option label="30秒" :value="30000" />
                </el-select>
                <el-select v-model="xuzhu_shizhan" size="small" style="width: 60px" @change="change_xuzhu_shizhan">
                    <el-option label="虚占" value="0" />
                    <el-option label="实占" value="1" />
                </el-select>
                <el-select v-model="pk" size="small" style="width: 60px" @change="change_pk">
                    <!-- <el-option label="总项盘口" value="0" /> -->
                    <el-option label="总盘" value="0" />
                    <el-option label="A盘" value="1" />
                    <el-option label="B盘" value="2" />
                    <el-option label="C盘" value="3" />
                </el-select>
                <div class="info-item">
                    <button class="btn light-bg theme-border-half" style="width: 108px; margin: 0 0 0 5px" @click="updeta_data_now">{{ updeta_data_btn != "" ? `${updeta_data_btn}秒钟后更新数据` : "更新中..." }}</button>
                </div>
                <div v-if="is_manlet != '-1'" style="margin: 5px 0 0 0">
                    <span>盈亏值：</span>
                    <el-input v-model="fxz" placeholder="请输入风险值" size="small" class="fxz" clearable />
                    <button class="btn yjbtn theme-bg theme-border-half" style="border-left: 0" @click="$emit('fast_bh', 'yjbh', fxz)">一键补货</button>
                    <button class="btn ksbtn theme-bg theme-border-half" style="margin-left: 5px" @click="$emit('fast_bh')">快速补货</button>
                    <button class="btn bhmxbtn theme-bg theme-border-half" style="margin-left: 5px" @click="$emit('get_bhmx')">补货明细</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: ["game_info_data", "is_manlet"],
    data() {
        return {
            pl_value: 0.005,
            update_time: 10000,
            xuzhu_shizhan: "1",
            pk: "0",
            updeta_data_btn: "",
            timer: "",
            countDown_h: "--",
            countDown_m: "--",
            countDown_s: "--",
            timerF: "",
            time_text: "距离封盘",
            fxz: "100000",
        };
    },
    created() {
        this.clear_timer();
        window.clearInterval(this.timerF);
        this.pk = this.$store.state.instant_pk;
    },
    beforeDestroy() {
        this.clear_timer();
        window.clearInterval(this.timerF);
    },
    methods: {
        updeta_pl_value(value) {
            this.$emit("updeta_pl_value", value);
        },
        change_xuzhu_shizhan(value) {
            this.$emit("change_xuzhu_shizhan", value);
        },
        change_pk(pk) {
            // console.log(pk);
            this.$emit("change_pk", pk);
        },
        time_down(time) {
            if (time == "00:00:00") {
                this.time_text = "已封盘";
            } else {
                this.time_text = "距离封盘";
            }
            window.clearInterval(this.timerF);
            let str = time;
            let arr = str.split(":");
            let seconds = arr[0] * 3600 + arr[1] * 60 + arr[2] * 1;
            let self = this;
            let t = seconds;
            this.timerF = setInterval(function () {
                t -= 1;
                if (t >= 0) {
                    let hour = Math.floor((t / 3600) % 24);
                    let min = Math.floor((t / 60) % 60);
                    let sec = Math.floor(t % 60);
                    hour = hour < 10 ? "0" + hour : hour;
                    min = min < 10 ? "0" + min : min;
                    sec = sec < 10 ? "0" + sec : sec;
                    self.countDown_h = hour;
                    self.countDown_m = min;
                    self.countDown_s = sec;
                    // console.log("距離封盤--" + hour + ":" + min + " " + sec)
                } else {
                    window.clearInterval(this.timerF);
                    // self.updeta_data('now');
                }
            }, 1000);
        },
        updeta_data_now() {
            this.updeta_data("now");
        },
        clear_timer() {
            window.clearInterval(this.timer);
        },
        updeta_data(type = "") {
            this.clear_timer();
            this.updeta_data_btn = "";
            this.updeta_data_btn = this.update_time / 1000;
            let that = this;
            if (type == "now") {
                that.$emit("get_game_info", that.pk);
            } else {
                this.timer = setInterval(() => {
                    that.updeta_data_btn--;
                    if (that.updeta_data_btn == 0) {
                        this.clear_timer();
                        that.updeta_data_btn = "";
                        that.$emit("get_game_info", that.pk);
                    }
                }, 1000);
            }
        },
        clear_all_timer() {
            window.clearInterval(this.timer);
            window.clearInterval(this.timerF);
        },
    },
};
</script>

<style scoped>
.icon {
    display: inline-block;
    background: url("../../assets/title_icon.gif") no-repeat;
    width: 14px;
    height: 13px;
    vertical-align: middle;
    margin: 0 6px;
}
.info-item {
    display: inline-block;
}
.nums-lhc {
    text-align: center;
    display: inline-block;
    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin: 0;
    margin-left: 6px;
    color: #fff;
}
.lottery_num_pk10 span {
    display: inline-block;
    height: 24px;
    width: 24px;
    line-height: 24px;
    margin: 0 2px 0 0;
    text-align: center;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    -webkit-text-stroke: 1px #000;
}

.pk10_num1 {
    background: #ff0;
}

.pk10_num2 {
    background: #0089ff;
}

.pk10_num3 {
    background: #4d4d4d;
}

.pk10_num4 {
    background: #ff7300;
}

.pk10_num5 {
    background: #81ffff;
}

.pk10_num6 {
    background: #5200ff;
}

.pk10_num7 {
    background: #e3e3e3;
}

.pk10_num8 {
    background: #f00;
}

.pk10_num9 {
    background: #760000;
}

.pk10_num10 {
    background: #28c200;
}
.cqssc_nums {
    display: inline-block;
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: 800;
    border-radius: 50%;
    height: 24px;
    width: 24px;
    margin: 0;
    margin-left: 6px;
    text-align: center;
}
.betclassname_old_pcdd {
    display: inline-block;
    width: 27px;
    height: 27px;
    line-height: 27px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    margin: 0 3px;
    font-weight: bold;
}
.btn {
    height: auto;
    line-height: 18px;
    border: 1px solid;
    padding: 2px 5px;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
}
.res-msg {
    color: blue;
    font-weight: bold;
    margin-left: 20px;
}
.fxz {
    width: 100px;
}
:deep(.fxz .el-input__wrapper) {
    border-radius: 0 !important;
}
.yjbtn,
.ksbtn,
.bhmxbtn {
    line-height: 22px;
    padding: 0 5px;
    color: #fff;
    font-weight: bold;
}
</style>