import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "user"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, {
    class: "user-router-view",
    onChange_submenu: $options.change_submenu
  }, {
    default: _withCtx(({
      Component
    }) => [Component ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      key: 0
    })) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["onChange_submenu"])]);
}