<!--
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-10-25 15:16:38
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-09-20 15:03:36
 * @FilePath: \pc_admin\src\components\report\zhudanchuli.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="zhudanchuli">
        <div class="titleform light-bg half-border">
            <div class="item">
                <datepicker ref="datePicker" from="报表查询" @getDate="getDate" :showBtns="true" :onlyUpdate="true" />
            </div>
            <div class="item">
                <span>注单号：</span>
                <el-input v-model="form.order_id" placeholder="请输入注单号" clearable size="small" style="width: 140px" />
            </div>
            <div class="item">
                <span>账号：</span>
                <el-input v-model="form.username" placeholder="请输入用户账号" clearable size="small" style="width: 140px" />
            </div>
            <div class="item">
                <span>状态：</span>
                <el-select v-model="form.t_status" size="small" style="width: 140px">
                    <el-option label="全部" :value="0" />
                    <el-option label="生效" :value="1" />
                    <el-option label="注销" :value="-1" />
                </el-select>
            </div>
            <div class="item">
                <el-button type="primary" size="small" @click="get_list">查询</el-button>
            </div>
            <div class="item fg"></div>
            <div class="item">
                <el-input v-model="form.hyname" placeholder="请输入会员账号" clearable size="small" style="width: 140px" />
                <el-button type="primary" size="small" @click="hy_cancel_list">按会员账号撤单</el-button>
            </div>
            <div class="item" style="float: right">
                <el-checkbox v-model="all_selecet" label="全选" @change="all_selecet_change" />&nbsp;
                <el-button style="margin-bottom: 6px" type="primary" size="small" @click="cancel_list">批量处理</el-button>
            </div>
        </div>
        <div>
            <el-table ref="tableRef" :data="list" border size="small" highlight-current-row header-cell-class-name="table-header-cell" header-row-class-name="table-header-row">
                <el-table-column property="posttime" label="注单号/时间" align="center" width="145">
                    <template #default="scope">
                        <div>{{ scope.row.order_id }}</div>
                        <div>{{ $filters.time(scope.row.posttime, "报表注单") }}</div>
                    </template>
                </el-table-column>
                <el-table-column property="game_name" label="彩种/期号" align="center">
                    <template #default="scope">
                        <div style="color: #409eff">{{ scope.row.game_name }}</div>
                        <div>{{ scope.row.qnum }}</div>
                    </template>
                </el-table-column>
                <el-table-column property="platform" label="来源" align="center" width="80">
                    <template #default="scope">
                        <span>{{ scope.row.platform }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="username" label="会员" align="center">
                    <template #default="scope">
                        <span>{{ scope.row.username }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="title" label="下注明细" align="center" min-width="100">
                    <template #default="scope">
                        <div style="color: #409eff">{{ scope.row.title }}</div>
                        <div>{{ scope.row.bet_name }}</div>
                        <div style="color: red">{{ scope.row.list_bonus }}</div>
                    </template>
                </el-table-column>
                <el-table-column property="balance" label="下注金额" align="center">
                    <template #default="scope">
                        <span>{{ scope.row.balance }}</span>
                    </template>
                </el-table-column>
                <el-table-column property="balance" label="会员输赢/退水" align="center" min-width="90">
                    <template #default="scope">
                        <template v-if="scope.row.bonus != undefined">
                            <div :class="scope.row.bonus * 1 - scope.row.balance >= 0 ? '' : 'redcolor'">{{ (scope.row.bonus * 1 - scope.row.balance).toFixed(1) }}</div>
                        </template>
                        <template v-else>-</template>
                        <div>{{ scope.row.rebate_balance }}</div>
                    </template>
                </el-table-column>
                <el-table-column v-if="$store.state.userinfo.group_id < 2" prop="rebate_balance" label="总监" align="center">
                    <template #default="scope">
                        <template v-if="scope.row.agent_list_2.some((item) => item.name == '总监')">
                            <div style="color: red">{{ scope.row.agent_list_2.find((item) => item.name == "总监").occupy_bill }}</div>
                            <div style="color: #409eff">{{ scope.row.agent_list_2.find((item) => item.name == "总监").occupy }}</div>
                            <div style="color: #333">{{ scope.row.agent_list_2.find((item) => item.name == "总监").username }}</div>
                        </template>
                        <template v-else>-</template>
                    </template>
                </el-table-column>
                <el-table-column v-if="$store.state.userinfo.group_id < 3" prop="rebate_balance" label="分公司" align="center">
                    <template #default="scope">
                        <template v-if="scope.row.agent_list_2.some((item) => item.name == '分公司')">
                            <div style="color: red">{{ scope.row.agent_list_2.find((item) => item.name == "分公司").occupy_bill }}</div>
                            <div style="color: #409eff">{{ scope.row.agent_list_2.find((item) => item.name == "分公司").occupy }}</div>
                            <div style="color: #333">{{ scope.row.agent_list_2.find((item) => item.name == "分公司").username }}</div>
                        </template>
                        <template v-else>-</template>
                    </template>
                </el-table-column>
                <el-table-column v-if="$store.state.userinfo.group_id < 4" prop="rebate_balance" label="大股东" align="center">
                    <template #default="scope">
                        <template v-if="scope.row.agent_list_2.some((item) => item.name == '大股东')">
                            <div style="color: red">{{ scope.row.agent_list_2.find((item) => item.name == "大股东").occupy_bill }}</div>
                            <div style="color: #409eff">{{ scope.row.agent_list_2.find((item) => item.name == "大股东").occupy }}</div>
                            <div style="color: #333">{{ scope.row.agent_list_2.find((item) => item.name == "大股东").username }}</div>
                        </template>
                        <template v-else>-</template>
                    </template>
                </el-table-column>
                <el-table-column v-if="$store.state.userinfo.group_id < 5" prop="rebate_balance" label="股东" align="center">
                    <template #default="scope">
                        <template v-if="scope.row.agent_list_2.some((item) => item.name == '股东')">
                            <div style="color: red">{{ scope.row.agent_list_2.find((item) => item.name == "股东").occupy_bill }}</div>
                            <div style="color: #409eff">{{ scope.row.agent_list_2.find((item) => item.name == "股东").occupy }}</div>
                            <div style="color: #333">{{ scope.row.agent_list_2.find((item) => item.name == "股东").username }}</div>
                        </template>
                        <template v-else>-</template>
                    </template>
                </el-table-column>
                <el-table-column v-if="$store.state.userinfo.group_id < 6" prop="rebate_balance" label="总代理" align="center">
                    <template #default="scope">
                        <template v-if="scope.row.agent_list_2.some((item) => item.name == '总代理')">
                            <div style="color: red">{{ scope.row.agent_list_2.find((item) => item.name == "总代理").occupy_bill }}</div>
                            <div style="color: #409eff">{{ scope.row.agent_list_2.find((item) => item.name == "总代理").occupy }}</div>
                            <div style="color: #333">{{ scope.row.agent_list_2.find((item) => item.name == "总代理").username }}</div>
                        </template>
                        <template v-else>-</template>
                    </template>
                </el-table-column>
                <el-table-column v-if="$store.state.userinfo.group_id < 7" prop="rebate_balance" label="代理" align="center">
                    <template #default="scope">
                        <template v-if="scope.row.agent_list_2.some((item) => item.name == '代理')">
                            <div style="color: red">{{ scope.row.agent_list_2.find((item) => item.name == "代理").occupy_bill }}</div>
                            <div style="color: #409eff">{{ scope.row.agent_list_2.find((item) => item.name == "代理").occupy }}</div>
                            <div style="color: #333">{{ scope.row.agent_list_2.find((item) => item.name == "代理").username }}</div>
                        </template>
                        <template v-else>-</template>
                    </template>
                </el-table-column>
                <el-table-column prop="rebate_balance" label="您的占成" align="center">
                    <template #default="scope">
                        <template v-if="scope.row.agent_list_2.some((item) => item.name == '自己')">
                            <div style="color: red">{{ scope.row.agent_list_2.find((item) => item.name == "自己").occupy_bill }}</div>
                            <div style="color: #409eff">{{ scope.row.agent_list_2.find((item) => item.name == "自己").occupy }}</div>
                        </template>
                        <template v-else>-</template>
                    </template>
                </el-table-column>
                <el-table-column property="order_staus" label="结算状态" align="center" width="120">
                    <template #default="scope">
                        <span v-show="scope.row.order_staus == 0">未结</span>
                        <span v-show="scope.row.order_staus == 1">已结算</span>
                        <div class="redcolor" v-show="scope.row.order_staus == -1">
                            <div>已撤单</div>
                            <div>{{ scope.row.cancel_time }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column property="bonus" label="中奖状态" align="center" width="60">
                    <template #default="scope">
                        <span v-show="scope.row.order_staus == 1 && scope.row.bonus != undefined">
                            <span>{{ scope.row.bonus != 0 ? "中奖" : "未中奖" }}</span>
                        </span>
                        <span v-show="scope.row.order_staus != 1 || scope.row.bonus == undefined">-</span>
                    </template>
                </el-table-column>
                <el-table-column width="60" align="center">
                    <template #header>
                        <el-checkbox v-model="all_selecet" label="全选" size="small" @change="all_selecet_change" />
                    </template>
                    <template #default="scope">
                        <div class="redcolor" v-show="scope.row.order_staus == -1">已撤单</div>
                        <el-checkbox v-show="scope.row.order_staus != -1" v-model="scope.row.seleceted" label="撤单" size="small" @change="change_selecet(scope.row)" />
                    </template>
                </el-table-column>
            </el-table>
            <div class="paging">
                <el-pagination small @current-change="change_page" v-model="form.page" :page-size="50" :pager-count="5" :total="pagination.total_records * 1" layout="total,prev,pager,next,jumper" hide-on-single-page />
            </div>
            <div>
                <el-checkbox v-model="all_selecet" label="全选" @change="all_selecet_change" />&nbsp;
                <el-button type="primary" style="margin-bottom: 6px" size="small" @click="cancel_list">批量处理</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import datepicker from "../homePublic/datepicker.vue";
import axios from "../../axios/axios";
export default {
    components: { datepicker },
    data() {
        return {
            form: {
                report_type: "注单处理",
                BeginDate: "",
                EndDate: "",
                order_id: "",
                username: "",
                t_status: 0, //全部0，生效1，注销 -1
                page: 1,
                hyname: "",
            },
            list: [],
            pagination: {
                //分页信息
                total_pages: "",
                total_records: "",
                page_size: "",
            },
            all_selecet_order_id_s: [],
            all_selecet: false,
        };
    },
    // beforeRouteEnter(to, from, next) {
    //     axios
    //         .post("operate/order_list", {
    //             report_type: "注单处理",
    //             BeginDate: "",
    //             EndDate: "",
    //             order_id: "",
    //             username: "",
    //             t_status: 0, //全部0，生效1，注销 -1
    //             page: 1,
    //         })
    //         .then((result) => {
    //             if (result.data.status == 200) {
    //                 next((vm) => {
    //                     vm.poc_data(result.data.repsoneContent);
    //                 });
    //             }
    //         });
    // },
    created() {},
    methods: {
        cancel_list() {
            if (this.all_selecet_order_id_s.length == 0) {
                return this.$message.error("未选择注单");
            }
            this.axios
                .post("order/cancel_list", {
                    order_id: this.all_selecet_order_id_s || [],
                })
                .then((result) => {
                    if (result.data.status == 1) {
                        this.$message.success(result.data.msg);
                        this.get_list();
                    }
                    if (result.data.status == 0) {
                        this.$message.error(result.data.msg);
                    }
                });
        },
        hy_cancel_list() {
            if (this.form.hyname == "") {
                return this.$message.error("请输入会员账号");
            }
            this.$confirm(`确定撤销该会员 (${this.form.hyname}) 所有注单，此操作不可恢复, 是否继续?`, "提示", {
                confirmButtonText: "确定删除",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.axios
                        .post("order/cancel_list", {
                            username: this.form.hyname,
                        })
                        .then((result) => {
                            if (result.data.status == 1) {
                                this.$message.success(result.data.msg);
                                this.get_list();
                            }
                            if (result.data.status == 0) {
                                this.$message.error(result.data.msg);
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消操作",
                    });
                });
        },
        //全选
        all_selecet_change() {
            if (this.all_selecet) {
                this.list.forEach((item) => {
                    if (item.order_staus != -1) {
                        item.seleceted = true;
                        this.all_selecet_order_id_s.push(item.order_id_s);
                    }
                });
            } else {
                this.all_selecet_order_id_s = [];
                this.list.forEach((item) => (item.seleceted = false));
            }
        },
        //单个选择
        change_selecet(row) {
            if (row.seleceted) {
                this.all_selecet_order_id_s.push(row.order_id_s);
            } else {
                this.all_selecet_order_id_s.splice(
                    this.all_selecet_order_id_s.findIndex((item) => item == row.order_id_s),
                    1
                );
            }
            this.all_selecet = this.all_selecet_order_id_s.length == this.list.length;
        },
        change_page(page) {
            this.form.page = page;
            this.get_list();
        },
        //获取注单列表
        get_list() {
            this.axios.post("operate/order_list", this.form).then((result) => {
                if (result.data.status == 200) {
                    this.poc_data(result.data.repsoneContent);
                }
            });
        },
        poc_data(data) {
            data.list.forEach((item) => {
                item.seleceted = false;
            });
            this.list = data.list;
            this.pagination = data.pagination;
        },
        //获取时间
        getDate(date, updata) {
            this.form.BeginDate = date.start;
            this.form.EndDate = date.end;
            if (updata === "updata") {
                this.get_list();
            }
        },
    },
};
</script>

<style scoped>
.titleform {
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding: 0 5px;
    border-bottom: 1px solid;
}
.titleform .item {
    display: inline-block;
    margin-right: 10px;
}
.zhudanchuli {
    background: #fff;
}
</style>