export default {
  data() {
    return {
      notice_list: [],
      //弹窗公告
      dialog: {
        visible: false,
        title: "站内公告",
        list: []
      },
      balance: "0",
      now_users_manager: "0",
      now_users_member: "0",
      user_bet_count: "0",
      color: "",
      predefineColors: ["#409EFF", "#67C23A", "#E6A23C", "#F56C6C", "#606266", "#508f02", "#7a64b2", "#8d5f4f"],
      sid: "",
      last_type: "",
      online_status: 0
    };
  },

  created() {
    if (this.$store.state.top_data != null) {
      let top_data = this.$store.state.top_data;
      this.is_kuaicai = top_data.is_kuaicai;
      this.is_liuhe = top_data.is_liuhe;
      this.balance = top_data.balance;
      this.kuaicai_balance = top_data.kuaicai_balance;
      this.$store.state.userinfo.balance = top_data.balance;
      this.$store.state.userinfo.kuaicai_balance = top_data.kuaicai_balance;
      this.now_users_manager = top_data.now_users_manager;
      this.now_users_member = top_data.now_users_member;
      this.user_bet_count = top_data.user_bet_count;
      this.online_status = top_data.online_status;
    }

    this.get_balance();
    this.color = this.$store.state.userinfo.color;
    this.change_color(this.color);
    this.get_new_notice();
    this.get_tc_notice();
    this.do_list();

    if (process.env.NODE_ENV !== "development") {
      this.sid = window.setInterval(() => {
        this.get_balance();
        this.do_list();
        this.get_tc_notice();
      }, 10000);
    }
  },

  unmounted() {
    window.clearInterval(this.sid);
  },

  methods: {
    go_tongji(type) {
      if (this.last_type == type) {} else {}

      this.$router.push({
        path: `/home/zaixiantongji`,
        name: `zaixiantongji`,
        query: {
          type
        }
      });
      this.last_type = type;
    },

    go_zijinrizhi() {
      this.$router.push(`/home/zijinrizhi`);
    },

    change_color(color) {
      // console.log(color);
      if (color == null) {
        return;
      }

      this.$store.state.userinfo.color = color;
      this.$emit("change_color", color);
    },

    /**获取站内弹窗公告 */
    get_tc_notice() {
      this.axios.post("party/notice", {
        page: 1,
        s_type: 2,
        limit: 1
      }).then(result => {
        // console.log(result.data);
        if (result.data.status === 200) {
          this.dialog.list = result.data.repsoneContent.list;

          if (this.dialog.list.length > 0) {
            if (this.dialog.list[0].create_time > this.$store.state.notice_tc.create_time) {
              this.dialog.visible = true;
              this.$store.state.notice_tc.create_time = this.dialog.list[0].create_time;
            }
          }
        }
      });
    },

    get_new_notice() {
      this.axios.post("party/notice", {
        page: 1,
        s_type: 1
      }).then(result => {
        // console.log(result.data)
        if (result.data.status == 200 && result.data.shortMessage == "获取成功") {
          this.notice_list = result.data.repsoneContent.list;
        }
      });
    },

    get_balance() {
      this.axios.post("account/get_balance?").then(result => {
        if (result.data.status === 200) {
          // console.log(this.$store.state.userinfo)
          this.is_kuaicai = result.data.repsoneContent.is_kuaicai;
          this.is_liuhe = result.data.repsoneContent.is_liuhe;
          this.balance = result.data.repsoneContent.balance;
          this.kuaicai_balance = result.data.repsoneContent.kuaicai_balance;
          this.$store.state.userinfo.balance = result.data.repsoneContent.balance;
          this.$store.state.userinfo.kuaicai_balance = result.data.repsoneContent.kuaicai_balance;
          this.now_users_manager = result.data.repsoneContent.now_users_manager;
          this.now_users_member = result.data.repsoneContent.now_users_member;
          this.user_bet_count = result.data.repsoneContent.user_bet_count;
          this.online_status = result.data.repsoneContent.online_status;
          this.$store.state.top_data = result.data.repsoneContent;
        }
      });
    },

    do_list() {
      this.axios.post("account/do_list").then(result => {
        // console.log(result.data)
        if (result.data.status == 200 && result.data.shortMessage == "获取成功") {}
      });
    }

  }
};