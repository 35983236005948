<template>
    <div class="add-huiyuan">
        <div class="myform">
            <!-- 上级 -->
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">上级</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-select v-model="sltuid" size="small" style="width: 140px" @change="get_user_edit">
                        <!-- <el-option v-for="item in addinfo.sub_list" :key="item.id" :label="item.username" :value="item.id" /> -->
                        <el-option-group v-for="group in addinfo.sub_list" :key="group.label" :label="group.label">
                            <el-option v-for="item in group.options" :key="item.id" :label="item.username" :value="item.id" />
                        </el-option-group>
                    </el-select>
                </el-col>
            </el-row>
            <!-- 账号 -->
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">{{ addinfo.sub_group_name }}账号</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-popover :visible="err.name.visible" placement="right">
                        <template #reference>
                            <el-input style="width: 140px" v-model.trim="name" size="small" placeholder="请输入登录账号" clearable @focus="err.name.visible = false" />
                        </template>
                        <span>
                            <el-icon color="red" size="20px">
                                <warning-filled />
                            </el-icon>
                            <span>{{ err.name.content }}</span>
                        </span>
                    </el-popover>
                </el-col>
            </el-row>
            <!-- 名称 -->
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">名称</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-popover :visible="err.nickname.visible" placement="right">
                        <template #reference>
                            <el-input style="width: 140px" v-model.trim="nickname" size="small" placeholder="请输入名称" clearable @focus="err.nickname.visible = false" />
                        </template>
                        <span>
                            <el-icon color="red" size="20px">
                                <warning-filled />
                            </el-icon>
                            <span>{{ err.nickname.content }}</span>
                        </span>
                    </el-popover>
                </el-col>
            </el-row>
            <!-- 登录密码 -->
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">登录密码</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-popover :visible="err.pwd.visible" placement="right">
                        <template #reference>
                            <el-input style="width: 140px" v-model.trim="pwd" size="small" placeholder="请输入登录密码" clearable show-password type="password" @focus="err.pwd.visible = false" />
                        </template>
                        <span>
                            <el-icon color="red" size="20px">
                                <warning-filled />
                            </el-icon>
                            <span>{{ err.pwd.content }}</span>
                        </span>
                    </el-popover>
                    <span>&nbsp;&nbsp;登录密码必需包含大小写字母和数字，最少8位</span>
                </el-col>
            </el-row>
            <!-- 现金模式 -->
            <el-row class="myform-item" v-if="add_user_data.group_id == 1">
                <el-col class="myform-item-lable" :span="6">现金模式</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-radio-group v-model="transfer_status" @change="is_extract = transfer_status == '0' ? '1' : '0'">
                        <el-radio label="1">开启</el-radio>
                        <el-radio label="0">关闭</el-radio>
                    </el-radio-group>
                </el-col>
            </el-row>
            <!-- 额度模式 -->
            <!-- <el-row class="myform-item" v-if="add_user_data.group_id==3">
                <el-col class="myform-item-lable" :span="6">额度模式</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-radio-group v-model="bill_reset">
                        <el-radio label="0">现金</el-radio>
                        <el-radio label="2">信用</el-radio>
                    </el-radio-group>
                </el-col>
            </el-row> -->
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">额度模式</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-radio-group v-model="bill_reset">
                        <el-radio label="0" v-if="add_user_data.group_id == 3 || add_user_data.bill_reset == 1 || add_user_data.bill_reset == 0">现金</el-radio>
                        <el-radio label="2" v-if="add_user_data.group_id == 3 || add_user_data.bill_reset == 1 || add_user_data.bill_reset == 2">信用</el-radio>
                    </el-radio-group>
                </el-col>
            </el-row>
            <!-- 退水设定 -->
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">退水设定</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-select v-model="kuaicai_auto_draw_back.value" placeholder="自定义" size="small" @change="draw_back_change('', $event)" style="width: 140px">
                        <el-option label="水全退到底" value="0"></el-option>
                        <el-option label="赚取 0.1 退水" value="0.1"></el-option>
                        <el-option label="赚取 0.2 退水" value="0.2"></el-option>
                        <el-option label="赚取 0.3 退水" value="0.3"></el-option>
                        <el-option label="赚取 0.4 退水" value="0.4"></el-option>
                        <el-option label="赚取所有退水" value="all"></el-option>
                        <el-option label="自定义" value="auto"></el-option>
                    </el-select>
                    <span class="auto_draw_back" v-if="kuaicai_auto_draw_back.show">
                        <label for="auto_draw_back" style="margin-left: 15px">赚取：</label>
                        <el-input name="auto_draw_back" v-model="kuaicai_auto_draw_back.value" size="small" style="width: 140px"></el-input>
                    </span>
                </el-col>
            </el-row>
            <!-- 状态 -->
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">状态</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-radio-group v-model="status">
                        <el-radio label="1">开启</el-radio>
                        <el-radio label="0">关闭</el-radio>
                    </el-radio-group>
                </el-col>
            </el-row>
            <el-row class="myform-item">
                <el-col class="myform-item-lable" :span="6">彩种选择</el-col>
                <el-col class="myform-item-value" :span="18">
                    <el-checkbox-group v-model="user_play_status_list" @change="change_user_play_status_list">
                        <el-checkbox v-if="add_user_data.status_kuaicai == 1" label="1">快彩</el-checkbox>
                        <el-checkbox v-if="add_user_data.status_liuhe == 1" label="2">香港六合</el-checkbox>
                        <el-checkbox v-if="add_user_data.status_aomen == 1" label="3">澳门六合</el-checkbox>
                        <el-checkbox v-if="add_user_data.status_xinaomen == 1" label="4">新澳门六合</el-checkbox>
                    </el-checkbox-group>
                </el-col>
            </el-row>
            <template v-if="user_play_status_list.includes('1')">
                <!-- 保留占成(快彩) -->
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">上级保留占成(快彩)</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-popover :visible="err.kuaicai_sup_occupy_lock.visible" placement="right">
                            <template #reference>
                                <el-input style="width: 140px" v-model.trim="kuaicai_sup_occupy_lock" size="small" @focus="err.kuaicai_sup_occupy_lock.visible = false" :disabled="add_user_data.group_id < 3" />
                            </template>
                            <span>
                                <el-icon color="red" size="20px">
                                    <warning-filled />
                                </el-icon>
                                <span>{{ err.kuaicai_sup_occupy_lock.content }}</span>
                            </span>
                        </el-popover>
                        最高可设占成：{{ add_user_data.kuaicai_max_sup_lock }}%
                    </el-col>
                </el-row>
                <!--  盘口 -->
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">盘口(快彩)</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-radio-group v-model="kuaicai_userKind_kc">
                            <el-radio v-if="add_user_data.level_id.includes('0') || add_user_data.level_id.includes('1')" label="1">A盘</el-radio>
                            <el-radio v-if="add_user_data.level_id.includes('0') || add_user_data.level_id.includes('2')" label="2">B盘</el-radio>
                            <el-radio v-if="add_user_data.level_id.includes('0') || add_user_data.level_id.includes('3')" label="3">C盘</el-radio>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <!-- 信用额度 -->
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">信用额度(快彩)</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-popover :visible="err.kuaicai_userCredit_kc.visible" placement="top">
                            <template #reference>
                                <el-input style="width: 140px" v-model.trim="kuaicai_userCredit_kc" size="small" clearable @focus="err.kuaicai_userCredit_kc.visible = false" min="0" :max="add_user_data.kuaicai_userCredit_kc * 1" />
                            </template>
                            <span>
                                <el-icon color="red" size="20px">
                                    <warning-filled />
                                </el-icon>
                                <span>{{ err.kuaicai_userCredit_kc.content }}</span>
                            </span>
                        </el-popover>
                        <span>&nbsp;&nbsp;上级余额：{{ add_user_data.kuaicai_balance }}</span>
                    </el-col>
                </el-row>
            </template>
            <template v-if="user_play_status_list.includes('2') || user_play_status_list.includes('3') || user_play_status_list.includes('4')">
                <!-- 保留占成(六合彩) -->
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">上级保留占成(六合)</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-popover :visible="err.sup_occupy_lock.visible" placement="right">
                            <template #reference>
                                <el-input style="width: 140px" v-model.trim="sup_occupy_lock" size="small" @focus="err.sup_occupy_lock.visible = false" :disabled="add_user_data.group_id < 3" />
                            </template>
                            <span>
                                <el-icon color="red" size="20px">
                                    <warning-filled />
                                </el-icon>
                                <span>{{ err.sup_occupy_lock.content }}</span>
                            </span>
                        </el-popover>
                        最高可设占成：{{ add_user_data.max_sup_lock }}%
                    </el-col>
                </el-row>
                <!--  盘口 -->
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">盘口(六合)</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-checkbox-group v-model="userKind_kc">
                            <el-checkbox v-if="add_user_data.level_id.includes('0') || add_user_data.level_id.includes('1')" label="1">A盘</el-checkbox>
                            <el-checkbox v-if="add_user_data.level_id.includes('0') || add_user_data.level_id.includes('2')" label="2">B盘</el-checkbox>
                            <el-checkbox v-if="add_user_data.level_id.includes('0') || add_user_data.level_id.includes('3')" label="3">C盘</el-checkbox>
                        </el-checkbox-group>
                    </el-col>
                </el-row>
                <!-- 信用额度 -->
                <el-row class="myform-item">
                    <el-col class="myform-item-lable" :span="6">信用额度(六合)</el-col>
                    <el-col class="myform-item-value" :span="18">
                        <el-popover :visible="err.userCredit_kc.visible" placement="top">
                            <template #reference>
                                <el-input style="width: 140px" v-model.trim="userCredit_kc" size="small" clearable @focus="err.userCredit_kc.visible = false" min="0" :max="add_user_data.userCredit_kc * 1" />
                            </template>
                            <span>
                                <el-icon color="red" size="20px">
                                    <warning-filled />
                                </el-icon>
                                <span>{{ err.userCredit_kc.content }}</span>
                            </span>
                        </el-popover>
                        <span>&nbsp;&nbsp;上级余额：{{ add_user_data.balance }}</span>
                    </el-col>
                </el-row>
            </template>
        </div>
        <el-button style="margin-top: 10px" size="small" type="primary" @click="post_change">确认新增</el-button>
    </div>
</template>

<script>
import { el } from "element-plus/es/locale";
export default {
    props: ["addinfo"],
    data() {
        return {
            sltuid: "",
            add_user_data: {
                group_id: "",
                level_id: "",
            },
            name: "",
            nickname: "", //昵称
            userKind_kc: [], //六合盘口多选
            kuaicai_userKind_kc: "", //快彩盘口单选
            pwd: "",
            userCredit_kc: "", //信用额度六合
            kuaicai_userCredit_kc: "", //信用额度快彩
            transfer_status: "0", //现金模式
            moneyinout_status: "1", //是否出入款
            is_inmoney: 0, //是否显示入款统计
            autolet_status: 0,
            trader_status: "1", //操盘
            trader_diff_user: 0, //是否显示赚取赔率差
            trader_diff: false, //赚取赔率差
            allowmaxrate_kc: "0", //限占1，任占0
            lowmaxrate_kc: "", //限占值
            play_list: [{ old_occupy: "" }], //游戏列表占成设置
            trader_diff: {
                //赚取赔率差
                status: "1",
                diff: [0.0001, 1],
            },
            posttime: "", //生效时间
            // fast_game_set_value: '',//游戏占成快速设置值
            sup_occupy_fly: "1", //剩余占成回到公司
            add_oc_url: "0",
            agent_url: "",
            note: "", //公司名称
            // sup_occupy_value: '',//自身占成
            sup_occupy_lock: "", //锁定自身占成
            is_extract: "1", //是否可提现
            sup_occupy_catch: "1", //拦截下级补货开关
            is_manlet: "1", //开2级及以下时手动补货
            show_is_manlet: false, //根据所有六合彩状态判断是否显示手补
            bill_reset: "", //额度还原
            allGamesSet: {
                occupy: "",
                game_list_status: "0",
            },
            copy_user_id: "", //赔率复制

            rebate: { min_A: "5.0000", min_B: "4.0000", min_C: "3.0000", min_D: "2.0000" }, //所有最高可设退水额度
            rebate_max: 0, //当前对应盘口的最高退水额度
            auto_draw_back: { show: false, value: "0" },

            //快彩----------------------
            kuaicai_rebate_max: 0,
            kuaicai_rebate: { kuaicai_min_A: "5.0000", kuaicai_min_B: "4.0000", kuaicai_min_C: "3.0000" },
            kuaicai_auto_draw_back: { show: false, value: "0" },

            kuaicai_sup_occupy_lock: "", //锁定自身占成
            kuaicai_allowmaxrate_kc: "0", //限占1，任占0
            kuaicai_lowmaxrate_kc: "", //限占值
            auto_manlet_status: 0,
            intercept_proportion_status: "0", //拦截占余

            remainder: "0", //占余成数归:0占成归自身,1限制下级固定成数
            kuaicai_remainder: "0",
            status: "1",
            user_play_status_list: [],
            err: {
                name: {
                    visible: false,
                    content: "",
                },
                nickname: {
                    visible: false,
                    content: "",
                },
                pwd: {
                    visible: false,
                    content: "",
                },
                sup_occupy_lock: {
                    visible: false,
                    content: "",
                },
                lowmaxrate_kc: {
                    visible: false,
                    content: "",
                },
                kuaicai_sup_occupy_lock: {
                    visible: false,
                    content: "",
                },
                kuaicai_lowmaxrate_kc: {
                    visible: false,
                    content: "",
                },
                userCredit_kc: {
                    visible: false,
                    content: "",
                },
                kuaicai_userCredit_kc: {
                    visible: false,
                    content: "",
                },
            },
        };
    },
    created() {
        // console.log(this.addinfo.sub_list);
        this.sltuid = this.addinfo.sub_list[0].options[0].id;
        // this.get_user_edit(this.sltuid);
    },
    methods: {
        change_user_play_status_list(list) {
            if (list.length < 1) {
                return this.$message.error("至少选择一个彩种");
            }
            if (list.length > 1) {
                let value = list[list.length - 1];
                if (value == "1") {
                    this.user_play_status_list = ["1"];
                } else {
                    this.user_play_status_list = this.user_play_status_list.filter((item) => item != "1");
                }
            }
        },
        /**切换盘口 */
        change_pk(list) {
            if (list.includes("0") && list[list.length - 1] != "0") {
                this.userKind_kc.shift();
            } else if (list[list.length - 1] == "0") {
                this.userKind_kc = ["0"];
            }
        },
        /**退水选择 */
        draw_back_change(type, value) {
            // console.log(value)
            if (type == "lh") {
                if (value == "auto") {
                    this.auto_draw_back.show = true;
                    this.auto_draw_back.value = "";
                } else {
                    this.auto_draw_back.show = false;
                }
            } else {
                if (value == "auto") {
                    this.kuaicai_auto_draw_back.show = true;
                    this.kuaicai_auto_draw_back.value = "";
                } else {
                    this.kuaicai_auto_draw_back.show = false;
                }
            }
        },
        change_rzxz_lhc(s) {
            this.lowmaxrate_kc = this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1;
        },
        change_rzxz_kc(s) {
            this.kuaicai_lowmaxrate_kc = this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1;
        },
        //游戏占成快速设置
        fast_game_set(value) {
            for (const item of this.play_list) {
                item.power_status = this.allGamesSet.power_status;
                item.occupy = value;
                if (item.old_occupy < value) {
                    item.occupy = item.old_occupy;
                }
            }
        },
        formatTooltip(value) {
            return (value / 200).toFixed(4);
        },
        proc_data(data) {
            this.add_user_data = data;
            this.trader_diff_user = this.add_user_data.trader_diff_user;
            this.is_extract = this.add_user_data.extract_bill.toString();
            this.add_user_data.D_odds *= 1;
            this.add_user_data.com_odds[0] *= 1;
            this.add_user_data.com_odds[1] *= 1;
            if (this.add_user_data.D_odds) {
                if (this.add_user_data.D_odds < this.add_user_data.com_odds[0]) {
                    this.add_user_data.D_odds = this.add_user_data.com_odds[0];
                }
                this.add_user_data.D_odds *= 1;
                this.add_user_data.com_odds[0] *= 1;
                this.add_user_data.com_odds[1] *= 1;
            }
            this.play_list = this.add_user_data.play_list.filter((item) => item.occupy_status == 1);
            // 所有六合彩打开才显示手补-------->
            let lhc = this.play_list.find((item) => item.gname == "六合彩");
            let amlhc = this.play_list.find((item) => item.gname == "澳门六合彩");
            if ((lhc && lhc.occupy_status != undefined && lhc.occupy_status == 1) || (amlhc && amlhc.occupy_status != undefined && amlhc.occupy_status == 1)) {
                this.show_is_manlet = true;
            } else {
                this.show_is_manlet = false;
            }
            //------------------------------<
            if (this.add_user_data.add_oc_url == 1) {
                this.add_oc_url = this.add_user_data.add_oc_url;
            }
            //二级开始，一直往下，新增代理的时候，剩余占成默认开启
            if (this.add_user_data.group_id > 2) {
                this.sup_occupy_fly = "1";
            }
            this.sup_occupy_lock = 0;
            if (this.sup_occupy_lock > this.add_user_data.max_sup_lock * 1) {
                this.sup_occupy_lock = this.add_user_data.max_sup_lock * 1;
            }
            //快彩------------------------------>
            this.kuaicai_sup_occupy_lock = 0;
            if (this.kuaicai_sup_occupy_lock * 1 > this.add_user_data.kuaicai_max_sup_lock * 1) {
                this.kuaicai_sup_occupy_lock = this.add_user_data.kuaicai_max_sup_lock * 1;
            }
            //额度模式
            if (this.add_user_data.bill_reset == "1") {
                this.bill_reset = "0";
            } else {
                this.bill_reset = this.add_user_data.bill_reset;
            }
            //彩种选择
            this.user_play_status_list = [];
            if (this.add_user_data.status_kuaicai == 1) {
                this.user_play_status_list.push("1");
                //快彩盘口
                this.kuaicai_userKind_kc = this.add_user_data.kuaicai_level_id == "0" ? "1" : this.add_user_data.kuaicai_level_id.toString();
            } else {
                if (this.add_user_data.status_liuhe == 1) {
                    this.user_play_status_list.push("2");
                }
                if (this.add_user_data.status_aomen == 1) {
                    this.user_play_status_list.push("3");
                }
                if (this.add_user_data.status_xinaomen == 1) {
                    this.user_play_status_list.push("4");
                }
                //六合盘口
                if (this.add_user_data.level_id.length > 1) {
                    this.userKind_kc = this.add_user_data.level_id.split(",");
                } else {
                    this.userKind_kc = this.add_user_data.level_id == "0" ? ["1", "2", "3"] : [this.add_user_data.level_id.toString()];
                }
            }
        },
        /**获取用户新增菜单选项，权限 */
        get_user_edit(data) {
            // console.log(data);
            if (typeof data == "object") {
                this.proc_data(data);
            } else {
                this.axios
                    .post("user_version2/member_add", {
                        sltuid: data,
                        groupid_zs: "",
                    })
                    .then((result) => {
                        if (result.data.status === 200) {
                            this.proc_data(result.data.repsoneContent);
                        }
                    });
            }
        },
        /**提交修改 */
        post_change() {
            if (this.name.length < 2) {
                return (this.err.name = { visible: true, content: "账号最少2个字符" });
            }
            if (this.nickname.length < 1) {
                return (this.err.nickname = { visible: true, content: "名称最少1个字符" });
            }
            if (!/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_]+$)(?![a-z0-9]+$)(?![a-z\\W_]+$)(?![0-9\\W_]+$)[a-zA-Z0-9\\W_]{8,20}$/.test(this.pwd)) {
                return (this.err.pwd = { visible: true, content: "密码为8-20位，大小写字母和数字组合" });
            }
            if (this.add_user_data.is_liuhe == 1) {
                if (this.sup_occupy_lock < 0 || this.sup_occupy_lock === "") {
                    return (this.err.sup_occupy_lock = { visible: true, content: "六合彩保留占成不能小于0" });
                }
                if (this.add_user_data.max_sup_lock != 0) {
                    if (this.allowmaxrate_kc == 1 && this.lowmaxrate_kc < 0) {
                        return (this.err.lowmaxrate_kc = { visible: true, content: "六合彩限制下线可占成数不能小于0" });
                    }
                } else {
                    this.lowmaxrate_kc = 0;
                }
                if (this.sup_occupy_lock * 1 > this.add_user_data.max_sup_lock * 1) {
                    return (this.err.sup_occupy_lock = { visible: true, content: `六合彩保留占成不能大于 ${this.add_user_data.max_sup_lock}%` });
                }
                if (this.add_user_data.group_id > 3) {
                    if (this.lowmaxrate_kc * 1 > this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1) {
                        return (this.err.lowmaxrate_kc = { visible: true, content: `六合彩限制下线可占成数不能大于 ${this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1}%` });
                    }
                } else {
                    if (this.lowmaxrate_kc * 1 > this.add_user_data.max_sup_lock * 1) {
                        return (this.err.lowmaxrate_kc = { visible: true, content: `六合彩限制下线可占成数不能大于 ${this.add_user_data.max_sup_lock}%` });
                    }
                }
                if (this.lowmaxrate_kc == "") {
                    this.lowmaxrate_kc = this.add_user_data.group_id > 3 ? this.add_user_data.max_sup_lock * 1 - this.sup_occupy_lock * 1 : this.add_user_data.max_sup_lock * 1;
                }
            }
            //快彩------------------------------>
            if (this.add_user_data.is_kuaicai == 1) {
                // if (this.kuaicai_auto_draw_back.value != 'all' && this.kuaicai_auto_draw_back.value > this.kuaicai_rebate_max) {
                //     return this.$message.error("赚取退水不得高于" + this.kuaicai_rebate_max);
                // }
                if (this.kuaicai_sup_occupy_lock < 0 || this.kuaicai_sup_occupy_lock === "") {
                    return (this.err.kuaicai_sup_occupy_lock = { visible: true, content: "快彩保留占成不能小于0" });
                }
                if (this.add_user_data.kuaicai_max_sup_lock * 1 != 0) {
                    if (this.kuaicai_allowmaxrate_kc == 1 && this.kuaicai_lowmaxrate_kc < 0) {
                        return (this.err.kuaicai_lowmaxrate_kc = { visible: true, content: "快彩限制下线可占成数不能小于0" });
                    }
                } else {
                    this.kuaicai_lowmaxrate_kc = 0;
                }
                if (this.kuaicai_sup_occupy_lock * 1 > this.add_user_data.kuaicai_max_sup_lock * 1) {
                    return (this.err.kuaicai_sup_occupy_lock = { visible: true, content: `快彩保留占成不能大于 ${this.add_user_data.kuaicai_max_sup_lock}%` });
                }
                if (this.add_user_data.group_id > 3) {
                    if (this.kuaicai_lowmaxrate_kc * 1 > this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1) {
                        return (this.err.kuaicai_lowmaxrate_kc = { visible: true, content: `快彩限制下线可占成数不能大于 ${this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1}%` });
                    }
                } else {
                    if (this.kuaicai_lowmaxrate_kc * 1 > this.add_user_data.kuaicai_max_sup_lock * 1) {
                        return (this.err.kuaicai_lowmaxrate_kc = { visible: true, content: `快彩限制下线可占成数不能大于 ${this.add_user_data.kuaicai_max_sup_lock}%` });
                    }
                }
                if (this.kuaicai_lowmaxrate_kc == "") {
                    this.kuaicai_lowmaxrate_kc = this.add_user_data.group_id > 3 ? this.add_user_data.kuaicai_max_sup_lock * 1 - this.kuaicai_sup_occupy_lock * 1 : this.add_user_data.kuaicai_max_sup_lock * 1;
                }
            }
            if (this.auto_draw_back.value > this.rebate_max) {
                return this.$message.error("退水不得高于" + this.rebate_max);
                // return this.$message.error("六合彩赚取退水不得高于" + this.rebate_max);
            }
            if (Number.isNaN(this.userCredit_kc * 1) || this.userCredit_kc < 0 || this.userCredit_kc > this.add_user_data.userCredit_kc * 1) {
                return (this.err.userCredit_kc = { visible: true, content: "清输入正确的六合额度" });
            }
            if (Number.isNaN(this.kuaicai_userCredit_kc * 1) || this.kuaicai_userCredit_kc < 0 || this.kuaicai_userCredit_kc > this.add_user_data.kuaicai_userCredit_kc * 1) {
                return (this.err.kuaicai_userCredit_kc = { visible: true, content: "清输入正确的快彩额度" });
            }
            if ((this.user_play_status_list.includes("2") || this.user_play_status_list.includes("3") || this.user_play_status_list.includes("4")) && this.userKind_kc.length < 1) {
                return this.$message.error("至少选择一个盘口");
            }
            if (this.user_play_status_list.length == 0) {
                return this.$message.error("至少选择一个彩种");
            }
            let game_change_list = [];
            //生成每个游戏占成修改的数组数据
            for (let i = 0; i < this.play_list.length; i++) {
                game_change_list.push({
                    id: this.play_list[i].id,
                    power_status: this.play_list[i].power_status,
                    // occupy_status: this.play_list[i].occupy_status,
                    occupy_status: 1,
                    // occupy: this.play_list[i].occupy == '' ? 'kong' : this.play_list[i].occupy,
                    occupy: "kong",
                    oid: this.play_list[i].oid,
                    old_occupy: this.play_list[i].old_occupy,
                });
            }

            let obj = {
                sltupuser: this.add_user_data.par_id,
                group_id: 0,
                agent_mark: this.add_user_data.agent_mark,
                userName: this.name,
                userNicker: this.nickname,
                userPassword: this.pwd,
                userKind_kc: this.userKind_kc.toString(),
                kuaicai_userKind_kc: this.kuaicai_userKind_kc.toString(),
                autolet_status: this.autolet_status,
                trader_status: Number(this.trader_status),
                transfer_status: this.transfer_status,
                moneyinout_status: this.moneyinout_status,
                is_inmoney: this.is_inmoney,
                userAllowSale_kc: Number(this.autolet_status),
                allowmaxrate_kc: this.allowmaxrate_kc,
                lowmaxrate_kc: this.lowmaxrate_kc,
                userzhan_kc: "1",
                game_change_list: game_change_list,

                sup_occupy_fly: this.sup_occupy_fly,
                add_oc_url: this.add_oc_url,
                agent_url: this.agent_url,
                note: this.note,
                // sup_occupy_value: this.sup_occupy_value,
                sup_occupy_catch: this.sup_occupy_catch,
                bill_reset: this.bill_reset,
                sup_occupy_lock: this.sup_occupy_lock,
                game_list_status: this.allGamesSet.game_list_status,
                auto_manlet_status: this.auto_manlet_status,
                intercept_proportion_status: this.intercept_proportion_status,
                sltDrawback_kc: this.auto_draw_back.value,
                max_sup_occupy_lock: this.add_user_data.max_sup_lock,
                //快彩-------------
                kuaicai_sltDrawback_kc: this.kuaicai_auto_draw_back.value,
                kuaicai_allowmaxrate_kc: this.kuaicai_allowmaxrate_kc,
                kuaicai_lowmaxrate_kc: this.kuaicai_lowmaxrate_kc,
                kuaicai_sup_occupy_lock: this.kuaicai_sup_occupy_lock,
                max_kuaicai_sup_occupy_lock: this.add_user_data.kuaicai_max_sup_lock,
                //占余成数归
                remainder: this.remainder,
                kuaicai_remainder: this.kuaicai_remainder,
                //彩种
                user_play_status: this.user_play_status_list.toString(),
            };
            if (this.add_user_data.group_id == 2) {
                obj.copy_user = this.add_user_data.copy_list.find((item) => item.id == this.copy_user_id) || "";
            }
            if (this.trader_diff.status === "1") {
                obj.trader_diff = {
                    status: "1",
                    diff: this.trader_diff.diff.toString(),
                };
            } else {
                obj.trader_diff = {
                    status: "0",
                    diff: "",
                };
            }

            if (this.posttime) {
                obj.posttime = this.posttime;
                obj.value_status = 1;
            }
            if (this.userKind_kc.includes("4") || this.userKind_kc.includes("0")) {
                obj.D_odds = this.add_user_data.D_odds;
                obj.sltDrawback_D = ((this.add_user_data.max_rebate / (this.add_user_data.com_odds[1] - this.add_user_data.com_odds[0])) * (this.add_user_data.D_odds - this.add_user_data.com_odds[0])).toFixed(3);
            }
            if (this.add_user_data.extract_bill == 1) {
                obj.extract_bill = this.is_extract;
            }
            if (this.add_user_data.group_id > 1) {
                obj.is_manlet = this.is_manlet;
            }
            this.axios.post("user_version2/member_save", obj).then((result) => {
                if (result.data.status == 200) {
                    this.$message.success(result.data.shortMessage);
                    //刷新列表
                    this.finish_cancel();
                }
            });
        },
        finish_cancel() {
            this.$emit("other_view_back", "");
        },
    },
};
</script>
<style scoped>
span {
    font-size: 12px;
}
</style>
