import axios from '../../../axios/axios';
export default {
  data() {
    return {
      active_gid: '',
      //当前激活的单个gid
      game_list: [],

      /**彩种分组 */
      game_group: [],
      play_param: {
        check_time: ''
      },
      type: "",
      //当前模式，单个/分类
      active_pid: "",
      //当前激活的分类pid
      get_url: "/play/auto_rate_alone",
      //获取数据时的url
      checked_name: ''
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("play/auto_rate", {
      type: 1
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          vm.game_list = vm.$store.state.game_list;
          vm.active_gid = '';
          vm.checked_name = vm.game_list[0].gname; // 通过 `vm` 访问组件实例

          let group = [];
          vm.play_param = "";
          vm.type = 'list';
          let game_group = result.data.repsoneContent.play_arr;
          let play_param = result.data.repsoneContent.play_param;

          for (const key in game_group) {
            game_group[key].pid = key;
            group.push(game_group[key]);
          }

          vm.game_group = group;
          vm.play_param = play_param;

          for (let i = 0; i < vm.game_group.length; i++) {
            for (let j = 0; j < vm.game_group[i].gids.length; j++) {
              if (vm.game_group[i].gids[j] == 1) {
                vm.play_param.min_sec = vm.game_group[i].min_sec;
              }
            }
          }

          vm.active_pid = group[0].pid;
          vm.checked_name = vm.game_group.find(item => item.pid == vm.active_pid).name;
        });
      }
    });
  },

  methods: {
    reset() {
      if (this.type === "one") {
        this.get_game_group(this.type, this.active_gid);
      } else {
        this.get_game_group(this.type, this.active_pid);
      }
    },

    //提交修改
    sub_change() {
      // if (this.play_param.check_time < this.play_param.min_sec * 1) {
      //     return this.$message.error('当前设置的提前封盘时间不得低于' + this.play_param.min_sec + "秒");
      // }
      let url = "";
      let post_obj = "";

      if (this.type === "one") {
        url = "play/system_init_set_alone";
        post_obj = {
          gid: this.active_gid,
          check_time: this.play_param.check_time,
          check_time_night: this.play_param.check_time_night,
          twosides_switch: this.play_param.twosides_switch,
          proportion: this.play_param.proportion,
          twosides_proport: this.play_param.twosides_proportion
        };
      } else {
        url = "play/system_init_set";
        post_obj = {
          pgid: this.active_pid,
          check_time: this.play_param.check_time,
          check_time_night: this.play_param.check_time_night,
          twosides_switch: this.play_param.twosides_switch,
          proportion: this.play_param.proportion,
          twosides_proport: this.play_param.twosides_proportion
        };
      }

      this.axios.post(url, post_obj).then(result => {
        if (result.data.status == 200) {
          this.$message.success(result.data.shortMessage);
        }
      });
    },

    //切换
    change_group(pid) {
      this.type = "list";
      this.get_game_group(this.type, pid);
    },

    change_game(gid) {
      this.type = "one";
      this.get_game_group(this.type, gid);
    },

    //获取彩种数据
    get_game_group(type, id) {
      this.type = type;

      if (type === "one") {
        this.active_pid = '';
        this.active_gid = id;
        this.get_url = "/play/auto_rate_alone";
      } else {
        this.active_gid = "";
        this.active_pid = id;
        this.get_url = "/play/auto_rate";
      }

      this.axios.post(this.get_url, {
        type: id
      }).then(result => {
        if (result.data.status == 200) {
          let group = [];
          this.play_param = "";
          let game_group = result.data.repsoneContent.play_arr;
          let play_param = result.data.repsoneContent.play_param;

          for (const key in game_group) {
            game_group[key].pid = key;
            group.push(game_group[key]);
          }

          this.game_group = group;
          this.play_param = play_param;

          for (let i = 0; i < this.game_group.length; i++) {
            for (let j = 0; j < this.game_group[i].gids.length; j++) {
              if (this.game_group[i].gids[j] == id) {
                this.play_param.min_sec = this.game_group[i].min_sec;
              }
            }
          }

          if (type === "one") {
            this.checked_name = this.game_list.find(item => item.id == this.active_gid).gname;
          } else {
            this.checked_name = this.game_group.find(item => item.pid == this.active_pid).name;
          }

          console.log(this.checked_name);
        }
      });
    }

  }
};