import zhudanmingxi from './zhudanmingxi.vue';
export default {
  props: ["form", 'search_title_info'],
  components: {
    zhudanmingxi
  },

  data() {
    return {
      data_list: [],
      total: "",
      page: 1,
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      dialog: {
        visible: false
      },
      rowinfo: "",
      autolet: [{
        result: '0'
      }],
      //补货表
      search_paly_list: []
    };
  },

  created() {},

  methods: {
    search_paly(row) {
      let obj = {
        gid: row.play_id,
        BeginDate: this.form.date.start,
        EndDate: this.form.date.end,
        t_status: this.form.settle_state,
        period: this.form.qid,
        page: 1,
        limit: 40,
        is_select: 0,
        username: this.form.username
      };

      if (row.method_name != undefined && row.method_name != '' && !row.method_name.includes('-')) {
        obj.level_two = row.level_two || '';
      }

      if (row.method_name != undefined && row.method_name != '' && row.method_name.includes('-')) {
        obj.level_two = row.level_two || '';
        obj.level_three = row.level_three || '';
      }

      this.axios.post("operate/ReportList_T_kc", obj).then(result => {
        if (result.data.status == 200) {
          let data = result.data.repsoneContent;
          let tmlist = []; //将特码移到最前面

          data.list.forEach((item, index) => {
            if (item.method_name == '特码') {
              tmlist.push(item);
              data.list.splice(index, 1);
            }
          });
          data.list.forEach((item, index) => {
            if (item.method_name == '特码B') {
              tmlist.push(item);
              data.list.splice(index, 1);
            }
          });
          data.list = [...tmlist, ...data.list];
          this.proc_data(data);
          this.search_paly_list.push(data);
        }
      });
    },

    //报表列的颜色
    cellClassName({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      if (columnIndex != 0 && columnIndex != 1) {
        //合计列
        this.$nextTick(() => {
          let dom = this.$refs.tableRef.$refs.footerWrapper.querySelector('.el-table__footer').querySelectorAll('td')[columnIndex].querySelectorAll('.cell')[0];
          let val = this.$refs.tableRef.$refs.footerWrapper.querySelector('.el-table__footer').querySelectorAll('td')[columnIndex].querySelectorAll('.cell')[0].innerText;
          dom.style.color = !Number.isNaN(val) && val < 0 ? 'red' : '#000';
        }); //数据列

        let columnProperty = this.data_list[rowIndex][column.property];

        if (columnProperty < 0) {
          return 'redcolor'; // class名称
        } else {
          return 'blackcolor'; // class名称
        }
      }
    },

    //报表合计
    baobiao_sum(param) {
      let {
        columns,
        data
      } = param;
      let sums = [];
      columns.forEach((column, index) => {
        // console.log(column.label);
        if (column.label === '分类') {
          return sums[index] = '合计';
        }

        if (column.label === '笔数') {
          return sums[index] = this.total.count;
        }

        if (column.label === '总投注额') {
          return sums[index] = this.total.bet_price;
        }

        if (column.label === '有效金额') {
          return sums[index] = this.total.bet_price;
        }

        if (column.label === '会员输赢') {
          return sums[index] = this.total.win_price;
        }

        if (column.label === '应收下线') {
          return sums[index] = this.total.receive_subline;
        }

        if (column.label === '实占成数') {
          return sums[index] = '';
        }

        if (column.label === '实占注额') {
          return sums[index] = this.total.occupy_balance;
        }

        if (column.label === '占货比') {
          return sums[index] = '';
        }

        if (column.label === '实占输赢') {
          return sums[index] = this.total.occupy_winlose;
        }

        if (column.label === '实占退水') {
          return sums[index] = this.total.occupy_rebate;
        }

        if (column.label === '实占结果') {
          return sums[index] = this.total.real_occupy_result;
        }

        if (column.label === '实占结果') {
          return sums[index] = this.total.a_rebate;
        }

        if (column.label === '赚取退水') {
          return sums[index] = this.total.earn;
        }

        if (column.label === '退水后结果') {
          return sums[index] = this.total.real_result;
        }

        if (column.label === '贡献上级') {
          return sums[index] = this.total.cont_sup;
        }

        if (column.label === '应付上级') {
          return sums[index] = this.total.receive_supline;
        }
      });
      return sums;
    },

    back_search() {
      if (this.search_paly_list.length == 1) {
        this.$emit('back_search');
      } else {
        this.search_paly_list.length -= 1;
        this.proc_data(this.search_paly_list[this.search_paly_list.length - 1]);
      }
    },

    back_list() {
      this.dialog.page = 1;
      this.$emit("change_page", this.page);
    },

    //补货详情
    get_buhuo_detail(fly = '', is_fly = '1') {
      this.search_detail(this.rowinfo, '', '', 1);
    },

    //注单明细
    search_detail(row) {
      let obj = {
        gid: row.play_id,
        userid: "",
        phaseid: this.form.qid,
        page: 1,
        limit: 20,
        t_status: this.form.settle_state,
        m_type: "zd",
        up_id: this.$store.state.userinfo.user_id,
        // level_two: row.level_two,
        // level_three: row.level_three,
        // level_four: row.level_four,
        BeginDate: this.form.date.start,
        EndDate: this.form.date.end,
        bet_count: row.bet_count
      };

      if (row.method_name != undefined && row.method_name != '' && !row.method_name.includes('-')) {
        obj.level_two = row.level_two || '';
      }

      if (row.method_name != undefined && row.method_name != '' && row.method_name.includes('-')) {
        obj.level_two = row.level_two || '';
        obj.level_three = row.level_three || '';
      }

      this.rowinfo = obj;
      this.axios.post("operate/ReportDetail_B_kc", obj).then(result => {
        if (result.data.status == 200) {
          this.dialog.visible = true;
          this.$nextTick(() => {
            this.$refs['zhudanmingxi'].proc_data(result.data.repsoneContent);
          });
        }
      });
    },

    change_page(page) {
      this.page = page;
      this.$emit("change_page", page);
    },

    proc_data(data) {
      let tmlist = []; //将特码移到最前面

      data.list.forEach((item, index) => {
        if (item.method_name == '特码') {
          tmlist.push(item);
          data.list.splice(index, 1);
        }
      });
      data.list.forEach((item, index) => {
        if (item.method_name == '特码B') {
          tmlist.push(item);
          data.list.splice(index, 1);
        }
      });
      data.list = [...tmlist, ...data.list];

      if (this.search_paly_list.length == 0) {
        this.search_paly_list.push(data);
      }

      this.data_list = data.list;

      for (let i = 0; i < this.data_list.length; i++) {
        this.data_list[i].real_result = (this.data_list[i].occupy * 1 + this.data_list[i].a_rebate * 1).toFixed(0);
        this.data_list[i].real_pre = (this.data_list[i].a_rebate_pre * 1 + this.data_list[i].occupy_pre * 1).toFixed(1);
      }

      this.autolet = [{
        result: ''
      }];
      let autolet = ''; // console.log(data);

      if (data.autolet != undefined) {
        autolet = data.autolet;
        autolet.result = autolet.let_balance + autolet.rebate_balance;
        this.autolet[0] = autolet;
      }

      this.pagination = data.pagination;

      if (this.data_list.length > 0) {
        this.total = data.total;
      } else {
        this.total = {
          a_rebate: 0,
          bet_price: 0,
          rebate: 0,
          win_price: 0,
          earn: 0,
          count: 0,
          occupy: 0,
          balance: 0,
          occupy_balance: 0,
          occupy_winlose: 0,
          occupy_rebate: 0,
          bonus: 0,
          occupy_pre: 0,
          a_rebate_pre: 0
        };
      }

      this.total.real_result = this.total.occupy * 1 + this.total.a_rebate * 1;
      this.total.real_pre = this.total.a_rebate_pre * 1 + this.total.occupy_pre * 1;
      this.dialog.visible = false;
    }

  }
};