<!--
 * @Author: your name
 * @Date: 2022-03-13 17:20:20
 * @LastEditTime: 2023-10-20 22:22:12
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\components\home\usermenu.vue
-->
<template>
    <div class="usermenu">
        <div class="main-menu themeColorhalf-bg">
            <div v-show="$store.state.userinfo.status != 2 && show_gamelist && has_instant" class="navBox theme-border" @mouseenter="mouseenter" @mouseleave="mouseleave">
                <div class="up-down-icon theme-text"></div>
                <span @click="click_game_list">{{ gname }}</span>
                <div class="navList theme-border light-bg" v-show="show_list">
                    <ul style="list-style-type: none; padding: 0; margin: 0; float: left">
                        <li v-for="item in game_list" :key="item.id" @click="change_game(item.id)">
                            <span class="gname">{{ item.gname }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="navBox theme-border" v-show="!show_gamelist" style="color: red">暂无彩种</div>
            <div style="margin-left: 10px">
                <span v-show="show_menulist">
                    <div v-for="item in menulist" :key="item.title" :class="`mian-menu theme-border ${item.menu_name}`" :style="{ backgroundColor: item.bg, color: item.color }" @click="click_mian_menu(item)">{{ item.title }}</div>
                </span>
                <div class="subuser" v-show="sub.list.length > 0">
                    <el-select v-model="sub.user" class="subuser-select" size="small" placeholder="查看下级" clearable filterable @change="change_sub">
                        <el-option-group v-for="group in sub.list" :key="group.label" :label="group.label">
                            <el-option v-for="item in group.options" :key="item.id" :label="item.username" :value="item.id" />
                        </el-option-group>
                    </el-select>
                    <el-button v-show="$store.state.watchsub_show" class="back-self" type="primary" size="small" @click="change_sub('')" title="取消查看下级">返回</el-button>
                </div>
            </div>
        </div>
        <div class="sub-menu" v-show="show_submenu">
            <div class="sub-menu-item half-border" :style="{ backgroundColor: item.bg, color: item.color }" v-for="item in submenu" :key="item.index" @click.prevent="submenu_change(item.title)">{{ item.title }}</div>
            <!-- <el-radio-group v-model="activated_sub" size="small" @change="submenu_change">
                <el-radio-button v-for="item in submenu" :key="item.index" :label="item.title" />
            </el-radio-group>-->
        </div>
        <el-dialog v-model="kjzb_dialog.visible" width="520px" class="mydialogclass" destroy-on-close draggable @close="close_kjzb">
            <template #header>
                <span class="mydialogtitle">开奖直播</span>
            </template>
            <video id="videoElement" crossorigin="anonymous" controls autoplay muted width="500" height="300" />
            <template #footer>
                <span class="dialog-footer">
                    <el-button size="small" type="warning" @click="close_kjzb">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import vPinyin from "../../plugin/pinyin";
import { jszd_menu } from "../../plugin/jszd_menu";
import flvjs from "flv.js";
export default {
    data() {
        return {
            has_instant: false,
            game_list: [],
            menulist: [],
            submenu: [],
            activated_sup: "",
            activated_sub: "",
            gid: "",
            gname: "",
            show_gamelist: true,
            show_list: false,
            show_menulist: false,
            show_submenu: false,
            last_sup: "",
            last_sub: "",
            sub: {
                user: "",
                list: [],
            },
            kjzb_dialog: {
                visible: false,
                url: "",
            },
            player: null,
        };
    },
    created() {
        if (this.$store.state.menulist.length > 0) {
            this.get_menulist();
        }
        this.get_game_list();
        this.get_user_menu();
    },
    beforeDestroy() {
        this.destoryVideo();
    },
    methods: {
        //缓存及时注单六合彩首页tmab数据
        get_cacheData() {
            if (this.gid == 20 || this.gid == 34 || this.gid == 48 || this.gid == 49) {
                this.axios
                    .post("trader/index_header", {
                        gid: this.gid,
                        pk: "0",
                        user_id: "",
                    })
                    .then((result) => {
                        if (result.data.status == 200) {
                            let instant_gameinfo = result.data.repsoneContent;
                            this.$store.state.instant_gameinfo = instant_gameinfo;
                            this.axios
                                .post("trader/index?ball=tm", {
                                    gid: this.gid,
                                    ball: "tm",
                                    pk: "0",
                                    type: "0",
                                    szsz: "1",
                                    periods_status: instant_gameinfo.nn == instant_gameinfo.upopenphase ? 1 : 0,
                                    ws: instant_gameinfo.result_res.ws ? instant_gameinfo.result_res.ws : "",
                                    zodiac: instant_gameinfo.result_res.zodiac ? instant_gameinfo.result_res.zodiac : "",
                                })
                                .then((result) => {
                                    if (result.data.status == 200) {
                                        this.$store.state.instant_cacheData["tm"] = result.data.repsoneContent;
                                    }
                                });
                        }
                    });
            }
        },
        close_kjzb() {
            this.destoryVideo();
            this.kjzb_dialog.visible = false;
        },
        destoryVideo() {
            if (this.player) {
                this.player.pause(); // 暂停播放数据流
                this.player.unload(); // 取消数据流加载
                this.player.detachMediaElement(); // 将播放实例从节点中取出
                this.player.destroy(); // 销毁播放实例
                this.player = null;
            }
        },
        //flvjs直播
        createVideo() {
            if (flvjs.isSupported()) {
                var videoElement = document.getElementById("videoElement");
                this.player = flvjs.createPlayer(
                    {
                        type: "flv",
                        isLive: true,
                        hasAudio: true,
                        // url: 'https://vdm6-ali.bemerswag.com/live/a6.flv'// flv视频流地址
                        url: "https://vdm6-cnts.onmve.com/live/a6-2.flv", // flv视频流地址
                    },
                    {
                        cors: true, // 是否跨域
                        // enableWorker: true, // 是否多线程工作
                        enableStashBuffer: false, // 是否启用缓存
                        // stashInitialSize: 384, // 缓存大小(kb)  默认384kb
                        autoCleanupSourceBuffer: true, // 是否自动清理缓存
                        isLive: true,
                    }
                );
                this.player.attachMediaElement(videoElement); //挂载元素
                this.player.load(); //加载流
                this.player.play(); //播放流
            }
            // 报错重连
            this.player.on(flvjs.Events.ERROR, (err, errdet) => {
                // 参数 err 是一级异常，errdet 是二级异常
                if (err == flvjs.ErrorTypes.MEDIA_ERROR) {
                    console.log("媒体错误");
                    this.$message.error("媒体错误");
                    if (errdet == flvjs.ErrorDetails.MEDIA_FORMAT_UNSUPPORTED) {
                        console.log("媒体格式不支持");
                    }
                }
                if (err == flvjs.ErrorTypes.NETWORK_ERROR) {
                    console.log("网络错误");
                    this.$message.error("网络错误,请重试");
                    if (errdet == flvjs.ErrorDetails.NETWORK_STATUS_CODE_INVALID) {
                        console.log("http状态码异常");
                    }
                }
                if (err == flvjs.ErrorTypes.OTHER_ERROR) {
                    console.log("其他异常：", errdet);
                }
                if (this.player) {
                    this.destoryVideo();
                    // this.createVideo()
                }
            });
        },
        get_game_list() {
            this.axios.post("main/game_list").then((result) => {
                if (result.data.status === 200) {
                    this.$store.state.game_list = result.data.repsoneContent.game_list;
                    this.game_list = this.$store.state.game_list;
                    if (this.game_list.length > 0) {
                        if (this.$store.state.instant_checked_gid !== "") {
                            this.gid = this.$store.state.instant_checked_gid;
                            this.gname = this.$store.state.instant_checked_gname;
                        } else {
                            this.$store.state.instant_checked_gid = this.gid = this.game_list[0].id;
                            this.$store.state.instant_checked_gname = this.gname = this.game_list[0].gname;
                        }
                        let g = this.game_list.find((item) => item.gname == this.gname);
                        if (g == undefined) {
                            this.gname = this.game_list[0].gname;
                        }
                        this.get_cacheData();
                    } else {
                        this.show_gamelist = false;
                    }
                }
            });
        },
        //切换到下级页面
        change_sub(user_id) {
            // this.$emit('get_balance');

            this.click_mian_menu({
                menu_name: "notice",
                bg: this.$store.state.themeColor,
                color: "#fff",
                index: 1,
            });
            let user_info = "";
            let act = "";
            if (user_id != "") {
                this.sub.list.forEach((item) => {
                    item.options.forEach((user) => {
                        if (user.id == user_id) {
                            user_info = user;
                            this.$store.state.watchsub.group_name = item.label;
                            this.$store.state.watchsub.username = user.username;
                        }
                    });
                });
                act = "add";
                this.$store.state.watchsub_show = true;
            } else {
                this.sub.user = "";
                act = "del";
            }
            this.axios
                .post("user_version2/change_user", {
                    ...user_info,
                    act,
                })
                .then((result) => {
                    if (result.data.status === 200 && result.data.shortMessage == "切换成功") {
                        if (act == "add") {
                            this.$store.state.watchsub_sub_id = user_id;
                            this.$message.success("切换到下级成功！只能查看数据，不能修改，增加，删除数据！");
                        }
                        if (act == "del" && result.data.repsoneContent == "") {
                            this.$store.state.watchsub_show = false;
                            this.$store.state.watchsub_sub_id = "";
                            this.$message.success("切换到自己成功");
                            this.axios
                                .post("user_version2/change_user", {
                                    ...user_info,
                                    act: "check",
                                })
                                .then((result) => {
                                    if (result.data.repsoneContent.is_change_user == 1 && result.data.repsoneContent.sub_user_id != this.$store.state.userinfo.user_id) {
                                        this.$message.error("返回自己失败，请重试");
                                        this.$store.state.watchsub_show = true;
                                    }
                                });
                        }
                        this.show_menulist = false;
                        this.show_submenu = false;
                        this.get_user_menu();
                        this.get_game_list();
                        this.$emit("get_balance");
                        //获取网站名称
                        if (this.$store.state.userinfo.group_id == 1) {
                            this.axios.get(`/login/get_web_info?url=${window.location.host}`).then((result) => {
                                if (result.data.status == 1 && result.data.msg.web_name != "") {
                                    // document.title = result.data.msg.web_name;
                                    this.$store.state.web_name = result.data.msg.web_name;
                                }
                            });
                        }
                    }
                });
        },
        //获取下级用户
        get_sublist() {
            this.axios.post("user_version2/member_xs_list").then((result) => {
                if (result.data.status === 200) {
                    let member_list = result.data.repsoneContent;
                    let list = [
                        {
                            label: "总公司",
                            options: [],
                        },
                        {
                            label: "大总监",
                            options: [],
                        },
                        {
                            label: "总监",
                            options: [],
                        },
                        {
                            label: "分公司",
                            options: [],
                        },
                        {
                            label: "大股东",
                            options: [],
                        },
                        {
                            label: "股东",
                            options: [],
                        },
                        {
                            label: "总代理",
                            options: [],
                        },
                        {
                            label: "代理",
                            options: [],
                        },
                    ];
                    member_list.forEach((item) => {
                        list[item.group_id - 1].options.push(item);
                    });
                    let newlist = [];
                    list.forEach((item) => {
                        if (item.options.length > 0) {
                            newlist.push(item);
                        }
                    });
                    this.sub.list = newlist;
                    if (this.$store.state.watchsub_show) {
                        this.sub.user = this.$store.state.watchsub_sub_id;
                    }
                    // console.log(this.sub.list);
                }
            });
        },
        change_color(color) {
            this.menulist.forEach((item) => {
                // console.log(item.color);
                if (item.color == "#fff") {
                    item.bg = color;
                }
            });
        },
        click_game_list() {
            this.show_list = !this.show_list;
        },
        mouseenter(s) {
            this.show_list = true;
        },
        mouseleave(s) {
            this.show_list = false;
        },
        change_game(gid) {
            this.gid = this.$store.state.instant_checked_gid = gid;
            this.gname = this.$store.state.instant_checked_gname = this.game_list.find((item) => item.id == gid).gname;
            this.show_list = false;
            this.$store.state.instant_gameinfo = "";
            this.click_mian_menu({
                menu_name: "instant",
                bg: "#fff",
                color: "#333",
                index: 8,
            });
        },
        confirm_quit() {
            this.axios.post("login/logout").then((result) => {
                if (result.data.status === 200 && result.data.shortMessage === "已退出登录") {
                    sessionStorage.clear();
                    this.$store.state.isLogin = false;
                    this.$store.state.notice = true;
                    // this.quit = false;
                    if (this.$store.state.userinfo.group_id != 1) {
                        window.location.replace(result.data.repsoneContent);
                    } else {
                        this.$store.state.state = {
                            userinfo: {
                                user_id: "",
                            },
                            themeColor: "",
                            themeColorhalf: "",
                            themeColorLight: "",
                            game_list: [],
                            gid: "",
                            instant_gameinfo: {
                                upopennumber: "",
                            },
                            instant_checked_gid: "",
                            instant_checked_gname: "",
                            activated_sup: "notice",
                            int_router_view: false, //初始化路由页面
                            notice_tc: {
                                create_time: "0",
                                text: "",
                            },
                            instant_pk: "0",
                            viewdata: {},
                            watch_sub: {
                                //查看下级模式
                                show: false,
                                sub_id: "",
                            },
                        };
                        this.$router.replace("/");
                    }
                }
            });
        },
        submenu_change(label) {
            this.last_sub = this.activated_sub;
            this.click_sub_menu(this.submenu.find((item) => item.title == label));
        },
        //小菜单
        click_sub_menu(menu, params = "", type = "") {
            // console.log(menu, params, type);
            this.activated_sub = menu.title;
            // console.log(this.last_sup, this.activated_sup);
            //小菜单是否重复点击，来刷新数据
            if (this.last_sub == this.activated_sub) {
                this.$store.state.relod_sub_menu_data = !this.$store.state.relod_sub_menu_data;
            }
            this.$store.state.checked_menu = menu;
            let sub_routename = vPinyin.chineseToPinYin(menu.title);
            if (this.activated_sup == "instant") {
                if (type != "") {
                    this.axios
                        .post("trader/index_header", {
                            gid: this.gid,
                            pk: "0",
                            user_id: "",
                        })
                        .then((result) => {
                            if (result.data.status == 200) {
                                this.$store.state.instant_gameinfo = result.data.repsoneContent;
                                this.$router.push({
                                    path: "/home/instant/instant",
                                    name: "instant",
                                    query: menu,
                                });
                            }
                        });
                } else {
                    this.$router.push({
                        path: "/home/instant/instant",
                        name: "instant",
                        query: menu,
                    });
                }
            } else {
                let pushobj = {
                    path: `/home/${this.activated_sup}/${sub_routename}`,
                    name: sub_routename,
                    query: {},
                };
                if (sub_routename == "lishikaijiang" || sub_routename == "xitongrizhi" || sub_routename == "zidongbuhuosheding" || sub_routename == "wanfazhuangtai") {
                    pushobj.query = { gid: this.gid, gname: this.gname };
                    this.$router.push(pushobj);
                }
                if (sub_routename == "peilvsheding") {
                    pushobj.query = { user_id: this.$store.state.userinfo.user_id };
                    this.$router.push(pushobj);
                }
                if (sub_routename == "tuishuishezhi") {
                    let gids = [];
                    let all_gids = [];
                    let userinfo = this.$store.state.userinfo;

                    this.$store.state.game_list.forEach((item) => {
                        gids.push(item.id);
                        all_gids.push(item.id);
                    });
                    pushobj.query = {
                        uid: userinfo.pid,
                        level_id: userinfo.level_id,
                        group_id: userinfo.group_id,
                        gids: gids,
                        all_gids: all_gids,
                    };
                    this.$router.push(pushobj);
                } else if (sub_routename == "zongjian" || sub_routename == "fengongsi" || sub_routename == "dagudong" || sub_routename == "gudong" || sub_routename == "zongdaili" || sub_routename == "daili" || sub_routename == "huiyuan") {
                    pushobj.query = { uid: params };
                    this.$router.push(pushobj);
                } else if (sub_routename == "gerenziliao") {
                    let gids = [];
                    this.game_list.forEach((item) => {
                        gids.push(item.id);
                    });
                    if (gids.length > 4) {
                        gids.length = 4;
                    }
                    // console.log(gids);
                    this.axios.post("user_version2/index", { gids }).then((result) => {
                        if (result.data.status == 200) {
                            this.$store.state.viewdata = result.data.repsoneContent;
                            this.$router.push(pushobj);
                        }
                    });
                } else {
                    this.$router.push(pushobj);
                }
            }
            this.$nextTick(() => {
                this.submenu.forEach((item) => {
                    if (item.title == menu.title) {
                        item.color = "#fff";
                        item.bg = this.$store.state.themeColor;
                    } else {
                        item.bg = "#fff";
                        item.color = "#333";
                    }
                });
            });
            //判断是否路由页面要重新初始化
            if (this.sub_routename == "baobiaochaxun") {
                this.$store.state.int_router_view = !this.$store.state.int_router_view;
            }
        },
        //主菜单
        click_mian_menu(item) {
            // console.log(item);
            if (item.menu_name != "kjzb" && item.menu_name != "quit") {
                this.show_submenu = false;
            }
            // this.submenu = []
            let menu = item.menu_name;
            this.menulist.forEach((item) => {
                item.bg = "#fff";
                item.color = "#333";
                if (item.menu_name == menu) {
                    item.color = "#fff";
                    item.bg = this.$store.state.themeColor;
                }
            });

            let submenu = [];
            this.last_sup = this.activated_sup;
            this.$store.state.activated_sup = this.activated_sup = menu;
            //点击开奖直播
            if (menu == "kjzb") {
                this.activated_sup = this.$route.matched[1].name;
                this.menulist.forEach((item) => {
                    item.bg = "#fff";
                    item.color = "#333";
                    if (item.menu_name == this.activated_sup) {
                        item.color = "#fff";
                        item.bg = this.$store.state.themeColor;
                    }
                });
                if (this.$store.state.userinfo.website_name == "77test") {
                    this.kjzb_dialog.visible = true;
                    this.$nextTick(() => {
                        this.createVideo();
                    });
                } else if (this.$store.state.userinfo.website_name == "xinaoliutwo") {
                    //新aoliu
                    return window.open("https://00853lhc.com");
                } else if (this.$store.state.userinfo.website_name == "aoliu") {
                    //老aoliu
                    return window.open("https://a6tk23.com");
                }
            } else if (menu == "quit") {
                // this.quit = true;
                this.$messageBox
                    .confirm("确认退出吗？", "操作确认", {
                        customClass: "myMessageBox",
                        customStyle: {
                            backgroundColor: this.$store.state.themeColorLight,
                            border: ` 2px solid`,
                            borderColor: ` ${this.$store.state.themeColor}`,
                            width: "200px",
                        },
                    })
                    .then(() => {
                        this.confirm_quit();
                    })
                    .catch(() => {
                        this.activated_sup = this.$route.matched[1].name;
                        // console.log(this.activated_sup);
                        this.menulist.forEach((item) => {
                            item.bg = "#fff";
                            item.color = "#333";
                            if (item.menu_name == this.activated_sup) {
                                item.color = "#fff";
                                item.bg = this.$store.state.themeColor;
                            }
                        });
                    });
            } else {
                if (this.activated_sup == "instant") {
                    submenu = jszd_menu(this.gid);
                } else {
                    // console.log(menu, this.menulist);
                    submenu = this.menulist.find((item) => item.menu_name == menu).ut.ut;
                }
                // console.log(this.gid);
                this.submenu = submenu;
                this.show_submenu = true;
                this.click_sub_menu(submenu[0], "", "init");
            }
            if (menu == "reportmain" && this.last_sup == "reportmain") {
                this.$store.state.int_router_view = !this.$store.state.int_router_view;
            }
            if (menu == "history" && this.last_sup == "history") {
                this.$store.state.int_router_view = !this.$store.state.int_router_view;
            }
            this.$store.state.now_menu = item;
        },
        /**获取用户菜单权限 */
        get_user_menu() {
            // this.menulist = [];
            this.axios.post("user_version2/user_menu", { token: this.$store.state.userinfo.token, user: this.$store.state.userinfo.user_id }).then((result) => {
                if (result.data.status == 200 && result.data.shortMessage === "获取菜单成功") {
                    let menu = result.data.repsoneContent.menulist;
                    this.$store.state.menulist = JSON.parse(JSON.stringify(menu));
                    this.get_menulist();
                }
            });
        },
        //生成菜单
        get_menulist() {
            let game_list = this.$store.state.game_list;
            let menu = JSON.parse(JSON.stringify(this.$store.state.menulist));
            //仅有六合彩不显示兑奖规则
            //总监不显示兑奖规则
            let lhc = [20, 34, 49];
            if (game_list.length <= lhc.length && this.$store.state.userinfo.group_id != 3) {
                for (let i = 0; i < game_list.length; i++) {
                    if (lhc.includes(game_list[i].id)) {
                        // this.show_djgz = false;
                    } else {
                        menu.push({
                            title: "兑奖规则",
                            menu_name: "djgz",
                            index: 10,
                            bg: "#fff",
                            color: "#333",
                            ut: {
                                ut: [
                                    {
                                        link: "/play/rules",
                                        title: "兑奖规则",
                                    },
                                ],
                            },
                        });
                    }
                }
            }
            //根据平台来判断是否有开奖直播
            // let has_kjzb = false;
            // if (this.$store.state.userinfo.website_name == "77test") {
            //     has_kjzb = true;
            // } else if (this.$store.state.userinfo.website_name == "xinaoliutwo") {
            //     //新aoliu
            //     has_kjzb = true;
            // } else if (this.$store.state.userinfo.website_name == "aoliu") {
            //     //老aoliu
            //     has_kjzb = true;
            // } else {
            //     has_kjzb = false;
            // }
            if (this.$store.state.userinfo.kj_url != undefined && this.$store.state.userinfo.kj_url != null && this.$store.state.userinfo.kj_url != "") {
                menu.push({
                    title: "开奖直播",
                    menu_name: "kjzb",
                    index: 10,
                    bg: "#fff",
                    color: "#333",
                    ut: {
                        ut: [
                            {
                                link: "",
                                title: "开奖直播",
                            },
                        ],
                    },
                });
            }
            menu.push({
                title: "安全退出",
                menu_name: "quit",
                index: 0,
                bg: "#fff",
                color: "#333",
            });
            menu.forEach((item) => {
                if (item.title == "站内消息") {
                    item.menu_name = "notice";
                    item.bg = "#fff";
                    item.color = "#333";
                    item.index = 1;
                }
                if (item.title == "历史开奖") {
                    item.menu_name = "history";
                    item.bg = "#fff";
                    item.color = "#333";
                    item.index = 2;
                }
                if (item.title == "报表查询") {
                    item.menu_name = "reportmain";
                    item.bg = "#fff";
                    item.color = "#333";
                    item.index = 3;
                }
                if (item.title == "个人管理") {
                    item.menu_name = "self";
                    item.bg = "#fff";
                    item.color = "#333";
                    item.index = 4;
                }
                if (item.title == "内部管理") {
                    item.menu_name = "inner";
                    item.bg = "#fff";
                    item.color = "#333";
                    item.index = 5;
                }
                if (item.title == "数据统计") {
                    item.menu_name = "statistics";
                    item.bg = "#fff";
                    item.color = "#333";
                    item.index = 6;
                }
                if (item.title == "用户管理") {
                    item.menu_name = "user";
                    item.bg = "#fff";
                    item.color = "#333";
                    item.index = 7;
                    this.get_sublist();
                }
                if (item.title == "即时注单") {
                    item.menu_name = "instant";
                    item.bg = "#fff";
                    item.color = "#333";
                    item.index = 8;
                    this.has_instant = true;
                }
                if (item.title == "开奖直播") {
                    item.menu_name = "kjzb";
                    item.bg = "#fff";
                    item.color = "#333";
                    item.index = 9;
                }
                if (item.title == "站内消息" || item.title == "历史开奖" || item.title == "报表查询" || item.title == "个人管理" || item.title == "内部管理" || item.title == "数据统计" || item.title == "用户管理") {
                    for (let sm of item.ut.ut) {
                        sm.bg = "#fff";
                        sm.color = "#333";
                    }
                }
            });
            this.menulist = menu;
            this.show_menulist = true;
            this.show_submenu = true;
            if (this.$store.state.now_menu != "") {
                // console.log(this.$store.state.now_menu);
                if (this.$store.state.now_menu.title == "即时注单") {
                    this.gid = this.$store.state.instant_checked_gid;
                }
                this.click_mian_menu(this.$store.state.now_menu);
            } else {
                // console.log(menu.find((item) => item.menu_name == "notice"));
                if (menu.find((item) => item.menu_name == "notice")) {
                    this.click_mian_menu({
                        menu_name: "notice",
                        bg: this.$store.state.themeColor,
                        color: "#fff",
                        index: 1,
                    });
                } else {
                    this.click_mian_menu({
                        menu_name: "user",
                        bg: this.$store.state.themeColor,
                        color: "#fff",
                        index: 1,
                    });
                }
            }
        },
    },
};
</script>

<style scoped>
.mian-menu {
    display: inline-block;
    width: 70px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border: 1px solid;
    border-right: 0;
    cursor: pointer;
}
.quit {
    border-right: 1px solid;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.mian-menu:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.sub-menu {
    width: auto;
    height: 26px;
    padding: 2px 5px;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
.sub-menu-item {
    display: inline-block;
    width: auto;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border: 1px solid;
    border-right: 0;
    cursor: pointer;
    padding: 0 10px;
}
.sub-menu :last-child {
    border-right: 1px solid;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}
.sub-menu :first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.gname:hover {
    background: url("../../assets/menu_a_bg.gif") no-repeat scroll 12px center #ffff2b;
}

.gname {
    background: url("../../assets/menu_a_bg.gif") no-repeat scroll 12px center #fff;
    border-radius: 0;
    display: inline-block;
    font-size: 12px;
    height: 28px;
    color: #000;
    line-height: 28px;
    padding-left: 26px;
    width: 118px;
    border-bottom: 1px dotted;
}

.navList {
    position: absolute;
    width: 100%;
    top: 100%;
    left: -1px;
    border: 1px solid;
    z-index: 4;
    border-bottom: 0;
    box-shadow: 0 3px 3px 0 #eee;
}

.up-down-icon {
    width: 10px;
    height: 5px;
    float: right;
    margin-top: 11px;
    font-size: 10px;
    background: url("../../assets/down.gif") no-repeat;
}

.navBox {
    width: 130px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    float: left;
    padding: 0 7px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid;
    cursor: pointer;
    margin-right: 7px;
    position: relative;
    background: #fff;
}

.sub-menu b {
    font-weight: normal;
    color: #ced5c2;
    padding: 0 4px;
    font-size: 12px;
}

.main-menu {
    height: 30px;
    padding: 4px 0 0 5px;
}

.usermenu {
    text-align: left;
}

.usermenu button {
    width: 70px;
    height: 26px;
    border: 1px solid #afbf87;
    box-shadow: 0px 1px 0px #ced5c3;
    border-left: 0;
}

.usermenu button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.usermenu button:first-child {
    border-left: 1px solid #afbf87;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.subuser {
    float: right;
    margin-right: 3px;
}
.subuser-select {
    width: 90px;
    margin-bottom: 2px;
}
.subuser .back-self {
    width: 36px;
    margin-left: 2px;
    margin-bottom: 2px;
}
</style>