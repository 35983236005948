export default {
  components: {},

  data() {
    return {
      userinfo: [],
      gamesinfo: []
    };
  },

  created() {
    this.userinfo = [this.$store.state.viewdata];
    console.log(this.userinfo[0].group_id);

    if (this.userinfo[0].group_id == 1) {
      this.userinfo[0].group_name = '总公司';
    }

    if (this.userinfo[0].group_id == 2) {
      this.userinfo[0].group_name = '大总监';
    }

    if (this.userinfo[0].group_id == 3) {
      this.userinfo[0].group_name = '总监';
    }

    if (this.userinfo[0].group_id == 4) {
      this.userinfo[0].group_name = '分公司';
    }

    if (this.userinfo[0].group_id == 5) {
      this.userinfo[0].group_name = '大股东';
    }

    if (this.userinfo[0].group_id == 6) {
      this.userinfo[0].group_name = '股东';
    }

    if (this.userinfo[0].group_id == 7) {
      this.userinfo[0].group_name = '总代理';
    }

    if (this.userinfo[0].group_id == 8) {
      this.userinfo[0].group_name = '代理';
    }

    this.userinfo[0].user_id = this.$store.state.userinfo.user_id;
    let list = this.$store.state.viewdata.list;
    list.forEach(item => {
      item.list = this.reSetData(item.list, Math.round(item.list.length / 2));
    });
    this.gamesinfo = list;
  },

  mounted() {
    let gids = [];
    this.$store.state.game_list.forEach(item => {
      gids.push(item.id);
    });
    this.get_data(gids);
  },

  methods: {
    get_data(gids = '') {
      this.axios.post("user_version2/index", {
        gids
      }).then(result => {
        if (result.data.status == 200) {
          this.userinfo = [result.data.repsoneContent];

          if (this.userinfo[0].group_id == 1) {
            this.userinfo[0].group_name = '总公司';
          }

          if (this.userinfo[0].group_id == 2) {
            this.userinfo[0].group_name = '大总监';
          }

          if (this.userinfo[0].group_id == 3) {
            this.userinfo[0].group_name = '总监';
          }

          if (this.userinfo[0].group_id == 4) {
            this.userinfo[0].group_name = '分公司';
          }

          if (this.userinfo[0].group_id == 5) {
            this.userinfo[0].group_name = '大股东';
          }

          if (this.userinfo[0].group_id == 6) {
            this.userinfo[0].group_name = '股东';
          }

          if (this.userinfo[0].group_id == 7) {
            this.userinfo[0].group_name = '总代理';
          }

          if (this.userinfo[0].group_id == 8) {
            this.userinfo[0].group_name = '代理';
          }

          this.userinfo[0].user_id = this.$store.state.userinfo.user_id;
          let list = result.data.repsoneContent.list;
          list.forEach(item => {
            item.list = this.reSetData(item.list, Math.round(item.list.length / 2));
          });
          this.gamesinfo = list;
        }
      });
    },

    reSetData(data, num) {
      //把一个数组分割成想要的数组长度
      let index = 0;
      let newArray = [];

      while (index < data.length) {
        newArray.push(data.slice(index, index += num));
      }

      return newArray;
    }

  }
};