import axios from '../../../axios/axios';
import { lhcNum } from '../../../plugin/lhcNum';
import { get_lhc_sx } from '../../../plugin/sx';
export default {
  components: {},

  data() {
    return {
      gid: '20',
      btns: [{
        type: 'primary',
        gid: 20,
        name: '六合彩'
      }, {
        type: '',
        gid: 34,
        name: '澳门六合彩'
      }, {
        type: '',
        gid: 49,
        name: '新澳门六合彩'
      }],
      list: [],
      page: 1,
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      change: {
        show: false,
        act: 'edit',
        id: '',
        qnum: '',
        today: new Date(),
        startdate: '',
        starttime: '16:00:00',
        closedate: '',
        closetime: '21:30:00',
        tema_closedate: '',
        tema_closetime: '21:31:00'
      },
      add: {
        show: false,
        act: 'edit',
        qnum: '',
        today: new Date(),
        startdate: '',
        starttime: '16:00:00',
        closedate: '',
        closetime: '21:30:00',
        tema_closedate: '',
        tema_closetime: '21:31:00'
      },
      jiesuan: {
        show: false,
        act: 'open',
        row: '',
        num_list: '',
        firstCode: '',
        secondCode: '',
        thirdCode: '',
        fourthCode: '',
        fifthCode: '',
        sixCode: '',
        sevenCode: ''
      }
    };
  },

  beforeRouteEnter(to, from, next) {
    axios.post("play/num_list", {
      page: 1,
      gid: '20',
      start_time: '',
      end_time: ''
    }).then(result => {
      if (result.data.status == 200) {
        next(vm => {
          // 通过 `vm` 访问组件实例
          vm.list = result.data.repsoneContent.list;
          vm.pagination = result.data.repsoneContent.pagination;

          for (let i = 0; i < vm.list.length; i++) {
            let arr = [];

            for (let j = 0; j < vm.list[i].res.length; j++) {
              arr.push(lhcNum(lhcNum(vm.list[i].res[j], vm.$filters.time(vm.list[i].starttime).split(' ')[0])));
            }

            vm.list[i].res = get_lhc_sx('开奖', arr);
          }

          if (vm.list.length > 0) {
            vm.add.qnum = vm.add.min_qnum = Number(vm.list[0].qnum) + 1;
          }
        });
      }
    });
  },

  created() {},

  methods: {
    send_one() {
      this.sub_jiesuan('one');
    },

    sub_jiesuan(type = 'all') {
      let list = [this.jiesuan.firstCode, this.jiesuan.secondCode, this.jiesuan.thirdCode, this.jiesuan.fourthCode, this.jiesuan.fifthCode, this.jiesuan.sixCode, this.jiesuan.sevenCode];
      let nary = list.sort();

      for (let i = 0; i < list.length; i++) {
        if (nary[i] != '') {
          if (Number.isNaN(Number(nary[i])) || nary[i] < 1 || nary[i] > 49) {
            return this.$message.error('请填写正确的号码');
          }

          if (nary[i] == nary[i + 1]) {
            return this.$message.error('号码不可重复');
          }
        }
      }

      this.axios.post("play/num_edit", this.jiesuan).then(result => {
        if (result.data.status == 200) {
          this.$message.success(result.data.shortMessage);

          if (type == 'all') {
            this.jiesuan.show = false;
          }

          this.get_list(1);
        }
      });
    },

    reset_code() {
      this.jiesuan.firstCode = '';
      this.jiesuan.secondCode = '';
      this.jiesuan.thirdCode = '';
      this.jiesuan.fourthCode = '';
      this.jiesuan.fifthCode = '';
      this.jiesuan.sixCode = '';
      this.jiesuan.sevenCode = '';
    },

    open_js(row) {
      if (row.settle_status == '已结算') {
        return this.$message.error('当期已结算');
      }

      this.reset_code();
      this.axios.post("play/num_edit", {
        act: 'res',
        ...row
      }).then(result => {
        if (result.data.status == 200) {
          if (result.data.repsoneContent.res_msg == '已开奖') {
            let list = result.data.repsoneContent.list[0].res;

            if (list[0] != undefined) {
              this.jiesuan.firstCode = list[0];
            }

            if (list[1] != undefined) {
              this.jiesuan.secondCode = list[1];
            }

            if (list[2] != undefined) {
              this.jiesuan.thirdCode = list[2];
            }

            if (list[3] != undefined) {
              this.jiesuan.fourthCode = list[3];
            }

            if (list[4] != undefined) {
              this.jiesuan.fifthCode = list[4];
            }

            if (list[5] != undefined) {
              this.jiesuan.sixCode = list[5];
            }

            if (list[6] != undefined) {
              this.jiesuan.sevenCode = list[6];
            }
          }

          this.jiesuan.row = row;
          this.jiesuan.num_list = row.id;
          this.jiesuan.show = true;
        }
      });
    },

    //删除
    del_row(row) {
      this.$confirm(`确定删除 ${row.qnum} 期吗?`, "提示", {
        confirmButtonText: "确定删除",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.axios.post("play/num_edit", {
          act: 'del',
          id: row.id
        }).then(result => {
          if (result.data.status == 200) {
            this.$message.success(result.data.shortMessage);
            this.get_list(1);
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },

    //修改
    change_row(row) {
      // console.log(row);
      this.change.id = row.id;
      this.change.qnum = row.qnum;
      this.change.startdate = this.$filters.time(row.starttime, 'notime');
      this.change.closedate = this.$filters.time(row.closetime, 'notime');
      this.change.tema_closedate = this.$filters.time(row.tema_closetime, 'notime');
      this.change.starttime = `${this.change.startdate} ${this.$filters.time(row.starttime, 'nodate')}`;
      this.change.closetime = `${this.change.closedate} ${this.$filters.time(row.closetime, 'nodate')}`;
      this.change.tema_closetime = `${this.change.tema_closedate} ${this.$filters.time(row.tema_closetime, 'nodate')}`;
      this.change.show = true;
    },

    //提交修改
    change_pk_sub() {
      let obj = {
        act: 'edittime',
        id: this.change.id,
        qnum: '',
        starttime: '',
        closetime: '',
        tema_closetime: '',
        today: this.$filters.time(Date.parse(new Date()) / 1000)
      }; // console.log(this.add);

      obj.qnum = this.change.qnum;
      obj.starttime = `${this.$filters.time(Date.parse(this.change.startdate) / 1000, 'notime')} ${this.$filters.time(Date.parse(this.change.starttime) / 1000).split(' ')[1]}`;
      obj.closetime = `${this.$filters.time(Date.parse(this.change.closedate) / 1000, 'notime')} ${this.$filters.time(Date.parse(this.change.closetime) / 1000).split(' ')[1]}`;
      obj.tema_closetime = `${this.$filters.time(Date.parse(this.change.tema_closedate) / 1000, 'notime')} ${this.$filters.time(Date.parse(this.change.tema_closetime) / 1000).split(' ')[1]}`; // console.log(obj);

      this.axios.post("play/num_edit", obj).then(result => {
        if (result.data.status == 200) {
          this.$message.success(result.data.shortMessage);
          this.change.show = false;
          this.get_list(1);
        }
      });
    },

    ///提交新增
    add_pk_sub() {
      // console.log(this.add);
      let obj = {
        act: 'edit',
        qnum: '',
        starttime: '',
        closetime: '',
        tema_closetime: '',
        today: this.$filters.time(Date.parse(new Date()) / 1000)
      }; // console.log(this.add);

      obj.qnum = this.add.qnum;
      obj.starttime = `${this.$filters.time(Date.parse(this.add.startdate) / 1000, 'notime')} ${this.$filters.time(Date.parse(this.add.starttime) / 1000).split(' ')[1]}`;
      obj.closetime = `${this.$filters.time(Date.parse(this.add.closedate) / 1000, 'notime')} ${this.$filters.time(Date.parse(this.add.closetime) / 1000).split(' ')[1]}`;
      obj.tema_closetime = `${this.$filters.time(Date.parse(this.add.tema_closedate) / 1000, 'notime')} ${this.$filters.time(Date.parse(this.add.tema_closetime) / 1000).split(' ')[1]}`; // console.log(obj);

      this.axios.post("play/num_edit", obj).then(result => {
        if (result.data.status == 200) {
          this.$message.success(result.data.shortMessage);
          this.add.show = false;
          this.get_list(1);
        }
      });
    },

    //新增
    add_pk() {
      this.add.today = new Date();
      let day = this.$filters.time(Date.parse(this.add.today) / 1000, 'notime'); // console.log(day);

      this.add.startdate = day;
      this.add.closedate = day;
      this.add.tema_closedate = day;
      this.add.starttime = `${this.add.startdate} 16:00:00`;
      this.add.closetime = `${this.add.closedate} 21:30:00`;
      this.add.tema_closetime = `${this.add.tema_closedate} 21:31:00`;
      this.add.show = true;
    },

    change_game(item) {
      this.gid = item.gid;
      this.btns[0].type = '';
      this.btns[1].type = '';
      this.btns[2].type = '';
      item.type = 'primary';
      this.add.qnum = '';
      this.get_list(1);
    },

    get_list(page) {
      this.page = page;
      this.axios.post("play/num_list", {
        page: page,
        gid: this.gid,
        start_time: '',
        end_time: ''
      }).then(result => {
        if (result.data.status == 200) {
          this.list = result.data.repsoneContent.list;
          this.pagination = result.data.repsoneContent.pagination;

          for (let i = 0; i < this.list.length; i++) {
            let arr = [];

            for (let j = 0; j < this.list[i].res.length; j++) {
              arr.push(lhcNum(lhcNum(this.list[i].res[j], this.$filters.time(this.list[i].starttime).split(' ')[0])));
            }

            this.list[i].res = get_lhc_sx('开奖', arr);
          }

          if (this.page == 1) {
            this.add.qnum = this.add.min_qnum = Number(this.list[0].qnum) + 1;
          }
        }
      });
    }

  }
};