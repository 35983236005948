import axios from '../../../axios/axios';
import datepicker from "../../homePublic/datepicker.vue";
export default {
  components: {
    datepicker
  },

  data() {
    return {
      show: 'form',
      form: {
        gid: "0",
        date: "",
        qnum: "",
        username: "",
        bet_money1: "",
        bet_money2: "",
        bet_result1: "",
        bet_result2: "",
        amount_type: '3',
        sort: '2',
        order_id: '',
        order_status: '3'
      },
      dialog: {
        visible: false,
        data: [],
        total: []
      },
      page: 1,
      pagination: {
        //分页信息
        total_pages: "",
        total_records: "",
        page_size: ""
      },
      game_list: []
    };
  },

  mounted() {
    this.get_game_list();
    this.$refs['datepicker'].setDate('今天');
  },

  methods: {
    change_page(page) {
      this.page = page;
      this.get_search_data();
    },

    //获取时间
    getDate(date) {
      this.form.date = date;
    },

    get_search_data() {
      if (this.form.bet_money1 && this.form.bet_money2) {
        if (this.form.bet_money2 * 1 < this.form.bet_money1 * 1) {
          return this.$message.error('注额范围的数值输入不正确，第2项不得低于第1项');
        }
      }

      if (this.form.bet_result1 && this.form.bet_result2) {
        if (this.form.bet_result2 * 1 < this.form.bet_result1 * 1) {
          return this.$message.error('结果范围的数值输入不正确，第2项不得低于第1项');
        }
      }

      this.axios.post("betbackup/order_list", {
        gid: this.form.gid,
        page: this.page,
        start_time: this.form.date.start,
        end_time: this.form.date.end,
        username: this.form.username,
        bet_money1: this.form.bet_money1,
        bet_money2: this.form.bet_money2,
        bet_result1: this.form.bet_result1,
        bet_result2: this.form.bet_result2,
        qnum: this.form.qnum,
        amount_type: this.form.amount_type,
        sort: this.form.sort,
        order_id: this.form.order_id,
        order_status: this.form.order_status
      }).then(result => {
        // console.log(result.data.repsoneContent)
        if (result.data.status == 200) {
          if (result.data.repsoneContent) {
            this.dialog.data = result.data.repsoneContent.list;
            this.pagination = result.data.repsoneContent.pagination;
            let total = result.data.repsoneContent.total;
            total.title = "总计";
            let sub_total = result.data.repsoneContent.sub_total;
            sub_total.title = "小计";
            this.dialog.total = [];
            this.dialog.total.push(sub_total);
            this.dialog.total.push(total); // console.log(this.dialog.total)
          } else {
            this.dialog.data = [];
            this.dialog.total = [];
            this.pagination = {
              total_pages: "",
              total_records: "",
              page_size: ""
            };
          }

          this.show = 'data';
        }
      });
    },

    get_game_list() {
      this.axios.post("main/game_list").then(result => {
        if (result.data.status == 200) {
          // console.log(result.data.repsoneContent)
          this.game_list = result.data.repsoneContent.game_list;
          this.game_list.unshift({
            id: "0",
            gname: "全部"
          });
        }
      });
    }

  }
};